import { CommonButtonInput } from '@acengage/ui/models/button.model';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'acengage-doc-view',
  templateUrl: './doc-view.component.html',
  styleUrls: ['./doc-view.component.scss'],
})
export class DocViewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private _location: Location
  ) {}
  breadcrumbPath: MenuItem[] = [
    { label: 'File', disabled: true },
    // { label: 'NDA', disabled: true },
  ];
  @Input() document: any;

  breadcrumbBtnDtls: CommonButtonInput[] = [
    {
      buttonText: 'Back',
      buttonClassesToAdd: 'secondary-btn',
      iconDetails: {
        name: 'pi pi-arrow-right',
        position: 'right',
      },
    },
  ];
  ngOnInit(): void {}

  getContent(data: any): void {}
  navigateToEditUser() {
    // this._location.back();
  }
  // showToaster(type: string, heading: string, message: string) {
  //   this.store.dispatch(
  //     AppActions.showToaster({
  //       class: type,
  //       header: heading,
  //       message: message,
  //       showToaster: true,
  //     })
  //   );
  // }
  // showLoaderTrigger(isShow: boolean) {
  //   this.store.dispatch(AppActions.showLoader({ showLoader: isShow }));
  // }
}
