import { CommonButtonInput } from '@acengage/ui/models/button.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acengage-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
})
export class MessagePopupComponent implements OnInit {
  constructor() {}
  @Input() success: boolean;
  @Input() error: boolean;
  @Input() message: string;
  @Input() buttonDtls: CommonButtonInput = {
    buttonText: 'Okay',
    buttonClassesToAdd: 'secondary-btn',
    iconDetails: { position: 'right', name: 'pi pi-arrow-right' },
  };
  @Output() closeClicked = new EventEmitter<void>();
  ngOnInit(): void {}
}
