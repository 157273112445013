import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'acengage-header-with-right-button',
  templateUrl: './header-with-right-button.component.html',
  styleUrls: ['./header-with-right-button.component.scss'],
})
export class HeaderWithRightButtonComponent implements OnInit {
  constructor() { }
  headerlabel = '';
  buttonlabel = '';
  @Input() set headerText(value: any) {
    this.headerlabel = value;
  }
  @Input() showBtn =true;
  @Input() set buttonText(value: any) {
    this.buttonlabel = value;
  }
  @Output() buttonClick = new EventEmitter<any>();

  ngOnInit(): void { }
  buttonClicked(event: any) {
    this.buttonClick.emit();
  }
}
