import { NotificationService } from './../../notification/services/notification.service';
import { profileDetailsFetchSuccess } from './../../setting/state/settings.action';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  map,
  exhaustMap,
  catchError,
  mergeMap,
  tap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { fetch } from '@nrwl/angular';
import * as SettingAction from '@acengage/app/app/setting/state/settings.action';
import * as appActions from '../../state/app.actions';
import { Store } from '@ngrx/store';
import * as authActions from './auth.actions';
import { AuthenticationService } from '../../core/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { SettingService } from '../../services/setting.service';
import { MenuPayload, SubMenu } from '../../state/app.model';
import * as moment from 'moment/moment';
@Injectable()
export class loginEffects {
  constructor(
    private readonly actions$: Actions,
    private authService: AuthenticationService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private settingService: SettingService,
    private notifySetting: NotificationService
  ) {}
  isCallBack = false;
  firstLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.firstLoginRequest),
      mergeMap((action) => {
        return this.authService.login(action.payload).pipe(
          distinctUntilChanged(),
          map((response: any) => {
            let toStore = {
              id: response.id,
              default_password_changed: response.default_password_changed,
              first_login: response.first_login,
              is_2fa: response.is_2fa,
              token: response.token.access,
              refreshToken: response.token.refresh,
              username: response.username,
              client_details: response.client_details,
              qr_text: response.qr_text,
              isLoading: false,
              roles: response.roles,
              usage_id: response.usage_id,
              full_name: response.full_name,
              is_call_back: response.is_call_back,
              is_client_2fa_verified: response.is_client_2fa_verified,
              progress_of_completion: response.client_details.progress_of_completion
            };
            // this.store.dispatch(appActions.showLoader({ showLoader: false }));
            return authActions.firstLoginSuccess(toStore);
          }),
          tap((state: any) => {
            if (!state.default_password_changed) {
              this.store.dispatch(appActions.showLoader({ showLoader: false }));
              this.router.navigateByUrl('/auth/reset-password');
              this.store.dispatch(
                appActions.showToaster({
                  class: 'success',
                  header: 'Valid credentials',
                  message: 'Continue with the next steps First',
                  showToaster: true,
                })
              );
            } else if (
              (state.is_2fa && state.first_login) ||
              (state.default_password_changed && state.is_2fa)
            ) {
              this.store.dispatch(appActions.showLoader({ showLoader: false }));
              this.router.navigateByUrl('/auth/scan-qr');
              this.store.dispatch(
                appActions.showToaster({
                  class: 'success',
                  header: 'Password reset success',
                  message: 'Continue with the next steps',
                  showToaster: true,
                })
              );
            } else if (state.token) {
              // this.router.navigate(['accounts']);
              this.store.dispatch(
                appActions.showToaster({
                  class: 'success',
                  header: 'Logged in successfully',
                  message: '',
                  showToaster: true,
                })
              );
              // if(state.token){
              //   this.store.dispatch(authActions.sideMenuBar({ payload: state }));
              // }
            }
          }),
          catchError((error: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(
              appActions.showToaster({
                class: 'error',
                header: 'Error',
                message: error.error.message,
                showToaster: true,
              })
            );
            return of(authActions.firsLoginFailure(error.error.message));
          })
        );
      })
    )
  );

  Login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.LoginRequest),
      distinctUntilChanged(),
      mergeMap((action) => {
        return this.authService.login(action.payload).pipe(
          map((response: any) => {
            let toStore = {
              id: response.id,
              default_password_changed: response.default_password_changed,
              first_login: response.first_login,
              is_2fa: response.is_2fa,
              token: response.token.access,
              refreshToken: response.token.refresh,
              qr_text: response.qr_text,
              username: response.username,
              client_details: response.client_details,
              isLoading: false,
              roles: response.roles,
              picture: response.picture,
              usage_id: response.usage_id,
              full_name: response.full_name,
              is_call_back: response.is_call_back,
              is_client_2fa_verified: response.is_client_2fa_verified,
              progress_of_completion: response.client_details.progress_of_completion
            };
            // this.store.dispatch(appActions.showLoader({ showLoader: false }));
            return authActions.LoginSuccess(toStore);
          }),
          tap((state: any) => {
            if (!state.default_password_changed) {
              this.store.dispatch(appActions.showLoader({ showLoader: false }));
              this.router.navigateByUrl('/auth/reset-password');
              this.store.dispatch(
                appActions.showToaster({
                  class: 'success',
                  header: 'Valid credentials',
                  message: 'Continue with the next steps second',
                  showToaster: true,
                })
              );
            } else if (state.roles['0'].id == 11 
                && !state.is_client_2fa_verified 
                && state.is_2fa
              ) {
              this.store.dispatch(appActions.showLoader({ showLoader: false }));
              this.router.navigateByUrl('/auth/scan-qr');
              this.store.dispatch(
                appActions.showToaster({
                  class: 'success',
                  header: 'Logged in successfully QR',
                  message: 'Continue with the next steps',
                  showToaster: true,
                })
              );
            } else if (state.is_2fa && state.first_login) {
              this.store.dispatch(appActions.showLoader({ showLoader: false }));
              this.router.navigateByUrl('/auth/scan-qr');
              this.store.dispatch(
                appActions.showToaster({
                  class: 'success',
                  header: 'Password reset success',
                  message: 'Continue with the next steps',
                  showToaster: true,
                })
              );
            } else if (state.token) {
              this.isCallBack = state.is_call_back
              this.store.dispatch(
                appActions.showToaster({
                  class: 'success',
                  header: 'Logged in successfully',
                  message: '',
                  showToaster: true,
                })
              );
              // if(state.token){
              //   this.store.dispatch(authActions.sideMenuBar({ payload: state }));
              // }
            }
          }),
          catchError((error: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(
              appActions.showToaster({
                class: 'error',
                header: 'Error',
                message: error.error.message,
                showToaster: true,
              })
            );
            return of(authActions.LoginFailure(error));
          })
        );
      })
    )
  );

  navigateToQR$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateLoginStateAndRedirect),
      distinctUntilChanged(),
      tap((result: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        if (result.is_2fa) {
          this.router.navigateByUrl('/auth/scan-qr');
          this.store.dispatch(
            appActions.showToaster({
              class: 'success',
              header: 'Password reset success',
              message: 'Continue with the next steps',
              showToaster: true,
            })
          );
        } else if (result.token) {
          let loginDtls: any = localStorage.getItem('login');
          loginDtls = JSON.parse(loginDtls);
          if (loginDtls?.roles[0]?.id === 11) {
            if (loginDtls?.client_details?.client_status === 3) {
              this.store.dispatch(
                authActions.sideMenuBar({ payload: loginDtls })
              );
            } else if (loginDtls?.client_details?.client_status === 1) {
              this.router.navigate([`client-admin/landing`]);
            } else {
              // let id;
              if (loginDtls?.client_details?.progress_of_completion === 12) {
                this.store.dispatch(
                  authActions.sideMenuBar({ payload: loginDtls })
                );
              } else {
                this.router.navigateByUrl(`/client-admin/landing`);
              }
            }
          }

          // if (result.token) {
          //   this.store.dispatch(authActions.sideMenuBar({ payload: result }));
          // }
          // this.router.navigateByUrl('/accounts');
          // this.store.dispatch(authActions.sideMenuBar());
          this.store.dispatch(
            appActions.showToaster({
              class: 'success',
              header: 'Logged in successfully',
              message: '',
              showToaster: true,
            })
          );
        }
      }),
      catchError((error: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            header: 'Error',
            message: error.error.message,
            showToaster: true,
          })
        );
        return error;
      })
    )
  );

  navigateToAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateLoginStateFromVerify),
      distinctUntilChanged(),
      tap((result: any) => {
        if (result.token) {
          this.store.dispatch(authActions.sideMenuBar({ payload: result }));
        }
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        // this.router.navigateByUrl('/accounts');
        this.store.dispatch(
          appActions.showToaster({
            class: 'success',
            header: 'Logged in successfully',
            message: '',
            showToaster: true,
          })
        );
      }),
      catchError((error: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            header: 'Error',
            message: error.error.message,
            showToaster: true,
          })
        );
        return error;
      })
    )
  );
  // forgot
  forgotUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.ForgotPasswordReq),
      tap(() => {
        this.store.dispatch(appActions.showLoader({ showLoader: true }));
      }),
      mergeMap((action) => {
        return this.authService.forgot(action.payload).pipe(
          map((response: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.router.navigateByUrl('/auth/forget-password/step-two');
            this.store.dispatch(
              appActions.showToaster({
                class: 'success',
                header: 'OTP sent successfully. Please check your email',
                message: '',
                showToaster: true,
              })
            );
            return authActions.ForgotPasswordSuccess({
              saveUser: action.payload ? action.payload.username : '',
            });
          }),
          catchError((error: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(
              appActions.showToaster({
                class: 'error',
                header: 'Error',
                message: error.error.message,
                showToaster: true,
              })
            );
            return of(authActions.ForgotPasswordFailure(error));
          })
        );
      })
    )
  );

  forgototpUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.ForgotOTPReq),
      tap(() => {
        this.store.dispatch(appActions.showLoader({ showLoader: true }));
      }),
      mergeMap((action) => {
        return this.authService.verifyForgotOtp(action.payload).pipe(
          map((response: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.router.navigateByUrl('/auth/forget-password/step-three');
            this.store.dispatch(
              appActions.showToaster({
                class: 'success',
                header: 'OTP is valid',
                message: '',
                showToaster: true,
              })
            );
            return authActions.ForgotOTPSuccess({ otpresult: response });
          }),
          catchError((error: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(
              appActions.showToaster({
                class: 'error',
                header: 'Error',
                message: error.error.message,
                showToaster: true,
              })
            );
            return of(authActions.ForgotOTPFailure(error));
          })
        );
      })
    )
  );

  resetForgotPasswordUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.ForgotResetPassword),
      tap(() => {
        this.store.dispatch(appActions.showLoader({ showLoader: true }));
      }),
      mergeMap((action) => {
        return this.authService.resetForgotPassword(action.payload).pipe(
          map((response: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.router.navigateByUrl('/auth/forget-password/step-four');
            this.store.dispatch(
              appActions.showToaster({
                class: 'success',
                header: 'Password reset successfully',
                message: '',
                showToaster: true,
              })
            );
            return authActions.ForgotResetPasswordSuccess({
              passwordresult: response,
            });
          }),
          catchError((error: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(
              appActions.showToaster({
                class: 'error',
                header: 'Error',
                message: error.error.message,
                showToaster: true,
              })
            );
            return of(authActions.ForgotResetPasswordFailure(error));
          })
        );
      })
    )
  );

  // login success
  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.LoginSuccess),
        tap((res: any) => {
          if (!res.first_login) {
            if (res.default_password_changed) {
              if (res.token) {
                if (res?.roles[0]?.id === 11) {
                  if(!res?.is_client_2fa_verified && res?.is_2fa) {
                    this.router.navigate([`auth/scan-qr`]);
                  } else if (res?.client_details?.progress_of_completion === 11) {
                    this.router.navigate([`client-admin/landing`]);
                  } else if (res?.client_details?.client_status === 3) {
                    this.store.dispatch(
                      authActions.sideMenuBar({ payload: res })
                    );
                  } else if (res?.client_details?.client_status === 1) {
                    // this.router.navigateByUrl(`/client-admin/profile-creation?tab=5&id=${res?.client_details?.account_id}`);
                    // this.router.navigate(['/client-admin/hybrid-profile-creation'], {
                    //   queryParams: { tab: 5 ,id: res?.client_details?.account_id , success:true, viewUpload:false},
                    // });
                    this.router.navigate([`client-admin/landing`]);
                  } else {
                    // let id;
                    if (res?.client_details?.progress_of_completion === 12) {
                      this.store.dispatch(
                        authActions.sideMenuBar({ payload: res })
                      );
                    } else {
                      this.router.navigateByUrl(`/client-admin/landing`);
                    }
                  }
                } else {
                  this.store.dispatch(
                    authActions.sideMenuBar({ payload: res })
                  );
                }
              }
            }
          } else {
            this.router.navigateByUrl('/auth/reset-password');
          }
        })
      );
    },
    { dispatch: false }
  );

  // side menu
  sideMenuBar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.sideMenuBar),
      mergeMap((action) => {
        return this.commonService.getSideMenu().pipe(
          map((response: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(authActions.profileDetailsFetch());
            this.store.dispatch(authActions.showPopup({ popup: true })); 
            const redirectUrl: string =
              this.route.snapshot.queryParams['redirectUrl'] || '';
              let loginDtls: any = localStorage.getItem('login');
            loginDtls = JSON.parse(loginDtls);
            const selectMenuDtls: { menuIndex: number; submenuIndex: number } =
            this.authService.getMenuAndSubmenuIndex(
              redirectUrl,
              response.data,
              loginDtls.roles['0'].id
            );
            let navigateUrl = '';
            if (redirectUrl) {
              navigateUrl = redirectUrl;
              this.router.navigateByUrl(navigateUrl);
            } else {
              if(this.isCallBack) {
                this.router.navigate(["/call_back"], {
                  queryParams: {
                    is_call_back: true
                  },
                });
              } else {
                if (response?.data[1]?.sub_menu.length) {
                  navigateUrl = response?.data[1]?.sub_menu[0]?.routePath;
                } else {
                  if(loginDtls.roles['0'].id==11){
                    navigateUrl = response?.data[0]?.routePath;
                  }else{
                    navigateUrl = response?.data[1]?.routePath;
                  }
                }
                this.router.navigate([`${navigateUrl}`]); 
              }
            }
            return authActions.sideMenuBarSuccess({
              menuItem: response.data,
              selectMenuDtls,
            });
          }),
          catchError((error: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(
              appActions.showToaster({
                class: 'error',
                header: 'Error',
                message: error.error.message,
                showToaster: true,
              })
            );
            return of(appActions.sideMenuBarFailure(error?.error?.message));
          })
        );
      })
    );
  });

  fetchUserDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.profileDetailsFetch),
      tap(() => {
        this.store.dispatch(appActions.showLoader({ showLoader: true }));
      }),
      mergeMap((action) => {
        return this.settingService.getUserIndDetails().pipe(
          map((response: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            return authActions.profileDetailsFetchSuccess({
              userDetails: response.data,
            });
          }),
          catchError((error: any) => {
            this.store.dispatch(appActions.showLoader({ showLoader: false }));
            this.store.dispatch(
              appActions.showToaster({
                class: 'error',
                header: 'Error',
                message: error.error.message,
                showToaster: true,
              })
            );
            return of(
              authActions.profileDetailsFetchFailure(error?.error?.message)
            );
          })
        );
      })
    );
  });

  formatData(value: any) {
    let localDate = moment.utc(value).toDate();
    return moment(localDate).format('DD/MM/YYYY hh:mm A');
  }

  // notificationDetails$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(authActions.notificationDtl),
  //     tap(() => {
  //       this.store.dispatch(appActions.showLoader({ showLoader: true }));
  //     }),
  //     mergeMap((action) => {
  //       return this.notifySetting.notification().pipe(
  //         map((response: any) => {

  //           response.data.map((eachData: any) => {
  //             eachData.created_at = this.formatData(eachData.created_at)
  //           })
  //           this.store.dispatch(appActions.showLoader({ showLoader: false }));
  //           return authActions.notificationSuccess({ notification: response.data });
  //         }),
  //         catchError((error: any) => {
  //           this.store.dispatch(appActions.showLoader({ showLoader: false }));
  //           this.store.dispatch(appActions.showToaster({
  //             class: 'error',
  //             header: 'Error',
  //             message: error.error.message,
  //             showToaster: true
  //           }));
  //           return of(authActions.notificationFailure(error?.error?.message));
  //         })
  //       )
  //     })
  //   );
  // });
}
