  <h3 class="textCenter">Google authenticator OTP</h3>
  <div class="marginBottom10px">
    <label>Enter OTP</label>
  </div>
  <form [formGroup]="sixDigitOtp">
    <div class="otpInput marginBottom20px paddingBottom20vh">
      <div class="otpInputField">
        <acengage-text-input
          formControlName="otp1" [allowedKeys]="numRegex"
          #otp1
          (keyup)="enterOtp($event, '', otp1, otp2)"
          id="otp1"
          maxLength="1"
        ></acengage-text-input>
      </div>
      <div class="otpInputField">
        <acengage-text-input
          formControlName="otp2" [allowedKeys]="numRegex"
          #otp2
          (keyup)="enterOtp($event, otp1, otp2, otp3)"
          id="otp2"
          maxLength="1"
        ></acengage-text-input>
      </div>
      <div class="otpInputField">
        <acengage-text-input
          formControlName="otp3" [allowedKeys]="numRegex"
          #otp3
          (keyup)="enterOtp($event, otp2, otp3, otp4)"
          id="otp3"
          maxLength="1"
        ></acengage-text-input>
      </div>
      <div class="otpInputField">
        <acengage-text-input
          formControlName="otp4" [allowedKeys]="numRegex"
          #otp4
          (keyup)="enterOtp($event, otp3, otp4, otp5)"
          id="otp4"
          maxLength="1"
        ></acengage-text-input>
      </div>
      <div class="otpInputField">
        <acengage-text-input
          formControlName="otp5" [allowedKeys]="numRegex"
          #otp5
          (keyup)="enterOtp($event, otp4, otp5, otp6)"
          id="otp5"
          maxLength="1"
        ></acengage-text-input>
      </div>
      <div class="otpInputField">
        <acengage-text-input
          formControlName="otp6" [allowedKeys]="numRegex"
          #otp6
          (keyup)="enterOtp($event, otp5, otp6, '')"
          id="otp6"
          maxLength="1"
        ></acengage-text-input>
      </div>
    </div>
    <!-- <acengage-form-error [control]="sixDigitOtp.controls.otp1 ||
    sixDigitOtp.controls.otp2 ||
    sixDigitOtp.controls.otp3 ||
    sixDigitOtp.controls.otp4 ||
    sixDigitOtp.controls.otp5 ||
    sixDigitOtp.controls.otp6
    "  [group]="sixDigitOtp"  validatorName="required">
      2FA code is required.
    </acengage-form-error>
    <acengage-form-error [control]="sixDigitOtp.controls.otp1 ||
    sixDigitOtp.controls.otp2 ||
    sixDigitOtp.controls.otp3 ||
    sixDigitOtp.controls.otp4 ||
    sixDigitOtp.controls.otp5 ||
    sixDigitOtp.controls.otp6 "  [group]="sixDigitOtp"  validatorName="pattern">
      Enter only numbers
    </acengage-form-error> -->
    <div class="">
      <acengage-button
        (buttonClicked)="check2FA()"
        [isBtnDisable]="!sixDigitOtp.valid"
        buttonType="submit"
        buttonText="Proceed to login"
      ></acengage-button>
    </div>
  </form>
