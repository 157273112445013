import { Component, OnInit, forwardRef, Input,Output,EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextAreaComponent),
    multi: true,
  }]
})
export class TextAreaComponent implements ControlValueAccessor {
  constructor() { }
  value: any;
  @Input() id = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() classesToAdd ='';
  @Input() isShowFloatLabel = true;
  @Input() autoResize = false;
  @Input() rows = 5;
  @Input() allowHTMLTags = false;
  @Input() htmlTag:boolean;
  @Output() txtFocusOut=new EventEmitter();
  private valueChanges = new Subject<string>();
  private touches = new Subject();
  onChange = () => { };
  onTouch = () => { };

  ngOnInit(): void {
    this.htmlTag?this.htmlTag=true:this.htmlTag=false;
  }

  handleEmptySpace(event: any) {
    if (event.which === 32 && !this.value.length) {
      event.preventDefault();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value ? value.trim() : '';
  }
  updateValue(value: any) {
    this.value = value ? value.trim() : '';
    this.valueChanges.next(this.value);
  }
  touch() {
    this.touches.next(null);
  }

  preventHTMLTagSymbol(event: KeyboardEvent): void {
    if (!this.allowHTMLTags) {
      const key: string = event.key?.toLowerCase();
      if (key === '<' || key === '>') {
        event.preventDefault();
      }
    }
  }
}
