import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommonAPIRequest } from '../../models/common-api-request.model';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }
  notification(apiReqDtls?: CommonAPIRequest) {
    if (apiReqDtls) {
      return this.http.get(`${environment.api_url}/notifications/?page=${apiReqDtls.page}&limit=${apiReqDtls.limit}`);
    } else {
      return this.http.get(`${environment.api_url}/notifications/`);
    }
  }
  readNotification(id: number, obj: any) {
    return this.http.patch(`${environment.api_url}/notifications/` + id, obj);
  }

  getNotificationCount(){
    return this.http.get(`${environment.api_url}/notifications/get_unread_count`);
  }
}
