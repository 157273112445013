import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@acengage/environments/environment';
import {
  RegisterParams,
  ResetPasswordParams,
  twoFaParams,
} from '../interfaces';
import { Store } from '@ngrx/store';
import { LoginState } from '../../authentication/state/auth.reducer';
import * as AuthActions from '../../authentication/state/auth.actions';
import { Router } from '@angular/router';
import * as appActions from '../../state/app.actions';
import { BnNgIdleService } from 'bn-ng-idle';
import { MenuPayload, SubMenu } from '../../state/app.model';

/**
 * @description Authentication Service which handles the user session and authentication related API
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * @description boolean to check logged in user in the app session level.
   */
  isLoggedIn: boolean;

  constructor(
    private http: HttpClient,
    private store: Store<LoginState>,
    private router: Router,
    private bnIdle: BnNgIdleService
  ) {}

  /**
   * @description To check whether the user is logged in or not.
   */
  isSessionActive() {
    return localStorage.getItem('login') ? true : false;
  }

  /**
   * @description Set the logged in user token to store
   */
  set token(token: any) {
    this.isLoggedIn = true;
    // localStorage.setItem('token', token);
    // this.store.dispatch(fromActions.appToken({action:'set',token:token}));
  }

  set loggedInUserDetails(params: any) {
    this.isLoggedIn = true;
    this.store.dispatch(AuthActions.updateLoginState({ payload: params }));
    // localStorage.setItem('login', JSON.stringify(params));
    // this.store.dispatch(
    //   fromActions.appToken({
    //     action: 'setLoginValues',
    //     token: params['token'],
    //     id: params['id'],
    //     default_password_changed: params['default_password_changed'],
    //     first_login: params['first_login'],
    //     is_2fa: params['is_2fa'],
    //     username: params['username']  || '',
    //     qr_text: params['qr_text'] || ''
    //   })
    // );
  }

  /**
   * @description Get the logged in user token.
   */
  get token() {
    return '';
  }

  /**
   * @param loginParams The login request params for the login API.
   * @description Login to the application, triggers login API.
   */
  login(loginParams: any) {
    return this.http.post(`${environment.api_url}/user/api/login`, loginParams);
  }
  logoutAPI(logOutParams: any) {
    return this.http.post(
      `${environment.api_url}/user/api/logout/`,
      logOutParams
    );
  }

  resetPassword(resetPasswordParams: ResetPasswordParams) {
    return this.http.post(
      `${environment.api_url}/user/api/reset-password/`,
      resetPasswordParams
    );
  }
  verify2FAcode(twofacode: twoFaParams) {
    return this.http.post(
      `${environment.api_url}/user/totp/login/` + twofacode.twoFa + '/',
      {}
    );
  }

  /**
   * @param registerParams The register/signup api request params.
   * @description Register/Signup to the application, triggers register API.
   */
  register(registerParams: RegisterParams) {
    return this.http.post(`${environment.api_url}/register`, registerParams);
  }

  assignedCampaignsTodayCount() {
    return this.http.get(
      `${environment.api_url}/user/api/user/user_assigned_count`
    );
  }

  logOut(isForceLogout = false, isIdleLogout = false) {
    const loginDetails = JSON.parse(localStorage.getItem('login') || '');
    const apiBody: { refresh_token: string; usage_id: number; poll?: boolean } =
      {
        refresh_token: loginDetails.refreshToken,
        usage_id: loginDetails.usage_id,
      };
    if (isForceLogout) {
      apiBody.poll = isForceLogout;
    }
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    this.logoutAPI(apiBody).subscribe(
      (res: any) => {
        if (res.code == 200) {
          this.store.dispatch(AuthActions.logoutUser());
          const successMsg: string = isIdleLogout
            ? 'You have been idle for a long time now, you are logged out.'
            : 'Logged out successfully';
          this.store.dispatch(
            appActions.showToaster({
              class: 'success',
              header: '',
              message: successMsg,
              showToaster: true,
            })
          );
          this.store.dispatch(appActions.showLoader({ showLoader: false }));
          localStorage.clear();
          const redirectUrl = isIdleLogout ? this.router.url : '';
          this.router.navigateByUrl(`/auth/login?redirectUrl=${redirectUrl}`);
          this.bnIdle.stopTimer();
        }
      },
      (err) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            header: 'Error',
            message: err.error.message,
            showToaster: true,
          })
        );
        this.store.dispatch(AuthActions.logoutUser());
        localStorage.clear();
        this.router.navigateByUrl('/auth/login');
        this.bnIdle.stopTimer();
      }
    );
  }

  clientLogOut(isForceLogout = false, isIdleLogout = false) {
    const loginDetails = JSON.parse(localStorage.getItem('login') || '');
    const apiBody: { refresh_token: string; usage_id: number; poll?: boolean } =
      {
        refresh_token: loginDetails.refreshToken,
        usage_id: loginDetails.usage_id,
      };
    if (isForceLogout) {
      apiBody.poll = isForceLogout;
    }
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    this.logoutAPI(apiBody).subscribe(
      (res: any) => {
        if (res.code == 200) {
          this.store.dispatch(AuthActions.logoutUser());
          const successMsg: string = isIdleLogout
            ? 'You have been idle for a long time now, you are logged out.'
            : 'Logged out successfully';
          this.store.dispatch(
            appActions.showToaster({
              class: 'success',
              header: '',
              message: successMsg,
              showToaster: true,
            })
          );
          this.store.dispatch(appActions.showLoader({ showLoader: false }));
          localStorage.clear();
          const redirectUrl = isIdleLogout ? this.router.url : '';
          this.router.navigateByUrl(
            `/auth/client-login?redirectUrl=${redirectUrl}`
          );
          this.bnIdle.stopTimer();
        }
      },
      (err) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            header: 'Error',
            message: err.error.message,
            showToaster: true,
          })
        );
        this.store.dispatch(AuthActions.logoutUser());
        localStorage.clear();
        this.router.navigateByUrl('/auth/client-login');
        this.bnIdle.stopTimer();
      }
    );
  }
  // forgot password
  forgot(forgotParams: any) {
    return this.http.post(
      `${environment.api_url}/user/api/forgot-password`,
      forgotParams
    );
  }

  verifyForgotOtp(otpParams: any) {
    return this.http.post(
      `${environment.api_url}/user/api/verify_forgot_password_otps`,
      otpParams
    );
  }

  resetForgotPassword(resetPasswordParams: any) {
    return this.http.post(
      `${environment.api_url}/user/api/reset_password`,
      resetPasswordParams
    );
  }

  getMenuAndSubmenuIndex(redirectUrl: string, menuItems: MenuPayload[],role?:number) {
    let menuIndex = 1;
    let submenuIndex = 0;
    if (redirectUrl) {
      if (redirectUrl.includes('contract')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Contracts'
          ) || 1;
      } else if (redirectUrl.includes('services')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Services'
          ) || 1;
        if (redirectUrl.includes('exit')) {
          submenuIndex =
            menuItems[menuIndex].sub_menu?.findIndex(
              (eachSubmenu: SubMenu) => eachSubmenu.name === 'Exits'
            ) || 0;
        } else if (redirectUrl.includes('hire-engagement')) {
          submenuIndex =
            menuItems[menuIndex].sub_menu?.findIndex(
              (eachSubmenu: SubMenu) =>
                eachSubmenu.name === 'New Hire Engagement'
            ) || 0;
        } else {
          submenuIndex =
            menuItems[menuIndex].sub_menu?.findIndex(
              (eachSubmenu: SubMenu) =>
                eachSubmenu.name === 'Candidate Engagement'
            ) || 0;
        }
      } else if (redirectUrl.includes('accounts')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Clients'
          ) || 1;
      } else if (redirectUrl.includes('users')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Users'
          ) || 1;
      } else if (redirectUrl.includes('acengage-tree')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'AceNgage Tree'
          ) || 1;
      } else if (redirectUrl.includes('report_list')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'My Reports'
          ) || 1;
      } else if (redirectUrl.includes('questionbank')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Question Bank'
          ) || 1;
      } else if (redirectUrl.includes('usage-log')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Usages'
          ) || 1;
      } else if (redirectUrl.includes('queries')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Queries'
          ) || 1;
      } else if (redirectUrl.includes('settings')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Settings'
          ) || 1;
      } else if (redirectUrl.includes('power_bi')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Power BI'
          ) || 1;
      } else if (redirectUrl.includes('appointment-schedules')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Appointment Schedules'
          ) || 1;
      } else if (redirectUrl.includes('interviews')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Interviews'
          ) || 1;
      } else if (redirectUrl.includes('faq-management')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'FAQ Management'
          ) || 1;
        // } else if (redirectUrl.includes('survey-management')) {
        //   menuIndex =
        //     menuItems?.findIndex(
        //       (eachMenu: MenuPayload) => eachMenu.name === 'Survey Management'
        //     ) || 1;
        //   if (redirectUrl.includes('chatbot')) {
        //     submenuIndex =
        //       menuItems[menuIndex].sub_menu?.findIndex(
        //         (eachSubmenu: SubMenu) => eachSubmenu.name === 'Chatbot'
        //       ) || 0;
        //   } else if (redirectUrl.includes('surveys')) {
        //     submenuIndex =
        //       menuItems[menuIndex].sub_menu?.findIndex(
        //         (eachSubmenu: SubMenu) =>
        //           eachSubmenu.name === 'Surveys'
        //       ) || 0;
        //   }
      } else if (redirectUrl.includes('dashboard')) {
        menuIndex =
          menuItems?.findIndex(
            (eachMenu: MenuPayload) => eachMenu.name === 'Dashboard'
          ) || 0;
      }
    }
    if(role===11){
      menuIndex=0
    } 
    return { menuIndex, submenuIndex };
  }
}
