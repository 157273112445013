import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss'],
})
export class NotificationIconComponent implements OnInit {
  @Input() newCount = 0;
  constructor() {}

  ngOnInit(): void {}
}
