<ng-container *ngIf="isShowServices">
    <acengage-question-creation-service-selection [questionForm]="questionForm" [acengageTreeForm]="acengageTreeForm"
        (sendMappingFields)="sendMappingFields.emit($event);">
    </acengage-question-creation-service-selection>
</ng-container>
<form [formGroup]="questionForm">
    <div class="add__question--container">
        <div *ngIf="!viewOnly" class="add__question--btn--container bg__content">
            <div class="add__question--discard" (click)="discardClicked.emit();">
                {{ isEdit ? 'Discard' : 'Clear' }}
            </div>
            <acengage-button [buttonClassesToAdd]="'secondary-btn'" buttonText="Save Changes"
                [isBtnDisable]="disableSaveBtn() || disableBtn" (buttonClicked)="saveClicked.emit();"></acengage-button>
        </div>
        <div class="add__question--contents bg__content">
            <div *ngIf="!viewOnly" class="add__question--input--container">
                <div class="add__question--left--content">
                    <acengage-text-input [inputType]="'text'" [maxLength]="null"
                        [placeholder]="'Enter the question here *'" formControlName="question">
                    </acengage-text-input>
                    <acengage-form-error [control]="questionForm.controls.question" validatorName="required">
                        Question is required.
                    </acengage-form-error>
                </div>
                <div class="add__question--right--content">
                    <div class="add__question--type--content">
                        <acengage-select [options]="questionTypes" [disabled]="isEdit" class="select__services--select"
                            [placeHolder]="'Select Type *'" formControlName="type"
                            (ngModelChange)="typeChanged.emit($event);">
                        </acengage-select>
                        <acengage-form-error [control]="questionForm.controls.type" validatorName="required">
                            Question type is required.
                        </acengage-form-error>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="questionFormControls['type'].value === 3 || questionFormControls['type'].value === 4">
                <acengage-choice-question-type [questionForm]="questionForm"
                    [isMultiChoice]="false"
                    (addClicked)="addCheckboxOptionClicked.emit();"
                    (removeClicked)="removeCheckboxOptionClicked.emit($event);"
                    (addFollowUp)="addFollowUpClicked.emit($event);"
                    (addQuestion)="addQuestionClicked.emit($event);"
                    (removeFollowup)="removeFollowUpClicked.emit($event)"
                    (removeQuestion)="removeQuestionClicked.emit($event)"
                    [nestedQuestions]="nestedQuestions">
                    <!-- 
                    (updateMainForm)="updateMainFormForNested.emit($event)" -->
                </acengage-choice-question-type>
             
            </ng-container>
            <div class="add__question--input--container block-div" *ngIf="questionFormControls['type'].value === 1">
                <div class="add__question--left--content answer__content--star">
                    <acengage-rating [starCount]="0" [readonly]="true"></acengage-rating>
                </div>
                 <!-- follow up questions -->
                 <div class="follow--questions">
                    <div class="add__question--link add" (click)="starNest=true">
                        <i class="pi pi-plus"></i>
                        <span>Add Follow up Question</span>
                    </div>
                    <div class="follow--questions__section" *ngIf="starNest">
                        <div class="follow--questions__head">
                            <p class="follow-p">Follow up question</p>
                            <div>
                                <div class="add__question--link remove" (click)="removeRating()">
                                    <i class="pi pi-trash"></i>
                                    <span>Remove</span>
                                </div>
                            </div>
                        </div>
                        <acengage-text-input [inputType]="'text'" [maxLength]="null" [placeholder]="'Enter the question here'" formControlName="followup_question"></acengage-text-input>
                        <div class="follow--questions__add">
                            <acengage-rating [starCount]="starVal" [readonly]="false" (onRate)="setHandleOnRate($event)"></acengage-rating>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="questionFormControls['type'].value === 2">
                <acengage-slider-question-type  [questionForm]="questionForm" [isEdit]="isEdit" (addFollowup)="addFollowupQuestion()"  (removeFollowup)="removeNestClicked.emit();sliderNest=false;"></acengage-slider-question-type>
            </ng-container>
            <div class="add__question--input--container question-block" *ngIf="questionFormControls['type'].value === 5">
                <div class="add__question--left--content answer__content--input">
                    <acengage-text-area [classesToAdd]="'disabled'" [disabled]="true" [placeholder]="'Text box'">
                    </acengage-text-area>
                </div> 
            </div>
            <div class="add__question--tree--container question-block" *ngIf="questionFormControls['type'].value === 6">
                <form #treeFormValidation="ngForm" class="acengage__tree--container">
                    <acengage-add-edit-tree-view [treeForm]="acengageTreeForm" [isShowServices]="false"
                        [treeFormValidation]="treeFormValidation" [processType]="processType"
                        [mappingFields]="treeMappingFields" (addClicked)="addOptions();" [nest]="true">
                    </acengage-add-edit-tree-view>
                </form>
       
                 
            </div>
            <div class="add__question--input--container display__na--content"
                *ngIf="questionFormControls['type'].value">
                <acengage-checkbox-input formControlName="is_na_allowed"
                    [classesToAdd]="'custom_primary_border_checkbox'"></acengage-checkbox-input>
                <span>Display NA Option</span>
            </div>
        </div>
    </div>
</form>