<div class="address__form--header--container">
    <h5><ng-content select=".heading"></ng-content></h5>
    <div *ngIf="isShowCheckbox" class="address__form--checkbox--container">
        <acengage-checkbox-input [classesToAdd]="'custom_small_checkbox'" [isNeedOutput]="true"
            [disabled]="checkDisable()" (checkboxClicked)="isSameClicked.emit($event);">
        </acengage-checkbox-input>
        <div class="small__text">
            <ng-content select=".checkbox__desc"></ng-content>
        </div>
    </div>
</div>
<form class="address__form--input--container remove__top__spacing" [formGroup]="_addressGroup">
    <div class="address__form--input--each">
        <acengage-text-input [inputType]="'text'" [formControl]="_addressGroup.controls['address_line1']"
            [placeholder]="'Address Line 1'">
        </acengage-text-input>
        <acengage-form-error [control]="_addressGroup.controls.address_line1" validatorName="required">
            Address line 1 is required
        </acengage-form-error>
    </div>
    <div class="address__form--input--each">
        <acengage-text-input [inputType]="'text'" [formControl]="_addressGroup.controls['address_line2']"
            [placeholder]="'Address Line 2'">
        </acengage-text-input>
        <acengage-form-error [control]="_addressGroup.controls.address_line2" validatorName="required">
            Address line 2 is required
        </acengage-form-error>
    </div>
    <div class="address__form--input--each">
        <acengage-select [options]="countries" [formControl]="_addressGroup.controls['country']" [searchBy]="'name'"
            [placeHolder]="'Country'" (ngModelChange)="getState($event);">
        </acengage-select>
        <acengage-form-error [control]="_addressGroup.controls.country" validatorName="required">
            Country is required
        </acengage-form-error>
    </div>
    <div class="address__form--input--each">
        <acengage-select [options]="states" [searchBy]="'name'" [formControl]="_addressGroup.controls['state']"
            [placeHolder]="'State'" (ngModelChange)="getCity($event);" [disabled]="!_addressGroup.value.country">
        </acengage-select>
        <acengage-form-error [control]="_addressGroup.controls.state" validatorName="required">
            State is required
        </acengage-form-error>
    </div>
    <div class="address__form--input--each">
        <acengage-select [options]="cities" [searchBy]="'name'" [formControl]="_addressGroup.controls['city']"
            [placeHolder]="'City'" [disabled]="!_addressGroup.value.state">
        </acengage-select>
        <acengage-form-error [control]="_addressGroup.controls.city" validatorName="required">
            City is required
        </acengage-form-error>
    </div>
    <div class="address__form--input--each">
        <acengage-text-input [inputType]="'text'" [formControl]="_addressGroup.controls['zip_code']"
            [allowedKeys]="zipCodeRegex" [maxLength]="10" [placeholder]="'Zip Code'">
        </acengage-text-input>
        <acengage-form-error [control]="_addressGroup.controls.zip_code" validatorName="required">
            Zip Code is required
        </acengage-form-error>
    </div>
</form>
