<ng-container *ngIf="optionlist?.length">
  <div class="horizontal" [ngClass]="{'displayBlock': showVeritically}">
    <ng-container *ngIf="behaveLikeRadio">
    <div *ngFor="let option of optionlist; let i = index;" class="checkBox-wrap">
      <div>
        <p-checkbox [name]="option.name" (click)="clearCheck($event)" [value]="option.value" [(ngModel)]="option.valueSelection"
        [disabled]="option.disabled ? option.disabled : false" (ngModelChange)="updateValue($event)"
        (onBlur)="onTouch();"></p-checkbox>
      <label *ngIf="option?.value" [for]="option.value">{{ isDateInput?((option.name==='Custom' || option.name==='Reject Slots')?option.name:option.name | date : 'MMMM d, y, h:mm a'):option.name }}</label>
      </div>
      <div class="follow-q" *ngIf="followUp && option?.dropdown_question">
        <div class="follow-head">Reasoning</div>
        <div class="follow-question">{{option?.dropdown_question['question']}}</div>
        <div  class="checkbox__with__answer displayQuestion" *ngFor="let item of option?.dropdown_question['options']">
         <p-radioButton [disabled]="true"></p-radioButton>
        <label class="Reasoning-lbl">{{ item.choice }}</label>
  
        </div>
      </div> 
      <div class="follow-q" *ngIf="followUp && option?.followup_question">
        <div class="follow-head">Follow Up</div>
        <div class="follow-question">{{option?.followup_question}}</div>
      </div>  
      <!-- <div *ngIf="option?.followup_question">{{option.followup_question}}</div> -->
    </div>
    </ng-container>
    <ng-container *ngIf="!behaveLikeRadio">
      <div *ngFor="let option of optionlist; let i = index;" class="checkBox-wrap">
        <div>
          <p-checkbox [name]="option.name" [value]="option.value" [(ngModel)]="value"
          [disabled]="option.disabled ? option.disabled : false" (ngModelChange)="updateValue($event)"
          (onBlur)="onTouch();"></p-checkbox>
        <label *ngIf="option?.value" [for]="option.value">{{ option.name }}</label>
        </div>
        <div class="follow-q" *ngIf="followUp && option?.dropdown_question">
          <div class="follow-head">Reasoning</div>
          <div class="follow-question">{{option?.dropdown_question['question']}}</div>
          <div  class="checkbox__with__answer displayQuestion" *ngFor="let item of option?.dropdown_question['options']">
           <p-radioButton [disabled]="true"></p-radioButton>
          <label>{{ item.choice }}</label>
    
          </div>
        </div> 
        <div class="follow-q" *ngIf="followUp && option?.followup_question">
          <div class="follow-head">Follow Up</div>
          <div class="follow-question">{{option?.followup_question}}</div>
        </div>  
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!optionlist?.length">
  <p-checkbox [styleClass]="classesToAdd" [binary]="true" [label]="cbLabel" [disabled]="disabled" [(ngModel)]="value"
    (ngModelChange)="updateValue($event)" (onBlur)="onTouch();">
  </p-checkbox>
</ng-container>
