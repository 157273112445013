import { Component, OnInit } from '@angular/core';
import { getForgot } from '@acengage/app/app/authentication/state/auth.selectors';
import * as AuthActions from '@acengage/app/app/authentication/state/auth.actions';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@acengage/app/app/state/app.state';
import { AuthenticationService } from 'apps/acengage/src/app/core/services';
import * as appActions from '@acengage/app/app/state/app.actions';

@Component({
  selector: 'acengage-forgot-password-step-three',
  templateUrl: './forgot-password-step-three.component.html',
  styleUrls: ['./forgot-password-step-three.component.scss'],
})
export class ForgotPasswordStepThreeComponent implements OnInit {
  newPassword: string = '';
  confirmNewPassword: string = '';
  displayToast: boolean = false;
  localStoreValues: any;
  showLoader: boolean = false;
  userDtl:any;
  toasterInfoObj = {
    severity: 'success',
    summary: '',
    detail: '',
  };
  constructor(private router: Router, private fb: FormBuilder, private store: Store,
    private auth: AuthenticationService,
    private appStore: Store<AppState>,) {}

  ngOnInit(): void {
    this.store.select(getForgot).subscribe((res) => {
      this.userDtl = res.data;
    });
  }
  setNewpassword() {
    this.displayToast = false;
    if(!this.checkForStrongPassword()){
      return
    }
    if (this.newPassword != this.confirmNewPassword) {
      this.appStore.dispatch(appActions.showToaster({
        class: 'error',
        header: 'Password Mismatch',
        message: '',
        'showToaster': true
      }));
      return;
    }
    let body = {
      username: this.userDtl,
      password: this.confirmNewPassword as any,
    };
    this.showLoader = true;
    this.store.dispatch(AuthActions.ForgotResetPassword({ payload: body }))
  }
  checkForStrongPassword(){
    if(this.newPassword.search(/[a-z]/) < 0) {
      this.appStore.dispatch(appActions.showToaster({
        class: 'error',
        header: 'Password should contain at least one lowercase',
        message: '',
        'showToaster': true
      }));
      return false

      } else if(this.newPassword.search(/[A-Z]/) < 0) {
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should contain at least one uppercase',
          message: '',
          'showToaster': true
        }));
        return false

      } else if(this.newPassword.search(/[0-9]/) < 0) {
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should contain at least one number',
          message: '',
          'showToaster': true
        }));
        return false

      } else if(this.newPassword.search(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)<0){
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should contain at least one special character',
          message: '',
          'showToaster': true
        }));
        return false
      }
      else if(this.newPassword.length < 8) {
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should have minimum 8 characters',
          message: '',
          'showToaster': true
        }));
       return false

      } else {

        return true;

      }
  }
  isValidPassword() {
    return (this.newPassword.trim() !== '' && this.confirmNewPassword.trim() !== '') ? false : true
  }
}
