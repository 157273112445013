import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class UnauthGuardService implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const loggedInDtls = localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login') || '{}') : {};
        if (
            loggedInDtls &&
            loggedInDtls.roles &&
            loggedInDtls.roles[0] &&
            loggedInDtls.roles[0].id === 11 &&
            !loggedInDtls.is_client_2fa_verified &&
            loggedInDtls.is_2fa
          ) {
            return true;
        } else if (loggedInDtls.token) {
            this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }
}
