import { Role } from './../models/login.model';
import { createReducer, on, Action, ActionReducer, State } from '@ngrx/store';

import * as authActions from './auth.actions';
import { MenuPayload } from '../../state/app.model';
import { notificationList } from '../../notification/models/notification-response.interface';

export const LOGIN_FEATURE_KEY = 'login';

export interface LoginState {
  token: string;
  refreshToken: string;
  id: number;
  default_password_changed: boolean;
  first_login: boolean;
  is_2fa: boolean;
  qr_text: string;
  saveUser: string | null;
  isLoading: boolean;
  popup?: boolean;
  loaded: boolean;
  roles: Role[];
  error: string;
  toasterInfo: object;
  username?: string;
  picture?: string;
  menuItem: MenuPayload[];
  notify: notificationList[];
  selectedMenu: number;
  selectedSubMenu?: number;
  userDetails: any;
  client_details: any;
  usage_id?: number;
  full_name: string | null;
  is_client_2fa_verified: boolean;
  progress_of_completion: number;
}

export const initialState: LoginState = {
  token: '',
  refreshToken: '',
  id: -1,
  default_password_changed: false,
  first_login: false,
  is_2fa: false,
  isLoading: false,
  saveUser: '',
  loaded: false,
  popup: false,
  error: '',
  qr_text: '',
  toasterInfo: {
    severity: 'success',
    summary: 'Success',
    detail: '',
  },
  username: '',
  picture: '',
  roles: [],
  notify: [],
  menuItem: [],
  selectedMenu: 0,
  selectedSubMenu: 1,
  userDetails: {},
  client_details: {},
  usage_id: 0,
  full_name: '',
  is_client_2fa_verified: false,
  progress_of_completion: 0
};

const reducer = createReducer(
  initialState,
  on(authActions.firstLoginRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    error: '',
  })),
  on(authActions.firstLoginSuccess, (state, result) => ({
    ...state,
    token: result.token,
    refreshToken: result.refreshToken,
    id: result.id,
    default_password_changed: result.default_password_changed,
    first_login: result.first_login,
    is_2fa: result.is_2fa,
    qr_text: result.qr_text,
    username: result.username,
    client_details: result.client_details,
    roles: result.roles,
    picture: result.picture,
    usage_id: result.usage_id,
    full_name: result.full_name,
    is_client_2fa_verified: result.is_client_2fa_verified,
    progress_of_completion: result.progress_of_completion
  })),
  on(authActions.firsLoginFailure, (state, { error }) => ({ ...state, error })),
  on(authActions.LoginRequest, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    error: '',
  })),
  on(authActions.LoginSuccess, (state, result) => ({
    ...state,
    token: result.token,
    refreshToken: result.refreshToken,
    id: result.id,
    default_password_changed: result.default_password_changed,
    first_login: result.first_login,
    is_2fa: result.is_2fa,
    qr_text: result.qr_text,
    username: result.username,
    client_details: result.client_details,
    roles: result.roles,
    picture: result.picture,
    usage_id: result.usage_id,
    full_name: result.full_name,
    is_client_2fa_verified: result.is_client_2fa_verified,
    progress_of_completion: result.progress_of_completion
  })),
  on(authActions.LoginFailure, (state, { error }) => ({ ...state, error })),
  on(authActions.updateLoginState, (state, payload: any) => ({
    ...state,
    token: payload.token,
    refreshToken: payload.refreshToken,
    id: payload.id,
    default_password_changed: payload.default_password_changed,
    first_login: payload.firsLogin,
    is_2fa: payload.is_2fa,
    qr_text: payload.qr_text,
    roles: payload.roles,
    is_client_2fa_verified: payload.is_client_2fa_verified,
    progress_of_completion: payload.progress_of_completion
  })),
  on(authActions.updateLoginStateAndRedirect, (state, payload: any) => ({
    ...state,
    token: payload.token,
    refreshToken: payload.refreshToken,
    id: payload.id,
    default_password_changed: payload.default_password_changed,
    first_login: payload.firsLogin,
    is_2fa: payload.is_2fa,
    qr_text: payload.qr_text,
    is_client_2fa_verified: payload.is_client_2fa_verified,
    progress_of_completion: payload.progress_of_completion
  })),
  on(authActions.updateLoginStateFromVerify, (state, payload: any) => ({
    ...state,
    token: payload.token,
    id: payload.id,
    default_password_changed: payload.default_password_changed,
    first_login: payload.firsLogin,
    is_2fa: payload.is_2fa,
    qr_text: payload.qr_text,
    is_client_2fa_verified: payload.is_client_2fa_verified,
    progress_of_completion: payload.progress_of_completion
  })),

  // forgot

  on(authActions.ForgotPasswordReq, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    error: '',
  })),
  on(authActions.ForgotPasswordSuccess, (state, result) => ({
    ...state,
    saveUser: result.saveUser,
    isLoading: false,
    loaded: true,
  })),
  on(authActions.ForgotPasswordFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(authActions.ForgotOTPReq, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    error: '',
  })),
  on(authActions.ForgotOTPSuccess, (state, result) => ({
    ...state,
    isLoading: false,
    loaded: true,
  })),
  on(authActions.ForgotOTPFailure, (state, { error }) => ({ ...state, error })),

  on(authActions.ForgotPasswordReq, (state) => ({
    ...state,
    isLoading: true,
    loaded: false,
    error: '',
  })),
  on(authActions.ForgotPasswordSuccess, (state, result) => ({
    ...state,
    isLoading: false,
    loaded: true,
  })),
  on(authActions.ForgotPasswordFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // side menu

  on(authActions.sideMenuBar, (state) => ({ ...state, isLoading: true })),
  on(authActions.sideMenuBarSuccess, (state, result) => {
    return {
      ...state,
      menuItem: result.menuItem,
      selectedMenu:
        result.menuItem[
          (result?.selectMenuDtls?.menuIndex != -1
            ? result?.selectMenuDtls?.menuIndex
            : 1) || 1
        ].main_menu_id,
      selectedSubMenu:
        result.menuItem[result?.selectMenuDtls?.menuIndex || 1]?.sub_menu[
          result?.selectMenuDtls?.submenuIndex || 0
        ]?.sub_menu_id,
      isLoading: false,
    };
  }),
  on(authActions.sideMenuBarFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  // notify

  on(authActions.notificationDtl, (state) => ({ ...state, isLoading: true })),
  on(authActions.notificationSuccess, (state, result) => {
    return { ...state, notify: result.notification, isLoading: false };
  }),
  on(authActions.notificationFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  // Get User Details
  on(authActions.profileDetailsFetchSuccess, (state, userDtlsRes) => ({
    ...state,
    userDetails: userDtlsRes.userDetails,
    picture: userDtlsRes?.userDetails?.personal_info?.picture?.picture || '',
    isLoading: false,
  })),

  on(authActions.selectedMenu, (state, { selectedMenu, selectedSubMenu }) => ({
    ...state,
    selectedMenu,
    selectedSubMenu,
  })),

  on(authActions.showPopup, (state, popup) => ({
    ...state,
    popup: popup.popup,
  }))
);

export function LoginReducer(state: LoginState | undefined, action: Action) {
  return reducer(state, action);
}
