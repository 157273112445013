<div class="breadcrumb__btn__container">
    <div class="breadcrumb__content">
        <ng-container *ngIf="isShowHome">
            <p-breadcrumb styleClass="custom_breadcrumb" [model]="items" [home]="homeItem"
                (onItemClick)="navigateTo($event);">
            </p-breadcrumb>
        </ng-container>
        <ng-container *ngIf="!isShowHome">
            <p-breadcrumb styleClass="custom_breadcrumb" [model]="items" (onItemClick)="navigateTo($event);">
            </p-breadcrumb>
        </ng-container>
    </div>
    <div class="btn__container">
        <ng-container *ngFor="let eachButtonDtl of buttonDetails">
            <div *ngIf="!eachButtonDtl.hideBtn && eachButtonDtl.isFlatBtn"
                [class]="'flat__btn--link '+eachButtonDtl.buttonClassesToAdd"
                (click)="buttonClicked.emit(eachButtonDtl.buttonText ? eachButtonDtl.buttonText : eachButtonDtl.iconDetails?.name);">
                {{eachButtonDtl.buttonText}}</div>
            <acengage-button [buttonText]=" eachButtonDtl.buttonText"
                *ngIf="!eachButtonDtl.hideBtn && !eachButtonDtl.isFlatBtn || editNcBtn"
                [buttonClassesToAdd]="eachButtonDtl.buttonClassesToAdd" [iconDtls]="eachButtonDtl.iconDetails"
                [isBtnDisable]="eachButtonDtl.disabled" [isNgContentAdded]="eachButtonDtl?.isNgContentAdded"
                (buttonClicked)="buttonClicked.emit(eachButtonDtl.buttonText ? eachButtonDtl.buttonText : eachButtonDtl.iconDetails?.name);">
                <ng-content></ng-content>
            </acengage-button>
        </ng-container>
    </div>
</div>
