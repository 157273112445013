import { Component, OnInit, Output, Input, EventEmitter, ViewEncapsulation ,ViewChild,ElementRef} from '@angular/core';
import { CommonIconDetails } from '../../models/button.model';

@Component({
  selector: 'acengage-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements OnInit {
  @Input() classToadd = 'primary-btn';
  @Input() tooltipText = '';
  @Input() buttonType:string = 'button';
  @ViewChild('myButton') myButton: ElementRef;
  @Input() iconDtls: CommonIconDetails | null | undefined = {
    name: '',
    position: ''
  };
  @Input() isBtnDisable: boolean | null | undefined = false;
  @Input() isNgContentAdded: boolean | null | undefined = false;
  @Input()
  set buttonText(value: any) {
    this.label = value;
  }
  @Input() set buttonClassesToAdd
    (value: any) {
    this.classToadd = value;
  }

  @Output() buttonClicked = new EventEmitter();
  constructor() { }
  label = '';
  ngOnInit(): void { }
  buttonClick(event: any) {
    this.myButton.nativeElement.blur();
    this.buttonClicked.emit(event);
  }
}
