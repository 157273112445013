import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'acengage-components-acengage',
  templateUrl: './components-acengage.component.html',
  styleUrls: ['./components-acengage.component.scss'],
})
export class ComponentsAcengageComponent implements OnInit {
  constructor(private fb: FormBuilder) {}
  profileForm: FormGroup;
  firstName: string;
  displayToast=false;
  openModel=false;
  categories: any[] = [
    { value: 'Male', name: 'M' },
    { value: 'Female', name: 'F' },
    { value: 'Others', name: 'O' },
  ];
  genderRadioOptions: any[] = [
    { value: 'Male', name: 'M', disabled: false },
    { value: 'Female', name: 'F', disabled: false },
    { value: 'Others', name: 'O', disabled: false },
  ];

  countryOptions: any[] = [
    { value: 'INDIA', name: 'INDIA', disabled: false },
    { value: 'CHINA', name: 'CHINA', disabled: false },
    { value: 'PAKISTAN', name: 'PAKISTAN', disabled: false },
  ];
  ageOptions: any[] = [
    { value: 'INDIA', name: 'INDIA'},
    { value: 'CHINA', name: 'CHINA'},
    { value: 'PAKISTAN', name: 'PAKISTAN' },
  ];
  ageSelectOptions: any[] = [
    { value: null, name: 'SELECT' , disabled: true},
    { value: 'INDIA', name: 'india'},
    { value: 'CHINA', name: 'china'},
    { value: 'PAKISTAN', name: 'pak' },
  ];
  ngOnInit(): void {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: [null, Validators.required],
      gender: [],
      country: [],
      age: [],
    });
  }

  toasterInfoObj={
    'severity':'success',
    'summary':'Service Messagee',
    'detail':'Toaster Details'
  };

  fileUpload(event: any) {
  }
  onUpdate(event: any) {
  }
  onSubmit() {
  }
  openModelDialog(){
    this.openModel=true
    setTimeout(()=>{
      this.openModel=false
    },100)
  }
}

