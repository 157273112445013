import { LOGIN_FEATURE_KEY } from './../authentication/state/auth.reducer';
import { LoginState } from '@acengage/app/app/authentication/state/auth.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter, pipe } from 'rxjs';
import { APP_FEATURES } from './app.reducers';
import { AppState } from './app.state';

export const getAppState = createFeatureSelector<AppState>(APP_FEATURES);

export const getLoginState = createFeatureSelector<LoginState>(LOGIN_FEATURE_KEY);


export const getToasterState = createSelector(getAppState, (state: AppState) => ({ toasterConfig: state.toasterConfig }));

export const getLoaderState = createSelector(getAppState, (state: AppState) => ({ showLoader: state.showLoader }));
export const getisRowClickedState = createSelector(getAppState, (state: AppState) => ({ isRowClicked: state.isRowClicked }));
export const getisSearchAvailableState = createSelector(getAppState, (state: AppState) => ({ isSearchAvailable: state.isSearchAvailable }));

export const getSideMenuState = createSelector(getAppState, (state: AppState) => ({ menusItem: state.menuItem}));


export const getPermissionSideMenuState = createSelector(getAppState,getLoginState, (state: AppState, login:LoginState) => {
  const permisionList = state.menuItem.filter(menuState => state.selectedMenu === menuState.main_menu_id)
  if(!permisionList.length){
    return []
  }
  const permissionRole = permisionList[0].permission_list.filter(permission => login.roles.find((eachRole)=>{
    return eachRole.name === permission.role
  }))
  return permissionRole
});
