import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PlyrComponent } from 'ngx-plyr';

@Component({
  selector: 'acengage-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnChanges {
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  player: Plyr;
  @Input() videoSources: any;
  videoSource: Plyr.Source[] = [];

  played(event: Plyr.PlyrEvent) {
  }
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  pause() {
    this.videoPlayer.nativeElement.pause();
  }

  togglePlayPause() {
    if (this.videoPlayer.nativeElement.paused) {
      this.play();
    } else {
      this.pause();
    }
  }
  play(): void {
    this.player.play(); // or this.plyr.player.play()
    this.videoPlayer.nativeElement.play();
  }
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if ('videoSources' in changes) {
      // Assign the value of videoSources to videoSource
      this.videoSource = this.videoSources;

      // You can also log the updated value
      console.log('Video sources changed:', this.videoSource);
    }
  }

  ngOnInit(): void {}
}
