import { selectedSubMenu } from './../../state/app.actions';
import { ForgotDtls, ForgotOTPPayload, ForgotPayload, ForgotResetPasswordParams, sixDigitOtp } from './../models/login.model';
import { createAction, props } from '@ngrx/store';
import { FirstLoginInformation, LoginParams, firstLoginPayloadSucess, LoginInformation, ResetPasswordInformation, ResetPasswordParams, twoFAParams, QrCodePayloadSuccess } from '../models/login.model'
import { MenuPayload } from '../../state/app.model';
import { ProfilePersonalInfoDetails } from '../../setting/state/setting.model';
import { notificationList } from '../../notification/models/notification-response.interface';

export const FIRST_LOGIN_SUCCESS = '[First Login/API] Load Users Success';
export const FIRST_LOGIN_FAILURE = '[First Login/API] Load Users Failure';
export const NORMAL_LOGIN_SUCCESS = '[Login/API] Load Users Success';
export const NORMAL_LOGIN_FAILURE = '[Login/API] Load Users Failure';
export const RESET_PASSWORD_SUCCESS = '[Reset Password/API] Reset Password Success';
export const RESET_PASSWORD_FAILURE = '[Reset Password/API] Reset Password Failure';
export const VERIFY_API_SUCESS = '[Verify 2fa/API] Verify 2fa Success';
export const VERIFY_API_FAILURE = '[Verify 2fa/API] Verify 2fa Failure';
export const FORGOT_PASSWORD_SUCESS = '[Forgot api] Forgot Password';
export const FORGOT_PASSWORD_FAILURE = '[Forgot api] Forgot Password';
export const FORGOT_OTP_SUCESS = '[Forgot api] Forgot OTP';
export const FORGOT_OTP_FAILURE = '[Forgot api] Forgot OTP';
export const FORGOT_Reset_Password_SUCESS = '[Forgot api] Forgot Reset Password';
export const FORGOT_Reset_Password_FAILURE = '[Forgot api] Forgot Reset Password';

// First Login Actions

export const firstLoginRequest = createAction('[Login Page] first login user', props<{ payload: FirstLoginInformation }>());
export const logoutUser = createAction('[Auth] User Logout');

export const firstLoginSuccess = createAction(
  FIRST_LOGIN_SUCCESS,
  props<firstLoginPayloadSucess>()
);

export const firsLoginFailure = createAction(
  FIRST_LOGIN_FAILURE,
  props<{ error: string }>()
);

// Normal Login Actions

export const LoginRequest = createAction('[Login Page] normal login user', props<{ payload: LoginParams }>());

export const LoginSuccess = createAction(
  NORMAL_LOGIN_SUCCESS,
  props<firstLoginPayloadSucess>()
);

export const LoginFailure = createAction(
  FIRST_LOGIN_FAILURE,
  props<{ error: string }>()
);

// Reset Password Actions
export const ResetPasswordRequest = createAction('[Login Page] reset password', props<{ payload: ResetPasswordParams }>());
export const ResetPaswordSuccess = createAction(
  NORMAL_LOGIN_SUCCESS,
  props<firstLoginPayloadSucess>()
);

export const ResetPasswordGetQrCode = createAction(RESET_PASSWORD_SUCCESS, props<QrCodePayloadSuccess>())

export const ResetPaswordFailure = createAction(
  FIRST_LOGIN_FAILURE,
  props<{ error: string }>()
);

// 2FA Actions
export const VerifyTwoFA = createAction('[Login Page] verify 2fa', props<{ payload: twoFAParams }>());
export const VerifyTwoFASuccess = createAction(
  NORMAL_LOGIN_SUCCESS,
  props<firstLoginPayloadSucess>()
);

export const VerifyTwoFAFailure = createAction(
  FIRST_LOGIN_FAILURE,
  props<{ error: string }>()
);


//Update Login state value
export const updateLoginState = createAction(
  '[Login Page] update login state',
  props<{ payload: firstLoginPayloadSucess }>()
);

export const updateLoginStateAndRedirect = createAction(
  '[Login Page] update Login State And Redirect',
  props<any>()
);


export const updateLoginStateFromVerify = createAction(
  '[Login Page] update Login State From Verify',
  props<any>()
);



// forgot password
export const ForgotPasswordReq = createAction('[Forgot Page] forgot password', props<{ payload: ForgotPayload }>());
export const ForgotPasswordSuccess = createAction(
  FORGOT_PASSWORD_SUCESS,
  props<{ saveUser: string | null }>()
);

export const ForgotPasswordFailure = createAction(
  FORGOT_PASSWORD_FAILURE,
  props<{ error: string }>()
);
// otp
export const ForgotOTPReq = createAction('[Forgot Page] forgot OTP', props<{ payload: ForgotOTPPayload }>());
export const ForgotOTPSuccess = createAction(
  FORGOT_OTP_SUCESS,
  props<{ otpresult: ForgotDtls }>()
);

export const ForgotOTPFailure = createAction(
  FORGOT_OTP_FAILURE,
  props<{ error: string }>()
);
// forgor reset password
export const ForgotResetPassword = createAction('[Forgot Page] forgot ResetPassword', props<{ payload: ForgotResetPasswordParams }>());
export const ForgotResetPasswordSuccess = createAction(
  FORGOT_Reset_Password_SUCESS,
  props<{ passwordresult: ForgotDtls }>()
);

export const ForgotResetPasswordFailure = createAction(
  FORGOT_Reset_Password_FAILURE,
  props<{ error: string }>()
);

// side menu
export const sideMenuBar = createAction('[Side Menu] side menu bar', props<{ payload: any }>());
// notification
export const notificationDtl = createAction('[Notification] notification', props<{ payload: any }>());
//callback
export const callbackDtl = createAction('[Callback] callback', props<{ payload: any }>());

export const sideMenuBarSuccess = createAction('[Side Menu] side menu success', props<{ menuItem: MenuPayload[], selectMenuDtls: { menuIndex: number, submenuIndex: number } }>());
export const notificationSuccess = createAction('[notification] notification success', props<{ notification: notificationList[] }>());

export const sideMenuBarFailure = createAction('[Side Menu] side menu failure', props<{ error: string }>());

export const notificationFailure = createAction('[notification] notification failure', props<{ error: string }>());

export const selectedMenu = createAction('[Side menu] menu access', props<{ selectedMenu: number, selectedSubMenu?: number }>());



export const profileDetailsFetch = createAction('[Profile Page] Details Fetch');

export const profileDetailsFetchSuccess = createAction('[Profile Page] Details Fetch Success', props<{ userDetails: ProfilePersonalInfoDetails }>());

export const profileDetailsFetchFailure = createAction('[Profile Page] Details Fetch Failure', props<{ error: string }>());

export const showPopup = createAction('[Popup show] popup access', props<{ popup: boolean }>());
