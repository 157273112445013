<ng-container *ngIf="options?.length">
  <div class="radioButtonWrap" *ngFor="let option of options; let i = index">
    <div class="d-flex">
       <div>
      <p-radioButton [name]="option.name" [value]="option.value" [(ngModel)]="value" [disabled]="option?.disabled"
      (ngModelChange)="updateValue($event)"></p-radioButton>
    <label *ngIf="option.value" [for]="option.value">{{ option.name }}</label>
    </div>   

    <div class="map-cover" *ngIf="map && question['map'].length">
      <!-- <div class="map-drop" *ngFor="let item of question.nodes; let j =index"> -->
        <acengage-select [(ngModel)]="option['model'+i]" *ngIf="question['map'].length" [options]="question['map']" [placeHolder]="'Select Option'" (ngModelChange)="changeValue(option,i);">
        </acengage-select>
      <!-- </div> -->

    </div>
    </div>
   

    <div class="follow-q" *ngIf="followUp && option?.dropdown_question">
      <div class="follow-head">Reasoning</div>
      <div class="follow-question">{{option?.dropdown_question['question']}}</div>
      <div  class="checkbox__with__answer displayQuestion" *ngFor="let item of option?.dropdown_question['options']">
       <p-radioButton [disabled]="true"></p-radioButton>
      <label>{{ item.choice }}</label>

      </div>
    </div> 

    <div class="follow-q" *ngIf="followUp && option?.followup_question">
      <div class="follow-head">Follow Up</div>
      <div class="follow-question">{{option?.followup_question}}</div>
    </div>  
  </div>
</ng-container>
<ng-container *ngIf="!options?.length">
  <p-radioButton [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="updateValue($event)" (onBlur)="onTouch();">
  </p-radioButton>
</ng-container>