<!-- <div class="account__details--container">
  <div class="account__details--content">
    <div class="account__details--content-main">
      <div class="account__details--content-viewer">
        <acengage-document-viewer [url]="document"></acengage-document-viewer>
      </div>
    </div>
  </div>
</div> -->

<acengage-document-viewer
  [url]="document"
  (maxTime)="getContent($event)"
></acengage-document-viewer>
