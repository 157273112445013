import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CEInterventionsDetailsMenu } from '../../services-screens/candidate-engagement/models/CE-Interventions.interface';

@Component({
  selector: 'acengage-intervention-menu',
  templateUrl: './intervention-menu.component.html',
  styleUrls: ['./intervention-menu.component.scss'],
})
export class InterventionMenuComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }
  @Input() ServiceType:string ='CE';
  @Input() interventionsList: CEInterventionsDetailsMenu[] = [];
  @Input() selectedIntervention = 0;
  @Output() interventionTabChanged = new EventEmitter<number>();
  @Input() buddy_induction:any;
  ngOnInit(): void {}
}
