
<form>
<h3 class="textCenter marginBottomZero">Scan QR code to activate</h3>
<h3 class="textCenter marginTophalfrem">google authenticator</h3>
<div class="qrcodeImage marginBottom20px">
    <qrcode
        [qrdata]="qrCodeValue$ | async"
        [allowEmptyString]="true" [ariaLabel]="'QR Code image with the following content...'"
        [cssClass]="'center'" [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'canvas'"
        [errorCorrectionLevel]="'M'" [imageHeight]="50" [imageWidth]="50" [margin]="4" [scale]="1"
        [title]="'A custom title attribute'" [width]="300"></qrcode>
</div>
<div class="marginBottom10px marginTop2rem">
    <acengage-button (buttonClicked)="setup2FA()" [isBtnDisable]="checkQrText()" type="button" buttonText="Next"></acengage-button>
</div>
</form>

