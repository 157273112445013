import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { QueryStatusOptions } from '../../models/data';
import { SelectOption } from '../../models/select.model';
import {
  QueryEmailRecurit,
  QueryFormField,
  QueryOtherFilesDetailsResponse,
} from '../../queries/models/queries-response.interface';
import { CECampaignService } from '../../services-screens/services/ce-campaign.service';
import { CommonService } from '../../services/common.service';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { CEInterventionTreeList } from '../../services-screens/candidate-engagement/models/CE-Intervention-Process.interface';
import { Config } from '../../config';

@Component({
  selector: 'acengage-add-edit-query',
  templateUrl: './add-edit-query.component.html',
  styleUrls: ['./add-edit-query.component.scss'],
})
export class AddEditQueryComponent implements OnInit {
  addQuery: FormGroup;
  openQueryModel = false;
  @Input() set openQuesModel(value: boolean) {
    this.openQueryModel = value;
  }
  empID: any;
  campaignID: number;
  @Input() isEdit = false;
  @Input() selectQueryId: number;
  @Input() interventionDetails: any;
  treeList: CEInterventionTreeList[] = [];
  queryData: any;
  selectedTreeID: any;
  interventionNodeDtls: any;
  path: any;
  isUpdateCallTreeTouched = false;
  queryStatus: Array<SelectOption> = QueryStatusOptions;
  uploadedOtherFilesForEdit: File[] = [];
  @Input() interventionID: number;
  duplicates = [];
  @Output() updateQueryPopupState = new EventEmitter();
  isFromGlobalSearch = false;
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private campaignCEService: CECampaignService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      ({
        campaignID,
        empID,
        mode,
        interventionID,
        path,
        isFromGlobalSearch,
      }: any) => {
        this.empID = empID;
        this.campaignID = campaignID;
        this.isFromGlobalSearch = isFromGlobalSearch == 'true' ? true : false;
        if (mode === 'edit') {
          this.isEdit = true;
        }
        if (path !== undefined) {
          this.path = path;
        }
        this.getCallStatusTreeList();
        this.initForm();
        this.getEmailRecruiterList();
        if (interventionID) {
          if (Number(interventionID) !== 0 && Number(interventionID) !== -1) {
            this.interventionID = interventionID;
            this.getInterventionDtls(interventionID);
          }
        }
      }
    );
  }
  initForm() {
    if (this.isEdit) {
      this.getCEQuery();
    } else {
      this.createFormQuery();
    }
  }
  getCEQuery() {
    let params = 0;
    if (this.selectQueryId) {
      params = this.selectQueryId;
    } else {
      params = this.empID;
    }
    this.campaignCEService.getCEDetailQuery(params).subscribe((data: any) => {
      this.queryData = data?.data;
      this.selectedTreeID = this.queryData?.node_name?.id;
      this.interventionDetails = data?.data;
      this.interventionNodeDtls = this.queryData?.node_name?.choice;
      if (this.queryData.title) {
        this.createFormQuery();
        this.addQuery.get('title')?.setValue(this.queryData?.node_name?.id);
      }
      this.bindFiles();
    });
  }

  clearChoice() {
    this.queryData.node_name.choice = '';
    this.interventionDetails.call_status.choice = '';
  }

  checkAlreadyEmail(cntrl: any, i: any) {
    const dict: any = {};
    cntrl.controls.forEach((item: any, index: any) => {
      const email = item.value.toLowerCase();
      dict[email] = dict[email] || [];
      dict[email].push(index);
    });
    let duplicates: any = [];
    for (const key in dict) {
      if (dict[key].length > 1) duplicates = duplicates.concat(dict[key]);
    }
    this.duplicates = duplicates;
    if (this.duplicates.length) {
      cntrl.controls.forEach((item: any) => {
        item.setErrors(null);
      });
      for (const index of duplicates) {
        cntrl.at(+index).setErrors({ duplicated: true });
      }
    } else {
      cntrl.controls.forEach((item: any) => {
        item.setErrors(null);
      });
    }
  }
  getInterventionDtls(interventionID: number) {
    // this.showLoaderTrigger(true);
    this.campaignCEService
      .getInterventionDetails(interventionID)
      .subscribe((interventionDtlResp: any) => {
        this.interventionDetails = interventionDtlResp?.data || {};
        this.addQuery
          .get('title')
          ?.setValue(this.interventionDetails?.call_status?.id);
        this.addQuery
          .get('description')
          ?.setValue(this.interventionDetails?.interview?.notes);
        this.selectedTreeID = this.interventionDetails?.call_status?.id;
        this.interventionNodeDtls =
          this.interventionDetails?.call_status?.choice;
        // this.showLoaderTrigger(false);
      });
  }
  onSubmit(data: any) {
    this.selectedTreeID = data;
    this.addQuery.get('title')?.setValue(data);
  }

  bindFiles() {
    this.uploadedOtherFilesForEdit = [];
    this.doOtherFilesRecursion();
  }

  getCallStatusTreeList() {
    this.showLoaderTrigger(true);
    this.campaignCEService
      .getCETreeNodes(this.campaignID)
      .subscribe((treeNodesResp: any) => {
        this.treeList = treeNodesResp?.data || [];
        this.showLoaderTrigger(false);
      });
  }

  getEmailRecruiterList() {
    // this.showLoaderTrigger(true);
    this.commonService.getEmailRecruitData(this.empID).subscribe((res: any) => {
      this.createEmailRecurit(res.data);
      // this.showLoaderTrigger(false);
    });
  }

  doOtherFilesRecursion() {
    const multiOtherFiles: File[] = [];
    let incOtherFilesCnt = 0;
    const getMultiFilesFromUrl = (
      url: string | null,
      fileName: string = 'file.pdf',
      fileType: string,
      fileID?: number
    ) => {
      this.commonService.getBlobFromUrl(url).subscribe((data) => {
        const file: any = new File([data], fileName, {
          type: data.type,
        });
        file.id = fileID;
        multiOtherFiles.push(file);
        incOtherFilesCnt++;
        recurFileCreation();
      });
    };

    const recurFileCreation = () => {
      if (incOtherFilesCnt < this.queryData.attachments.length) {
        const otherFileDtls: QueryOtherFilesDetailsResponse =
          this.queryData.attachments[incOtherFilesCnt];
        if (otherFileDtls?.url) {
          getMultiFilesFromUrl(
            otherFileDtls.url,
            otherFileDtls.filename,
            'other_file',
            otherFileDtls.id
          );
        }
      } else {
        this.uploadedOtherFilesForEdit = multiOtherFiles;
        incOtherFilesCnt = 0;
      }
    };

    recurFileCreation();
  }

  questionsModelState(value: boolean) {
    this.updateQueryPopupState.emit(value);
    this.addQuery?.reset();
  }

  createFormQuery() {
    this.addQuery = this.fb.group({
      title: this.fb.control(this.queryData?.title),
      description: this.fb.control(this.queryData?.description, [
        Validators.required,
      ]),
      recommendation: this.fb.control(this.queryData?.recommendation, [
        Validators.required,
      ]),
      recruiter_email_id: this.fb.array([this.createEmailRecurit({})]),
      attachments: this.fb.control(''),
      notes: this.fb.control(this.queryData?.notes),
      resolution: this.fb.control(this.queryData?.resolution),
      remove_files: this.fb.control(null),
      status: this.fb.control(this.queryData?.status),
    });
  }
  createEmailRecurit(email: any) {
    let formArr: any;
    if (this.queryData?.recruiter_email_id || email?.recruiter_email_id) {
      setTimeout((res: any) => {
        formArr = this.addQuery?.controls['recruiter_email_id'];
        formArr.clear();
        let data: any;
        if (email?.recruiter_email_id) {
          data = email.recruiter_email_id.split(',');
        } else {
          data = this.queryData.recruiter_email_id.split(',');
        }
        data.forEach((res: any) => {
          formArr = this.addQuery.controls['recruiter_email_id'] as FormArray;
          const emailRect = this.fb.control('', [
            Validators.required,
            Validators.pattern(Config.emailPolicy),
          ]);
          formArr.controls.push(emailRect);
        });
        data.forEach((res: any, i: number) => {
          formArr.controls.forEach((emailres: any, index: number) => {
            if (i === index) {
              emailres.setValue(res);
            }
          });
        });
        this.addQuery.controls['recruiter_email_id'].updateValueAndValidity();
      }, 100);
      return formArr;
    } else {
      return this.fb.control('', [
        Validators.required,
        Validators.pattern(Config.emailPolicy),
      ]);
    }
  }
  // newCustomForm(cusField: QueryEmailRecurit | any) {
  //   return this.fb.group<QueryFormField>({
  //     recruiter_email_id: this.fb.control(cusField?.name),
  //   })
  // }
  customField(): FormArray {
    return this.addQuery.get('recruiter_email_id') as FormArray;
  }
  addFormArrayData() {
    const control = new FormControl('', [
      Validators.required,
      Validators.pattern(Config.emailPolicy),
    ]);
    (<FormArray>this.addQuery.get('recruiter_email_id')).push(control);
    this.addQuery.controls['recruiter_email_id'].updateValueAndValidity();
  }

  removeFormArrayData(index: any) {
    this.customField().removeAt(index);
  }
  attachmentsFileUpload(event: any) {
    this.addQuery.get('attachments')?.setValue(event);
  }

  querySubmit() {
    if (this.isEdit) {
      this.updateSubmitQuery();
    } else {
      this.addSubmitQuery();
    }
  }

  addSubmitQuery() {
    this.showLoaderTrigger(true);
    const params = this.addQuery.value;
    let tree: any;
    if (this.interventionDetails?.call_status?.id) {
      tree = this.interventionDetails?.call_status?.id;
    } else if (this.queryData?.node_name?.id) {
      tree = this.queryData?.node_name?.id;
    } else {
      tree = this.selectedTreeID;
    }
    const empId = this.empID;
    params.recruiter_email_id = params.recruiter_email_id.map(
      (eachEmail: string) => {
        eachEmail = eachEmail ? eachEmail.toLowerCase() : '';
        return eachEmail;
      }
    );
    const queryInfoFD = new FormData();
    if (params.attachments?.length && typeof params.attachments === 'object') {
      params.attachments.forEach((eachOtherFile: File) => {
        queryInfoFD.append('attachments', eachOtherFile);
      });
    }
    queryInfoFD.append('title', params.title);
    queryInfoFD.append('recommendation', params.recommendation);
    queryInfoFD.append('description', params.description);
    queryInfoFD.append('employee', empId);
    queryInfoFD.append('recruiter_email_id', params.recruiter_email_id);
    this.campaignCEService.addCEQuery(queryInfoFD).subscribe(
      (res: any) => {
        this.showLoaderTrigger(false);
        this.addQuery.reset();
        this.showToaster('success', '', 'Query created successfully');
        this.router.navigate(['/services/candidate-engagement/view_query'], {
          queryParams: {
            campaignID: this.campaignID,
            empID: this.empID,
            isFromGlobalSearch: this.isFromGlobalSearch,
          },
        });
        this.questionsModelState(false);
      },
      (err: any) => {
        this.showLoaderTrigger(false);
        this.showToaster('error', '', err?.error?.message);
      }
    );
  }

  setRemovedFileID(event: any) {
    let ctrlValue: Array<number> = [];
    if (event?.file) {
      if (event?.file?.id) {
        ctrlValue = this.addQuery.value?.remove_files?.length
          ? [...this.addQuery.value.remove_files, event?.file?.id]
          : [event?.file?.id];
      }
    } else {
      ctrlValue = this.queryData.attachments.map(
        (eachFile: any) => eachFile.id
      );
    }
    this.addQuery.controls['remove_files'].setValue(ctrlValue);
  }

  updateSubmitQuery() {
    this.showLoaderTrigger(true);
    const params = this.addQuery.value;
    const tree = this.selectedTreeID
      ? this.selectedTreeID
      : this.interventionDetails.call_status.id;
    const queryInfoFD = new FormData();
    if (params.attachments?.length && typeof params.attachments === 'object') {
      params.attachments.forEach((eachOtherFile: File) => {
        queryInfoFD.append('attachments', eachOtherFile);
      });
    }
    const email = this.addQuery.controls['recruiter_email_id'] as FormArray;
    const emailData: any = [];
    email.controls.map((eachemail: any) => {
      emailData.push(eachemail.value);
    });
    // params.recruiter_email_id = this.addQuery.controls['recruiter_email_id'].map((eachEmail: any) => {
    //   eachEmail = eachEmail ? eachEmail.toLowerCase() : ''
    //   return eachEmail
    // });
    queryInfoFD.append('remove_files', JSON.stringify(params.remove_files));
    queryInfoFD.append('title', tree);
    queryInfoFD.append('recommendation', params.recommendation);
    queryInfoFD.append(
      'resolution',
      params.resolution ? params.resolution : ''
    );
    queryInfoFD.append('notes', params.notes ? params.notes : '');
    queryInfoFD.append('status', params.status);
    queryInfoFD.append('description', params.description);
    queryInfoFD.append('employee', this.queryData?.employee);
    queryInfoFD.append('recruiter_email_id', emailData);
    // this.store.dispatch(CEActions.ceQueryInfo({payload:queryInfoFD }))
    this.campaignCEService
      .updateCEQuery(this.queryData?.id, queryInfoFD)
      .subscribe(
        (res: any) => {
          this.showLoaderTrigger(false);
          this.addQuery.reset();
          this.showToaster('success', '', 'Query updated successfully');
          if (this.path === 'query') {
            this.router.navigate([`/queries/queries_list`]);
          } else {
            this.router.navigate(
              [`/services/candidate-engagement/detail_query`],
              {
                queryParams: {
                  campaignID: this.campaignID,
                  empID: this.empID,
                  isFromGlobalSearch: this.isFromGlobalSearch,
                },
              }
            );
          }
          this.questionsModelState(false);
        },
        (err: any) => {
          this.showLoaderTrigger(false);
          this.showToaster('error', '', err?.error?.message);
        }
      );
  }

  navigateFromBreadcrumbBtn() {
    if (this.isEdit) {
      this.router.navigate([`/services/candidate-engagement/detail_query`], {
        queryParams: {
          campaignID: this.campaignID,
          empID: this.empID,
          isFromGlobalSearch: this.isFromGlobalSearch,
        },
      });
    } else {
      if (this.interventionID) {
        this.router.navigate([`/services/candidate-engagement/interventions`], {
          queryParams: {
            campaignID: this.campaignID,
            empID: this.empID,
            isFromGlobalSearch: this.isFromGlobalSearch,
          },
        });
      } else {
        this.router.navigate([`/services/candidate-engagement/view_query`], {
          queryParams: {
            campaignID: this.campaignID,
            empID: this.empID,
            isFromGlobalSearch: this.isFromGlobalSearch,
          },
        });
      }
    }
  }
  showToaster(type: string, heading: string, message: string) {
    this.store.dispatch(
      AppActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: true,
      })
    );
  }
  showLoaderTrigger(isShow: boolean) {
    this.store.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
