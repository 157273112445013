<span [ngClass]="{'p-float-label' : isShowFloatLabel}">
    <ng-container *ngIf="disabled">
        <textarea class="form-control-text-area" [ngClass]="classesToAdd" readonly [id]="id || placeholder"
            [rows]="rows" [cols]="30" [ngModel]="value" (ngModelChange)="updateValue($event)"
            (blur)="onTouch();txtFocusOut.emit()" type="string" pInputTextarea [autoResize]="autoResize"
            (keydown)="handleEmptySpace($event); preventHTMLTagSymbol($event);"
            [placeholder]="isShowFloatLabel ? '' : placeholder"></textarea>
    </ng-container>
    <ng-container *ngIf="!disabled">
        <textarea *ngIf="!htmlTag" class="form-control-text-area" [ngClass]="classesToAdd" [id]="id || placeholder" [rows]="rows"
            [cols]="30" [ngModel]="value" (ngModelChange)="updateValue($event)" (blur)="onTouch();txtFocusOut.emit()"
            type="string" pInputTextarea [autoResize]="autoResize"
            (keydown)="handleEmptySpace($event); preventHTMLTagSymbol($event);"
            [placeholder]="isShowFloatLabel ? '' : placeholder"></textarea>
        <textarea *ngIf="htmlTag" class="form-control-text-area" [ngClass]="classesToAdd" [id]="id || placeholder" [rows]="rows"
            [cols]="30" [ngModel]="value" (ngModelChange)="updateValue($event)" (blur)="onTouch();txtFocusOut.emit()"
            type="string" pInputTextarea [autoResize]="autoResize"
            (keydown)="handleEmptySpace($event);"
            [placeholder]="isShowFloatLabel ? '' : placeholder"></textarea>
    </ng-container>
    <label *ngIf="isShowFloatLabel" [for]="id || placeholder">{{placeholder}}</label>
</span>