<span [ngClass]="{'p-float-label' : isShowFloatLabel}">
  <ng-container *ngIf="!lazy">
    <ng-container *ngIf="isMultiSelect">
      <p-multiSelect [id]="id || placeHolder" [styleClass]="'form-control-select ' + classesToAdd" [appendTo]="appendTo"
        [options]="options" [name]="name" [(ngModel)]="value" [defaultLabel]="placeHolder" [optionValue]="optionValue"
        [optionLabel]="optionLabel" optionDisabled="disabled" [disabled]="disabled" [filterBy]="filterBy"
        [resetFilterOnHide]="true"
        (ngModelChange)="onValUpdate($event);updateValue($event);" [showClear]="true" #multiselect>
        <ng-template pTemplate="header">
          <div for="select_all_multi_select_box" class="select__all--label">All</div>
        </ng-template>
      </p-multiSelect>
    </ng-container>

    <ng-container *ngIf="!isMultiSelect">
      <p-dropdown [id]="id || placeHolder" [styleClass]="'form-control-select ' + classesToAdd" [appendTo]="appendTo"
        [options]="options" [name]="name" [(ngModel)]="value" [optionValue]="optionValue" [optionLabel]="optionLabel"
        [showClear]="isShowClear" [placeholder]="editable ? '' : placeHolder" [disabled]="disabled"
        optionDisabled="disabled" [filter]="filter" [filterBy]="filterBy" [maxlength]="20" [editable]="editable"
        [resetFilterOnHide]="true"
        (onChange)="onValUpdate($event.value);updateValue($event.value);" (keydown)="handleEmptySpace($event);">
      </p-dropdown>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="lazy">
    <ng-container *ngIf="isMultiSelect">
      <p-multiSelect [id]="id || placeHolder" [styleClass]="'form-control-select ' + classesToAdd" [appendTo]="appendTo"
        [options]="options" [name]="name" [(ngModel)]="value" [defaultLabel]="placeHolder" [optionValue]="optionValue"
        [optionLabel]="optionLabel"  (onLazyLoad)="onLazyLoad($event)" [virtualScroll]="virtualScroll" [virtualScrollItemSize]="45" [lazy]="true" optionDisabled="disabled" [disabled]="disabled" [filterBy]="filterBy"
        (ngModelChange)="onValUpdate($event);updateValue($event);" [showClear]="true" #multiselect (onFilter)="onLazyFilter($event);">
        <ng-template pTemplate="loader" let-options="options">
          <div class="flex align-items-center py-2 px-3" style="height: 38px">
          </div>
        </ng-template>
        <ng-template pTemplate="header" >
          <div for="select_all_multi_select_box" class="select__all--label">All</div>
        </ng-template>
      </p-multiSelect>
    </ng-container>

    <ng-container *ngIf="!isMultiSelect">
      <p-dropdown [id]="id || placeHolder" [styleClass]="'form-control-select ' + classesToAdd"
        [optionValue]="optionValue" [optionLabel]="optionLabel" [name]="name" [appendTo]="appendTo"
        (onLazyLoad)="onLazyLoad($event)" [options]="options" [(ngModel)]="value" placeholder="Select Item"
        [virtualScroll]="virtualScroll" [virtualScrollItemSize]="45" [lazy]="true" [showClear]="isShowClear"
        [placeholder]="editable ? '' : placeHolder" [disabled]="disabled" optionDisabled="disabled" [filter]="filter"
        [filterBy]="filterBy" [maxlength]="20" [editable]="editable"
        (onChange)="onValUpdate($event.value);updateValue($event.value);" (keydown)="handleEmptySpace($event);"
        (onFilter)="onLazyFilter($event);">
        <ng-template pTemplate="loader" let-options="options">
          <div class="flex align-items-center py-2 px-3" style="height: 38px">
          </div>
        </ng-template>
      </p-dropdown>
    </ng-container>
  </ng-container>
  <label *ngIf="isShowFloatLabel" [for]="id || placeHolder">{{placeHolder}}</label>
</span>