import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '@acengage/app/app/core/services';
import { AppState } from '@acengage/app/app/state/app.state';
import { LoginState } from '@acengage/app/app/authentication/state/auth.reducer';
import * as AuthActions from '@acengage/app/app/authentication/state/auth.actions';
import * as appActions from '@acengage/app/app/state/app.actions';


@Component({
  selector: 'acengage-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  newPassword = '';
  confirmNewPassword = '';
  displayToast = false;
  localStoreValues: any;
  showLoader = false;
  storeSubscribed$:Subscription;
  @Output() QrText: any = new EventEmitter();
  @Output() nextStep: any = new EventEmitter();
  toasterInfoObj = {
    severity: 'success',
    summary: '',
    detail: '',
  };
  loginStoreValue$: Observable<any>;
  qrCode$: Observable<any>;
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private store: Store<LoginState>,
    private appStore: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.storeSubscribed$=this.store.subscribe((state: any) => {
      this.localStoreValues = { appStates: state['login'] };
      if(this.localStoreValues.appStates.id=='' || !this.localStoreValues.appStates.id || this.localStoreValues.appStates.id==undefined ){
        this.router.navigateByUrl('/auth/login')
      }
      if((this.localStoreValues.appStates.hasOwnProperty('qr_text') && this.localStoreValues.appStates?.qr_text?.length>0)){
        this.router.navigateByUrl('/auth/scan-qr')
      }
    })
  }

  setNewpassword() {
    this.displayToast = false;
    if(!this.checkForStrongPassword()){
      return
    }
    if (this.newPassword != this.confirmNewPassword) {
      this.appStore.dispatch(appActions.showToaster({
        class: 'error',
        header: 'Password Mismatch',
        message: '',
        'showToaster': true
      }));
      return;
    }
    let body = {
      user_id: this.localStoreValues.appStates.id as any,
      password: this.confirmNewPassword as any,
    };
    this.showLoader = true;
    this.appStore.dispatch(appActions.showLoader({ showLoader: true }));
    this.auth.resetPassword(body).subscribe(
      (res: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        if (res['code'] == 200) {
          this.toasterInfoObj = {
            severity: 'success',
            summary: 'Success',
            detail: '',
          };
          this.displayToast = true;
          let toStore = {
            id: this.localStoreValues.appStates.id,
            default_password_changed: this.localStoreValues.appStates.default_password_changed,
            first_login: this.localStoreValues.appStates.first_login,
            is_2fa: this.localStoreValues.appStates.is_2fa,
            token: this.localStoreValues.appStates.token,
            qr_text: res.qr_text,
            isLoading: false
          };
          this.store.dispatch(AuthActions.updateLoginStateAndRedirect(toStore));

          let loginDtls: any = localStorage.getItem('login');
          loginDtls = JSON.parse(loginDtls); 
          if (loginDtls?.roles[0]?.id != 11) {
            if(!toStore.is_2fa){
              this.store.dispatch(AuthActions.sideMenuBar({ payload: toStore }));
            }
          }

          
        }
      },
      (err) => {
        // this.showLoader = false;
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        if (err['error']?.message) {
          this.appStore.dispatch(appActions.showToaster({
            class: 'error',
            header: err['error']?.message,
            message: '',
            'showToaster': true
          }));
        } else {
          this.appStore.dispatch(appActions.showToaster({
            class: 'error',
            header: 'Server error',
            message: '',
            'showToaster': true
          }));
        }

        this.displayToast = true;
      }
    );
  }
  checkForStrongPassword(){
    if(this.newPassword.search(/[a-z]/) < 0) {
      this.appStore.dispatch(appActions.showToaster({
        class: 'error',
        header: 'Password should contain at least one lowercase',
        message: '',
        'showToaster': true
      }));
      return false

      } else if(this.newPassword.search(/[A-Z]/) < 0) {
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should contain at least one uppercase',
          message: '',
          'showToaster': true
        }));
        return false

      } else if(this.newPassword.search(/[0-9]/) < 0) {
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should contain at least one number',
          message: '',
          'showToaster': true
        }));
        return false

      } else if(this.newPassword.search(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)<0){
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should contain at least one special character',
          message: '',
          'showToaster': true
        }));
        return false
      }
      else if(this.newPassword.length < 8) {
        this.appStore.dispatch(appActions.showToaster({
          class: 'error',
          header: 'Password should have minimum 8 characters',
          message: '',
          'showToaster': true
        }));
       return false

      } else {

        return true;

      }
  }
  isValidPassword() {
    return (this.newPassword.trim() !== '' && this.confirmNewPassword.trim() !== '') ? false : true
  }
  ngOnDestroy() {
    this.storeSubscribed$.unsubscribe()
  }
}
