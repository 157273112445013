<form [formGroup]="loginForm" (keydown)="checkForEnter($event)">
    <h2 class="textCenter">Login to your account</h2>

    <div class="width100">
        <div class="marginBottom10px">
            <label>Username</label>
        </div>
        <div class="marginBottom10px">
            <acengage-text-input [isShowFloatLabel]="false" [placeholder]="'Enter your username'" (keydown.space)="$event.preventDefault()" formControlName="username"
            [maxLength]="'50'"></acengage-text-input>
        </div>
        <div class="marginBottom10px">
            <acengage-form-error [control]="loginForm.controls.username"  [group]="loginForm"  validatorName="required">
                Username is required.
            </acengage-form-error>
            <acengage-form-error  [control]="loginForm.controls.username" [group]="loginForm" validatorName="MinMax">
             Maximum limit is 50.
            </acengage-form-error>
        </div>
    </div>
    <div class="width100">
        <div class="marginBottom10px">
            <label>Password</label>
        </div>
        <div class="marginBottom10px">
            <acengage-password-input [placeholder]="placeHolderForPassword()" (keydown.space)="$event.preventDefault()" [showPasswordStrength]="false" [maxLength]="'50'" formControlName="password">
            </acengage-password-input>
        </div>
        <div class="marginBottom10px">
            <acengage-form-error [control]="loginForm.controls.password"  [group]="loginForm"  validatorName="required">
                Password is required.
            </acengage-form-error>
            <acengage-form-error [control]="loginForm.controls.password"  [group]="loginForm"  validatorName="MinMax">
                Maximum limit is 50.
            </acengage-form-error>
        </div>
    </div>
    <div *ngIf="showGoogleCode" class="forgetPasword marginBottom10px">
        <span class="cursor-pointer forgetPasword color-white" routerLink="/auth/forget-password">Forgot password?</span>
    </div>
    <div class="width100" *ngIf="showGoogleCode">
        <div class="marginBottom10px">
            <label>Google authenticator OTP</label>
        </div>
        <div class="marginBottom10px">
            <acengage-text-input [isShowFloatLabel]="false" formControlName="otp"
            [maxLength]="'6'"
            [placeholder]="'Enter 6 digit code'"
            (keypress)="_vldService.allowOnly('Number', $event); "
            (paste)="_vldService.allowOnly('Number', $event)"></acengage-text-input>
        </div>
        <div class="marginBottom10px">
            <acengage-form-error [control]="loginForm.controls.otp"  [group]="loginForm"  validatorName="required">
                2FA code is required.
            </acengage-form-error>
            <acengage-form-error [control]="loginForm.controls.otp"  [group]="loginForm"  validatorName="MinMax">
                Maximum limit is 7.
            </acengage-form-error>
        </div>

    </div>
    <div class="marginBottom10px marginTop2rem">

        <acengage-button (buttonClicked)="login()" [isBtnDisable]="!loginForm.valid" buttonType="submit"
            [buttonText]="getLoginBtnText()"></acengage-button>
    </div>
</form>
<!-- <acengage-toaster
  toasterPosition="top-right"
  [toasterInfos]="toasterInfoObj"
  [displaytoaster]="displayToast"
>
</acengage-toaster>

<acengage-loader [showLoader]="showLoader"></acengage-loader> -->
