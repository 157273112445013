<ul class="tree__reason--ul" [ngClass]="{'remove__padding': isPrimaryReason}">
    <li class="tree__reason--each">
        <div class="tree__reason--each--row"
            [ngClass]="{'remove__bottom__space': (isPrimaryReason && ((parentLeafNode.mapped_to && processType === 'view') || processType !== 'view'))}">
            <hr class="tree__reason--divider">
            <div class="tree__reason--fields--container">
                <div class="tree__reason--input--container">
                    <acengage-text-input
                        [name]="parentLeafNode.choice + '_' + parentLeafNode.drill_down + '_' + leafIndex + getRandKey()"
                        [maxLength]="null" [(ngModel)]="parentLeafNode.choice" [disabled]="processType === 'view'"
                        #choice="ngModel" [placeholder]="isPrimaryReason ? 'Primary Reason *' : 'Branch name *'"
                        [required]="true">
                    </acengage-text-input>
                    <div *ngIf="choice.invalid && (choice.touched || choice.dirty)" class="form-error-message">
                        {{isPrimaryReason ? 'Primary reason' : 'Sub reason'}} is required
                    </div>
                </div>
                <div class="tree__reason--each--switch">
                    <div class="tree__reason--each--switch--label">Red Flag</div>
                    <acengage-toggle-switch [classesToAdd]="'custom_toggle_switch'" [disabled]="processType === 'view'"
                        [name]="parentLeafNode.choice + '_red_flag_' + parentLeafNode.drill_down + '_' + leafIndex + getRandKey()"
                        [(ngModel)]="parentLeafNode.is_red_flagged">
                    </acengage-toggle-switch>
                </div>
                <div class="tree__reason--each--switch">
                    <div class="tree__reason--each--switch--label">Hide</div>
                    <acengage-toggle-switch [classesToAdd]="'custom_toggle_switch'" [disabled]="processType === 'view'"
                        [name]="parentLeafNode.choice + '_hide_' + parentLeafNode.drill_down  + '_' + leafIndex + getRandKey()"
                        [(ngModel)]="parentLeafNode.is_hidden">
                    </acengage-toggle-switch>
                </div>
                <div class="tree__reason--each--switch" *ngIf="(isPrimaryReason && serviceId === 3) || (serviceId !== 3)">
                    <div class="tree__reason--each--switch--label">Controllable</div>
                    <acengage-toggle-switch [classesToAdd]="'custom_toggle_switch'" [disabled]="processType === 'view'"
                        [name]="parentLeafNode.choice + '_controllable_' + parentLeafNode.drill_down + '_' + leafIndex + getRandKey()"
                        [(ngModel)]="parentLeafNode.is_controllable">
                    </acengage-toggle-switch>
                </div>
                <ng-container *ngIf="processType !== 'view'">
                    <acengage-button class="tree__reason--button" [isBtnDisable]="parentLeafNode.drill_down === 8"
                        [buttonText]="isPrimaryReason ? 'Create branches' : 'Sub reason'" [iconDtls]="addIcon"
                        (buttonClicked)="addLeafNodes();">
                    </acengage-button>
                    <acengage-button class="tree__reason--button"
                        *ngIf="isShowServices || (!isShowServices && treeForm.options.length > 1)"
                        [buttonClassesToAdd]="'remove-btn'" buttonText="Remove" [iconDtls]="removeIcon"
                        (buttonClicked)="removeNodeClicked.emit(leafIndex);"></acengage-button>
                </ng-container>
            </div>
        </div>
        <div class="tree__reason--mapped--field--selected"
            *ngIf="isPrimaryReason && ((parentLeafNode.mapped_to && processType === 'view') || processType !== 'view')">
            Mapped to
            <acengage-select [classesToAdd]="'value_only'" [disabled]="processType === 'view'"
                [name]="parentLeafNode.choice + '_mapped_' + parentLeafNode.drill_down + '_' + leafIndex + getRandKey()"
                [(ngModel)]="parentLeafNode.mapped_to" [options]="mappingFields" [isMultiSelect]="false"
                [placeHolder]="'Select mapping field'" [isShowFloatLabel]="false">
            </acengage-select>
        </div>
                  <!-- follow up questions -->
                  <div class="follow--questions" *ngIf="processType != 'view'">
                    <div class="add__question--link add" (click)="followUp=true">
                        <i class="pi pi-plus"></i>
                        <span>Add Follow up Question</span>
                    </div>
                    <div class="follow--questions__section" *ngIf="followUp || parentLeafNode.followup_question">
                        <div class="follow--questions__head">
                            <p class="follow-p">Follow up question</p>
                            <div>
                                <div class="add__question--link remove" (click)="followUp=false;parentLeafNode.followup_question=''">
                                    <i class="pi pi-trash"></i>
                                    <span>Remove</span>
                                </div>
                            </div>
                        </div>

                        <acengage-text-input
                        [name]="parentLeafNode.followup_question + '_' + parentLeafNode.drill_down + '_' + leafIndex + getRandKey()"
                        [maxLength]="null" [(ngModel)]="parentLeafNode.followup_question"
                        #followup_question="ngModel" [placeholder]="'Enter the question here'">
                    </acengage-text-input>

                        <!-- <acengage-text-input [inputType]="'text'" [maxLength]="null" [placeholder]="'Enter the question here'" [(ngModel)]="parentLeafNode.followup_question"></acengage-text-input> -->
                    </div>
                </div>
                <div class="follow--questions" *ngIf="processType === 'view' && parentLeafNode.followup_question">
                    <acengage-text-input [disabled]="true"
                    [name]="parentLeafNode.followup_question + '_' + parentLeafNode.drill_down + '_' + leafIndex + getRandKey()"
                    [maxLength]="null" [(ngModel)]="parentLeafNode.followup_question"
                    #followup_question="ngModel">
                </acengage-text-input></div>

        <ng-container *ngFor="let eachLeafNode of parentLeafNode.leaf_nodes; let i = index;">
            <acengage-tree-reason-fields [parentLeafNode]="eachLeafNode" [serviceId]="serviceId" [leafIndex]="i" [processType]="processType"
                (removeNodeClicked)="removeNode($event);">
            </acengage-tree-reason-fields>
        </ng-container>
       
    </li>
</ul>