import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '@acengage/config/*';
import { Store } from '@ngrx/store';
import * as AuthActions from '@acengage/app/app/authentication/state/auth.actions';

@Component({
  selector: 'acengage-forgot-password-step-one',
  templateUrl: './forgot-password-step-one.component.html',
  styleUrls: ['./forgot-password-step-one.component.scss'],
})
export class ForgotPasswordStepOneComponent implements OnInit {
  constructor(private router: Router, private fb: FormBuilder, private store1: Store,) { }
  forgotForm: FormGroup;
  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.forgotForm = this.fb.group({
      username: this.fb.control('', [Validators.required])
    })
  }
  submitUsername() {
    localStorage.removeItem('minutes')
    const payload = { ...this.forgotForm.value };
    payload.username = payload?.username?.toLowerCase() || '';
    this.store1.dispatch(AuthActions.ForgotPasswordReq({ payload: payload }))
  }
  checkForEnter(event: any) {
    if (event.keyCode === 13) {
      this.submitUsername();
    }
  }
}
