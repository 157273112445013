export interface CommonAPIRequest {
  page: number;
  limit: number;
  search?: string;
  sort_order: string;
  customFilter?: string;
  mode?: string;
  current_status?: number;
}

export interface bulkDiscardPayload {
  campaign: number
}

export interface dummy {
  header?: string | undefined;
  field_type: string;
  contract_id: number | string;
  allow_edit: boolean;
  red_flag: boolean;
  mapping_field: any;
  format: string;
  male: string;
  female: string;
  others: string;
  true: string;
  false: string;
  mobile: string;
  voluntary: string;
  voluntaryValid: boolean;
  involuntary: string;
  involuntaryValid: boolean;
  headerValid: boolean;
  mapping_fieldValid: boolean;
  mappingTypeValid: boolean;
  maleValid: boolean;
  femaleValid: boolean;
  othersValid: boolean;
  trueValid: boolean;
  falseValid: boolean;
}

export interface fieldMappingData {
  label?: string;
  type?: string;
  contract_id?: number | string;
  allow_edit?: boolean;
  red_flag?: boolean;
  mapping_field?: any;
  format?: string;
  male?: string;
  female?: string;
  others?: string;
  true?: string;
  false?: string;
  mobile?: string;
  voluntary?: string;
  involuntary?: string;
  id?: number | undefined;
  newrow?: boolean;
  field_type?: string;
  is_mandatory?: boolean;
  field_typeValid?: boolean;
  mappingTypeValid?: boolean;
  involuntaryValid?: boolean;
  voluntaryValid?: boolean;
  mapping_fieldValid?: boolean;
  headerValid?: boolean;
  csv_mapping_field?: string;
  header?: string | undefined;
  mapFieldId?: number;
  data?: any
  format_field?: string;
  maleValid?: boolean;
  femaleValid?: boolean;
  othersValid?: boolean;
  trueValid?: boolean;
  falseValid?: boolean;
  intervention?: number | null;
  hint?: string | null;
  is_hint?: boolean | null;
  expected_values?: string[] | null;
  hintTooltipContent?: string | null;
}

export interface addVerifyFields {
  toSave: [fieldMappingData],
  tempArray: [fieldMappingData]
}

// export interface assignedUserResponse{
//     data:{
//         results:[]
//     }
// }