import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddClientAdminForm } from '../models/interfaces/add-client-admin.interface';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { Config } from '../../config';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services';
import { CommonButtonInput } from '@acengage/ui/models/button.model';

@Component({
  selector: 'acengage-add-client-admin',
  templateUrl: './add-client-admin.component.html',
  styleUrls: ['./add-client-admin.component.scss'],
})
export class AddClientAdminComponent implements OnInit {
  addClientAdminForm: FormGroup<AddClientAdminForm>;
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  isLoggedIn = false;
  msgPopupButtonDtls: CommonButtonInput = {
    buttonText: 'back to website',
    buttonClassesToAdd: 'secondary-btn',
    iconDetails: { position: 'right', name: 'pi pi-arrow-right' },
  };
  showSuccessMsgPopup = false;
  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    let loginDtls: any = localStorage.getItem('login');
    if (loginDtls) {
      loginDtls = JSON.parse(loginDtls);
      this.isLoggedIn = loginDtls?.token ? true : false;
    }
    this.createAddClientAdminForm();
  }

  createAddClientAdminForm(): void {
    this.addClientAdminForm = this.fb.group<AddClientAdminForm>({
      organisation_name: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      spoc_name: this.fb.control('', Validators.required),
      spoc_mobile_number: this.fb.control('', Validators.required),
      spoc_email: this.fb.control('', [
        Validators.required,
        Validators.pattern(Config.emailPolicy),
      ]),
      service_type: this.fb.control('', Validators.required),
    });
  }

  navigateToProfileCreation() {
    this.router.navigate(['/client-admin/profile-creation']);
  }
}
