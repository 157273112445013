import { Basicinfo } from '../../accounts/models/add-account.interface';
import { basicDetails } from '../../services-screens/state/services.reducer';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { AppState } from '@acengage/app/app/state/app.state';
import { TableHeader } from '@acengage/ui/models/table.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CommonService } from '@acengage/app/app/services/common.service';
import { CommonAPIRequest } from '../../models/common-api-request.model';
import { MenuItem } from 'primeng/api';
import { PermissionList } from '../../state/app.model';
import { NHCampaignService } from '../../services-screens/services/new-hire-campaign.service';
import * as appActions from '@acengage/app/app/state/app.actions';
import { CommonButtonInput } from '@acengage/ui/models/button.model';
import { TableCustomBtnClick } from '../../models/table.model';

interface TimelineItem {
  date: string;
  title: string;
  content: any;
  expanded: boolean;
  isShowMore: any;
}
@Component({
  selector: 'acengage-buddy-induction',
  templateUrl: './buddy-induction.component.html',
  styleUrls: ['./buddy-induction.component.scss'],
})
export class BuddyInductionComponent implements OnInit {
  // Added
  apiReqDtls: CommonAPIRequest = {
    page: 1,
    limit: 100,
    search: '',
    sort_order: '',
  };
  inputData: any;
  showConfirmationDialog = false;
  buddyInductionList: any = [];
  actionItems: MenuItem[] = [];
  permissionRole: PermissionList = {
    feature_name: '',
    uid: '',
    role: '',
    add: false,
    edit: false,
    delete: false,
    view: false,
    export: false,
    nc_weekly_update: false,
  };
  file: any;
  @Input() permissionDetails: any;
  totalCount=1
  loading = false;
  resetRecords = false;
  UploadefilterDetails: any = {};
  @Input() empID: string | number;
  @Input() campaignID: number;
  @Output() continuityViewClicked = new EventEmitter<number>();
  mappingFields: any; 
  isShowMore: any = []; 
  confimationMessage = 'Do you want to update the value?';
  uploadDialog = false
  sendScheduleDisabled = true; 
  headers: TableHeader[] = [
    {
      headerName: 'Name',
      objectKeyName: 'name',
      isSortable: true,
      isShow: true,
    },
    {
      headerName: 'Poornata ID',
      objectKeyName: 'poornata_id',
      isSortable: true,
      isShow: true,
    },
    {
      headerName: 'Position Title',
      objectKeyName: 'position_title',
      isSortable: false,
      isShow: true
    },
    {
      headerName: 'Function',
      objectKeyName: 'function',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Email id',
      objectKeyName: 'email',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Mobile Number',
      objectKeyName: 'phone',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Schedule Time',
      objectKeyName: 'schedule_time',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Status',
      objectKeyName: 'buddy_induction_status',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Action',
      objectKeyName: 'buddy_action',
      isSortable: false,
      isShow: true,
    }
  ];
  tableCustomBtnDtls: CommonButtonInput[] = [];
  constructor(
    private campaignService: NHCampaignService,
    private appStore: Store<AppState>,
    private router: Router,
    private commonService: CommonService,
    private store: Store
  ) {}
    

  ngOnInit(): void {
    this.actionItems = [
      { label: 'Edit' },
      // { label: 'Cancel debit' }
    ];
    this.getRecordDtls()
  }

  getRecordDtls() {
    this.campaignService.getBuddyInductionDetails(this.empID, this.apiReqDtls).subscribe(
      (response: any) => {
        this.buddyInductionList = response.data
        // const allStatusesAreThree = this.buddyInductionList.every(
        //   (item: any) => item.status == 3
        // );
        this.tableCustomBtnDtls = [
          {
            buttonText: 'Upload',
            isCheckSelectedRow: false,
            buttonClassesToAdd: 'p-button-outlined p-button-secondary',
            enableAlways: true
          },
          {
            buttonText: 'Send Schedule',
            isCheckSelectedRow: false,
            buttonClassesToAdd: 'p-button-outlined p-button-secondary',
            // disabled: !allStatusesAreThree
          },
          {
            buttonText: 'Export',
            isCheckSelectedRow: false,
            buttonClassesToAdd: 'p-button-outlined p-button-secondary'
          }
        ];
      })
  }
  delete_record_id: any
  showDeleteConfirmationDialog = false
  confirmDeleteRecord(id: any) {
    this.delete_record_id = id
    this.showDeleteConfirmationDialog = true
  }

  deleteRecord() {
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    this.campaignService.deleteBuddyInductionIndividualValue(this.delete_record_id).subscribe(
      (res: any) => {
        if (res.code == 200) {
          this.showDeleteConfirmationDialog = false
          this.getRecordDtls();
          this.store.dispatch(appActions.showLoader({ showLoader: false }));
          this.store.dispatch(
            appActions.showToaster({
              class: 'success',
              message: 'Data deleted successfully',
              showToaster: true,
              header: '',
            })
          );
        }
      },
      (err) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.getRecordDtls();
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            message: err['error']?.message ? err['error']?.message : 'Error',
            showToaster: true,
            header: '',
          })
        );
      }
    );
  }

  // Added
  getNextpageRecords({ page, rows }: any) {
    this.apiReqDtls = { ...this.apiReqDtls, page: page || 1, limit: rows };
    // this.getRecordDtls(this.apiReqDtls, this.filterDetails, false);
  }
  sortColumn(sortedValue: string) {
    this.apiReqDtls = { ...this.apiReqDtls, page: 1, sort_order: sortedValue };
    this.getRecordDtls();
  }

  customBtnClicked(event: TableCustomBtnClick<any>) {
    if (event?.buttonText?.toLowerCase() === 'upload') {
      this.uploadDialog = true
      // this.allColorCodes
    } else if (event?.buttonText?.toLowerCase() === 'send schedule') {
      this.sentSchedule()
    } else if (event?.buttonText?.toLowerCase() === 'export') {
      this.exportBuddyInduction()
    }
  }

  fileUploads(_data: any) {
    const formData = new FormData();
    if(_data[0] != undefined) {
      formData.set('file', _data[0]);
      this.file = formData;
    } else {
      this.file = ''
    }
  }

  downloadTemplate() {

  }


  filterUsers(filteredData: any) {
    this.apiReqDtls = {
      ...this.apiReqDtls,
      sort_order: '',
      search: filteredData.search,
    };
    this.getRecordDtls();
  }

  setEditColsToStore(editedCols: TableHeader[]) {
    console.log(editedCols);
    // this.saveFilterandColumn('column');
  }
  
  exportBuddyInduction() {
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    let export_payload = {
        "field_names": ["name", "poornata_id", "position_title", "function", "email", "phone", "schedule_time", "status"],
        "sort_order": this.apiReqDtls.sort_order,
        "search": ""
    }
    this.campaignService
      .exportBuddyInductionTemplate(this.empID, export_payload)
      .subscribe((dwldTempResp: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        if (dwldTempResp?.data) {
          const fileName: string =
            `Buddy_induction_${this.empID}_upload_template.xlsx`;
          this.commonService.downloadFile(dwldTempResp.data, fileName);
        } else {
          this.store.dispatch(
            appActions.showToaster({
              class: 'error',
              message: 'File not exists',
              showToaster: true,
              header: '',
            })
          );
        }
      });
  }

  showPopup(data: any) {
    this.inputData = data;
    this.showConfirmationDialog = true;
  }

  updateData() {
    this.showConfirmationDialog = false;
    this.updateIndividualValues(this.inputData);
  }

  updateIndividualValues(data: any) {
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    const { candidate, value, ...to_update } = data;
    this.campaignService.updatedBuddyInductionIndividualValue(data.candidate, to_update).subscribe(
      (res: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        if (res.code == 200) {
          this.getRecordDtls();
          this.store.dispatch(
            appActions.showToaster({
              class: 'success',
              message: 'value updated successfully',
              showToaster: true,
              header: '',
            })
          );
        }
      },
      (err) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.getRecordDtls();
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            message: err['error']?.message ? err['error']?.message : 'Error',
            showToaster: true,
            header: '',
          })
        );
      }
    );
  }

  discardFunction() {
    this.showConfirmationDialog = false;
    this.uploadDialog = false
    this.showDeleteConfirmationDialog = false
    this.file = ''
    this.getRecordDtls();
  }

  showToaster(type: string, heading: string, message: string) {
    this.store.dispatch(
      AppActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: true,
      })
    );
  }

  submitClicked() {
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    this.campaignService.uploadBuddyInduction(this.file, this.empID).subscribe(
      (res: any) => {
        this.getRecordDtls();
        this.uploadDialog=false
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.showToaster('success', '', res?.message);
      },
      (err: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.showToaster('error', '', err?.error?.message);
      }
    );
  }

  sentSchedule() {
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    this.campaignService.sentScheduleBuddyInduction(this.empID).subscribe(
      (res: any) => {
        if (res.code == 200) {
          this.getRecordDtls();
          this.store.dispatch(appActions.showLoader({ showLoader: false }));
          this.store.dispatch(
            appActions.showToaster({
              class: 'success',
              message: res.message,
              showToaster: true,
              header: '',
            })
          );
        }
      },
      (err) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.getRecordDtls();
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            message: err['error']?.message ? err['error']?.message : 'Error',
            showToaster: true,
            header: '',
          })
        );
      }
    );
  }
  // ------
}
