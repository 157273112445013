
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@acengage/app/environments/environment';
import { CommonAPIRequest } from '@acengage/app/app/models/common-api-request.model';
import { AcengageTreeDefaultPayload, AcengageTreeForm, AcengageTreeNode } from '../acengage-tree/models/add-acengage-tree.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})

export class AcengageTreeService {
    constructor(private http: HttpClient,
        private fb: FormBuilder) { }

    getAcengageTreeFilters() {
        return this.http.get(`${environment.api_url}/acengage-tree/tree-filters`);
    }

    getAllAcengageTrees(apiReqDtls: CommonAPIRequest, filterDetails: any) {
        return this.http.post(`${environment.api_url}/acengage-tree/tree/?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&sort_order=${apiReqDtls.sort_order}`, filterDetails);
    }

    getServiceList() {
        return this.http.get(`${environment.api_url}/acengage-tree/services`);
    }

    getSubServicesList(subservicePayload: { service_id: number | number[] }) {
        return this.http.post(`${environment.api_url}/acengage-tree/sub_service`, subservicePayload);
    }

    getDefaultTree(defaultTreePayload: AcengageTreeDefaultPayload) {
        return this.http.post(`${environment.api_url}/acengage-tree/default-tree`, defaultTreePayload);
    }

    getMappingFields(mappingFieldPayload: AcengageTreeDefaultPayload) {
        return this.http.post(`${environment.api_url}/acengage-tree/mapping-field`, mappingFieldPayload);
    }

    getQuesBankMappingField(mappingFieldPayload: { service_id: number[] | number | null }) {
        return this.http.post(`${environment.api_url}/acengage-tree/qb-mapping-field`, mappingFieldPayload);
    }

    getTreeDetails(treeID: number) {
        return this.http.get(`${environment.api_url}/acengage-tree/tree/${treeID}`);
    }

    addNewTree(addTreePayload: AcengageTreeForm) {
        return this.http.post(`${environment.api_url}/acengage-tree/`, addTreePayload);
    }

    editExistTree(editTreePayload: AcengageTreeForm, treeID: number) {
        return this.http.put(`${environment.api_url}/acengage-tree/tree-update/${treeID}/`, editTreePayload);
    }

    deleteTree(treeID: number) {
        return this.http.delete(`${environment.api_url}/acengage-tree/tree/${treeID}/`);
    }

    newNodeOptionFG(drillCnt: number): AcengageTreeNode {
        return {
            choice: '',
            followup_question:'',
            parent: 0,
            node_identifier: 0,
            is_controllable: false,
            is_red_flagged: false,
            is_hidden: false,
            mapped_to: null,
            leaf_nodes: [],
            drill_down: drillCnt,
            scale_end: null,
            scale_start:null
        };
    }
}