<p-toast [position]="toastrPosition" (onClose)="toasterClose();"></p-toast>
<div *ngIf="displayPopup" class="popOverlay"></div>
<p-dialog [header]="toasterDetails.header ? toasterDetails.header : 'Error'" (onHide)="closePopup()" [draggable]="false"
    [(visible)]="displayPopup" [style]="{width: '30vw'}" [baseZIndex]="10000">
    <div class="error-content">
        <p class="color-red"><i class="pi pi-times-circle" style="font-size: 2.5rem"></i></p>
        <div class="message-content">
            <pre><p>{{toasterDetails['message'] ? toasterDetails['message']:toasterDetails['header']}}</p></pre>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <!-- <p-button (click)="closePopup()" label="Ok" styleClass="primary-btn"></p-button> -->
    </ng-template>
</p-dialog>