import { Injectable } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValueAccessorService implements ControlValueAccessor {
  value: any;
  disabled: any;
  private valueChanges = new Subject<string>();
  private touches = new Subject();
  onChange = () => { };
  onTouch = () => { };
  constructor() {

  }


  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value;
  }
  updateValue(value: any) {
    this.value = value;
    this.valueChanges.next(value);
  }
  touch() {
    this.touches.next(null);
  }
}
