import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-password-toggle',
  templateUrl: './password-toggle.component.html',
  styleUrls: ['./password-toggle.component.scss'],
})
export class PasswordToggleComponent implements OnInit {
  constructor() { }
  @Input() passwordValue: string = '';
  splittedPassword: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  ngOnInit(): void { }
}
