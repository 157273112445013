import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common'

@Component({
  selector: 'acengage-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent implements OnInit {
  @Input() activeStep: number;
  @Input() stepItems: number;
  @Input() isSkip = false;
  @Input() isPrev = true;
  @Input() isBack = false;
  @Input() nextBtnDisable = false;
  @Input() prevBtnLabel = 'Prev';
  @Input() nextBtnLabel = 'Continue';
  @Input() skipBtnLabel = 'Skip';
  @Input() submitBtnLabel = 'Approve';
  @Input() submitLabel:String;
  @Output() navigateBtnClicked = new EventEmitter<string>();
  constructor(private _location: Location) { }

  ngOnInit(): void { 
  }

}
