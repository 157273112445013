import { Component, forwardRef, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarTypeView, Calendar } from 'primeng/calendar';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true,
  }]
})
export class DatePickerComponent implements ControlValueAccessor {
  constructor() { }
  @ViewChild('pcalendar') pcalendar: Calendar;
  @Input() id = '';
  @Input() placeholder = '';
  @Input() inputType = 'text';
  @Input() minDate: any = null;
  @Input() maxDate: any = null;
  @Input() isShowFloatLabel = true;
  @Input() displayDateFormat = 'dd/mm/yy';
  @Input() selectionMode = "single";
  @Input() calendarType: CalendarTypeView = 'date';
  @Input() icon = "pi pi-calendar";
  @Input() timeOnly = false;
  @Input() disabled = false;
  @Input() selectableOnly = true;
  @Input() showClear = true;
  @Input() name = "datepicker";
  @Input() hourFormat = '12';
  @Input() showSeconds = false;
  @Input() appendTo: string | null = null;
  @Output() selectedDate = new EventEmitter<any>();
  value: any;
  private valueChanges = new Subject<string>();
  private touches = new Subject();
  onChange = () => { };
  onTouch = () => { };

  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value;
  }
  updateValue(value: any) {
    this.value = value;
    if (!value) {
      this.pcalendar.updateInputfield();
    }
    this.valueChanges.next(value);

  }
  touch() {
    this.touches.next(null);
  }
}
