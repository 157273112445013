import { FormControl } from "@angular/forms";

export interface CESlotSelectionCandidateDetails {
    id: number;
    candidate_name: string;
    candidate_id: string;
    date_of_join: string;
    stakeholder_name: string | null;
    department: string;
    location: string;
    type: number;
    email: string;
    is_submitted: boolean;
}

export interface CESlotSelectionForm {
    slot_date_1: FormControl<string | null>;
    slot_date_2: FormControl<string | null>;
    slot_date_3: FormControl<string | null>;
    slot_time_1: FormControl<string | null>;
    slot_time_2: FormControl<string | null>;
    slot_time_3: FormControl<string | null>;
    link: FormControl<string | null>;
    meeting_id: FormControl<string | null>;
    passcode: FormControl<string | null>;
}
export interface SlotChangeForm {
    reason: FormControl<string | null>;
    slot_date_1?: FormControl<string | null>;
    slot_date_2?: FormControl<string | null>;
    slot_date_3?: FormControl<string | null>;
    slot_time_1?: FormControl<string | null>;
    slot_time_2?: FormControl<string | null>;
    slot_time_3?: FormControl<string | null>;
}

export interface CESlotSelectionPayload {
    slot_1: string;
    slot_2: string;
    slot_3: string;
    link: string;
    meeting_id: string;
    passcode: string;
}
export interface ChangeSlotPayload {
    slot_1: string;
    slot_2: string;
    slot_3: string;
    selected_slot?: string;
}