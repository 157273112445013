import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SelectOption } from '@acengage/app/app/models/select.model';
import { QuestionsService } from '@acengage/app/app/services/question-bank.service';
import { QuestionIntervensionsPayload, QuestionsForm, QuestionTypeServiceInterventionResponse } from '@acengage/app/app/question-bank/models/add-question-bank.model';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { AppState } from '@acengage/app/app/state/app.state';
import { Store } from '@ngrx/store';
import { AcengageTreeDefaultPayload, AcengageTreeForm } from '@acengage/app/app/acengage-tree/models/add-acengage-tree.interface';
import { AcengageTreeService } from '@acengage/app/app/services/acengage-tree.service';

@Component({
  selector: 'acengage-question-creation-service-selection',
  templateUrl: './question-creation-service-selection.component.html',
  styleUrls: ['./question-creation-service-selection.component.scss'],
})
export class QuestionCreationServiceSelectionComponent implements OnInit {
  constructor(private questionService: QuestionsService,
    private appStore: Store<AppState>,
    private treeService: AcengageTreeService,) { }
  _questionForm: FormGroup<QuestionsForm>;
  @Input() acengageTreeForm: AcengageTreeForm = {
    service: null,
    heading: null,
    subservice: null,
    options: []
  };
  @Input() set questionForm(formGroup: FormGroup<QuestionsForm>) {
    this._questionForm = formGroup;
    const { services, addToQuestionnaire } = formGroup.value;
    if (services?.length) {
      this.getQuesIntervensions(services, false);
      if (addToQuestionnaire) {
        this.getQuestionnaireList(services, false);
      }
    }
  }
  @Output() sendMappingFields = new EventEmitter<SelectOption[]>();
  servicesList: SelectOption[] = [];
  interventionsList: SelectOption[] = [];
  questionnaireList: SelectOption[] = [];
  selectboxLabel = "Select Interventions";

  ngOnInit(): void {
    this.getQuesService();
  }

  getQuesService() {
    this.showLoaderTrigger(true);
    this.questionService.getQuestionServices().subscribe((quesServicesResp: any) => {
      this.servicesList = quesServicesResp?.data?.map((eachService: QuestionTypeServiceInterventionResponse) => {
        const formSelectOption: SelectOption = {
          name: eachService.name,
          value: eachService.id
        };
        return formSelectOption;
      }) || [];
      this.showLoaderTrigger(false);
    });
  }

  getQuesIntervensions(selectedServiceID: number[], clearValue = true) {
    if (clearValue) {
      this.interventionsList = [];
      this.questionnaireList = [];
      this._questionForm.controls.interventions.setValue([]);
      this._questionForm.controls.questionnaire.setValue([]);
      this._questionForm.controls.addToQuestionnaire.setValue(false);
      this.addToQuesValueChange(false);
    }
    if (selectedServiceID.length) {
      this.acengageTreeForm.service = selectedServiceID;
      this.acengageTreeForm.subservice = selectedServiceID;
      this.showLoaderTrigger(true);
      const intervensionPayload: QuestionIntervensionsPayload = {
        service: selectedServiceID
      };
      this.questionService.getQuestionIntervensions(intervensionPayload).subscribe((quesIntervensionsResp: any) => {
        this.selectboxLabel = quesIntervensionsResp?.data?.label || "";
        this.interventionsList = quesIntervensionsResp?.data?.dropdown?.map((eachIntervension: QuestionTypeServiceInterventionResponse) => {
          const formSelectOption: SelectOption = {
            name: eachIntervension.name,
            value: eachIntervension.id
          };
          return formSelectOption;
        }) || [];
        this.showLoaderTrigger(false);
        this.fetchMappingFields();
      });
    }
  }

  getQuestionnaireList(selectedServiceID?: number[], clearValue = true) {
    if (clearValue) {
      this.questionnaireList = [];
      this._questionForm.controls.questionnaire.setValue([]);
    }
    if (selectedServiceID?.length || this._questionForm.value.services?.length) {
      this.showLoaderTrigger(true);
      const questionnairePayload: { services: number[] } = {
        services: selectedServiceID || this._questionForm.value.services || []
      };
      this.questionService.getQuestionnaireList(questionnairePayload).subscribe((questionnaireListResp: any) => {
        this.showLoaderTrigger(false);
        this.questionnaireList = questionnaireListResp?.data?.map((eachIntervension: QuestionTypeServiceInterventionResponse) => {
          const formSelectOption: SelectOption = {
            name: eachIntervension.name,
            value: eachIntervension.id
          };
          return formSelectOption;
        }) || [];
      });
    }
  }

  fetchMappingFields() {
    let mappingFields: SelectOption[] = [];
    if (this._questionForm.value.services?.length) {
      this.showLoaderTrigger(true);
      const mappingFieldPayload: { service_id: number[] | number | null } = { service_id: this.acengageTreeForm.service };
      this.treeService.getQuesBankMappingField(mappingFieldPayload).subscribe((mappingFieldResp: any) => {
        this.showLoaderTrigger(false);
        if (mappingFieldResp?.data?.length) {
          mappingFields = mappingFieldResp?.data?.map((eachField: any) => {
            const formSelectOption: SelectOption = { name: eachField.name, value: eachField.id };
            return formSelectOption;
          }) || [];
          this.sendMappingFields.emit(mappingFields);
        }
      });
    } else {
      this.sendMappingFields.emit(mappingFields);
    }
  }

  addToQuesValueChange(isAdd: boolean) {
    if (isAdd) {
      this.getQuestionnaireList();
      this._questionForm.controls.questionnaire.setValidators(Validators.required);
    } else {
      this._questionForm.controls.questionnaire.setValidators(null);
    }
    this._questionForm.controls.questionnaire.markAsPristine();
    this._questionForm.controls.questionnaire.updateValueAndValidity();
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
