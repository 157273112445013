import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class loaderService {
  constructor() {}

  showLoader$: Subject<boolean> = new Subject<boolean>();

  /**
   * show loader.
   */
  showLoader() {
    this.showLoader$.next(true);
  }
  /**
   * hide loader.
   */
  hideLoader() {
    this.showLoader$.next(false);
  }
}
