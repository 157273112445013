import { Basicinfo } from './../../accounts/models/add-account.interface';
import { basicDetails } from './../../services-screens/state/services.reducer';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { AppState } from '@acengage/app/app/state/app.state';
import { TableHeader } from '@acengage/ui/models/table.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CommonService } from '@acengage/app/app/services/common.service';
interface TimelineItem {
  date: string;
  title: string;
  content: any;
  expanded: boolean;
  isShowMore: any;
}
@Component({
  selector: 'acengage-ngage-continuity',
  templateUrl: './ngage-continuity.component.html',
  styleUrls: ['./ngage-continuity.component.scss'],
})
export class NgageContinuityComponent implements OnInit {
  
  @Input() empID: string | number;
  @Input() campaignID: number;
  @Output() continuityViewClicked = new EventEmitter<number>();
  mappingFields: any; 
  isShowMore: any = []; 
  headers: TableHeader[] = [];
  constructor(private appStore: Store<AppState>,
    private router: Router,
    private commonApi: CommonService) {}

  ngOnInit(): void {
    this.getCEContinuityDetails();
  }
  timelineItems: TimelineItem[] = [];
  getCEContinuityDetails() {
    this.showLoaderTrigger(true);
    this.commonApi.getCEContinuityDetails(this.empID).subscribe((continuityDtlsResp: any) => {
      this.showLoaderTrigger(false);
      this.mappingFields = continuityDtlsResp?.data || {};
      console.log('mappingFields',this.mappingFields);
      this.timelineItems =[];
      for (const key in this.mappingFields) {
        if (this.mappingFields.hasOwnProperty(key)) {
          const title = key;
          const date = this.mappingFields[key].date;
          const content:any = this.extractDetails(this.mergeDetails(this.mappingFields[key].data));
          const expanded = false;
          const isShowMore:any = [];

          this.timelineItems.push({ date, title, content, expanded,isShowMore });
        }
      }
      if (this.timelineItems.length) {
          this.headers=[];
          let details = this.timelineItems[0]?.content[0];
          for (const key in details) {
            if (details.hasOwnProperty(key) && key !== 'record_id' && key !== 'campaign_id') {
              const headerName = key;
              const objectKeyName = key;
              const isSortable = false;
              this.headers.push({ headerName, objectKeyName, isSortable });
            }
          }
      }
      console.log('timelineItems',this.timelineItems);
      console.log('headers',this.headers);

    })
  }
  mergeDetails(objectsArray: any[]): any[] {
    objectsArray.forEach(obj => {
      obj.details = { ...obj.basic_details };
      obj.details.basicInfo =  { ...obj.basic_details };
      let count = 0;
      for (const key in obj.other_details) {
        if (!obj.details.hasOwnProperty(key)) {
          obj.details[key] = obj.other_details[key];
          count++;
        }
      }
      obj.details['isMoreData'] = count > 0 ? true : false;
      obj.details['campaign_id'] = obj.campaign_id;
      obj.details['record_id'] = obj.record_id;
      obj.details.basicInfo['campaign_id'] =  obj.campaign_id;
      obj.details.basicInfo['record_id'] =  obj.record_id;
    });

    return objectsArray;
  }
  getBasicDetails(objectsArray: any[]): any[] {
    objectsArray.forEach(obj => {
      obj.basic_details = { ...obj.basic_details };
      obj.basic_details['campaign_id'] = obj.campaign_id;
      obj.basic_details['record_id'] = obj.record_id;
    });

    return objectsArray;
  }
  extractDetails(dataArray: any[]): any[] {
    const detailsArray: any[] = [];
    if(dataArray && dataArray.length){
      dataArray.forEach(item => {
        detailsArray.push(item.details);
      });
    }
    return detailsArray;
  }
  toggleItem(index: number): void {
    this.timelineItems[index].expanded = !this.timelineItems[index].expanded;
  }

  expandAll(): void {
    this.timelineItems.forEach(item => item.expanded = true);
  }

  collapseAll(): void {
    this.timelineItems.forEach(item => item.expanded = false);
  }
  showMore(item:any,i:number): void {
    item.isShowMore[i] = !item.isShowMore[i];
  }
  downloadExport() {
    this.showLoaderTrigger(true);
    let obj = {
      "export_type": 30,
      "record_id": this.empID,
      "client_id": null
    }
    this.commonApi.downloadRecords(
      obj
    ).subscribe(
      (res: any) => {
        this.showLoaderTrigger(false);
        this.showToaster('success', '', res?.message);
      },
      (err: any) => {
        this.showLoaderTrigger(false);
        // Handle the error here
      }
    );
  }
  showToaster(type: string, heading: string, message: string) {
    this.appStore.dispatch(
      AppActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: true,
      })
    );
  }
  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
  rowClicked(e:any,item:any){
    this.continuityViewClicked.emit(0);
    this.showLoaderTrigger(true);
    console.log('clicked',e,item)
    switch (item.title.toLowerCase()) {
      case 'ce': 
        this.navigateToCEInterventionDtls(e);
        setTimeout(() => {
          this.showLoaderTrigger(false);
        }, 1500);
        return
      case 'nhe':
        this.navigateToNHEInterventionDtls(e);
        setTimeout(() => {
          this.showLoaderTrigger(false);
        }, 1500);
        return
      case 'exits':
        this.navigateToExitInterventionDtls(e);
        setTimeout(() => {
          this.showLoaderTrigger(false);
        }, 1500);
        return
      case 'renege':
        this.navigateToExitInterventionDtls(e);
        setTimeout(() => {
          this.showLoaderTrigger(false);
        }, 1500);
        return
      case 'stay survey':
        this.navigateToExitInterventionDtls(e);
        setTimeout(() => {
          this.showLoaderTrigger(false);
        }, 1500);
        return
      default:
        setTimeout(() => {
          this.showLoaderTrigger(false);
        }, 500);
        return ;
    }
  }
  navigateToCEInterventionDtls(selectedRecord: any) {
    this.router.navigate(['/services/candidate-engagement/interventions'], {
      queryParams: { campaignID: selectedRecord.campaign_id, empID: selectedRecord.record_id,campaignName:selectedRecord['Campaign Name'],candidateName:selectedRecord['First Name'], isContinuity: true },
    });
  }
  navigateToNHEInterventionDtls(selectedRecord: any) {
    this.router.navigate(['/services/hire-engagement/interventions'], {
      queryParams: { campaignID: selectedRecord.campaign_id, empID: selectedRecord.record_id,campaignName:selectedRecord['Campaign Name'],candidateName:selectedRecord['First Name'], isContinuity: true },
    });
  }
  navigateToExitInterventionDtls(selectedRecord: any) {
    this.router.navigate(['/services/view-exit-campaign'], {
      queryParams: { service_id: selectedRecord.campaign_id, type 
      : 'Exit', recordId: selectedRecord.record_id,activeTab:3, isContinuity: true,interviewPage:true,activeSubTab:0,campaignName:selectedRecord['Campaign Name'],candidateName:selectedRecord['First Name'] },
    });
  }
  getObjectEntries(obj: any): any[] {
    return Object.entries(obj);
  }
}
