import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import * as fromActions from '../../state/app.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirstLoginInformation,sixDigitOtp,LoginInformation} from '../models/login.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { getLoginStates } from '../state/auth.selectors';
import {LoginState} from '../state/auth.reducer';
import * as AuthActions from '../state/auth.actions';
import { Observable } from 'rxjs';
import { loaderService } from '../../services/loader.service';

@Component({
  selector: 'acengage-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  constructor(private auth:AuthenticationService,private store: Store<AppState>,private store1: Store<LoginState>,private fb: FormBuilder,
    private router: Router,
    private route:ActivatedRoute,
    public _loader: loaderService
    ) {}
  newPassword='';
  confirmNewPassword='';
  step=1;
  localStoreValues:any;
  firstLoginForm: FormGroup<FirstLoginInformation>;
  loginForm: FormGroup<LoginInformation>;
  showGoogleCode=true;
  qrText="";
  sixDigitOtp:FormGroup<sixDigitOtp>
  loginStoreValue$: Observable<any>;
  toasterInfoObj={
    'severity':'success',
    'summary':'',
    'detail':''
  };

  displayToast=false;
  ngOnInit(){}
}
