import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CECampaignService } from '../services-screens/services/ce-campaign.service';
import { AppState } from '../state/app.state';
import * as AppActions from '../state/app.actions';
import { ActivatedRoute } from '@angular/router';
import { CESlotSelectionCandidateDetails, SlotChangeForm, ChangeSlotPayload } from '../services-screens/candidate-engagement/models/CE-Intervention-Slot-Selection.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { SelectOption } from '../models/select.model';
import { Config } from '../config';

@Component({
  selector: 'acengage-slot-confirmation',
  templateUrl: './slot-confirmation.component.html',
  styleUrls: ['./slot-confirmation.component.scss'],
})
export class SlotConfirmationComponent implements OnInit {
  constructor(private campaignCEService: CECampaignService,
    private appStore: Store<AppState>,
    private route: ActivatedRoute,
    private fb: FormBuilder,) { }

  slotID: number | null;
  slotType: string | null;
  todayDate: Date = new Date();
  slotSelectionForm: FormGroup<any>;
  candidateDtls = {} as CESlotSelectionCandidateDetails;
  slotDtls:any = {};
  slotDtlsManager:any = {};
  selectedSlot: number=0;
  isCustomClicked: boolean = false;
  isRejectClicked: boolean = false;
  option:any[] = [
    { value: 1, name: 'Slot 1'},
    { value: 2, name: 'Slot 2'},
    { value: 3, name: 'Slot 3'},
    { value: 4, name: 'Custom'},
  ];
  connectDtls: SelectOption[] = [{
    name: 'manager',
    value: 1
  }, {
    name: 'leader',
    value: 2
  }, {
    name: 'recruiter',
    value: 3
  }, {
    name: 'buddy',
    value: 4
  }, {
    name: 'peer',
    value: 5
  }];

  slotSuccess = false;

  ngOnInit(): void {
    this.slotID = this.route.snapshot.paramMap.get('id') ? Number(this.route.snapshot.paramMap.get('id')) : null;
    this.route.queryParams.subscribe((params: any) => {
      this.slotType = params?.type?params?.type:null;
    });
    this.slotType === 'manager'?this.getManagerDtls():this.getCandidateDtls();
    this.slotType === 'manager'?this.getCandidateSlots():this.getManagerSlots();
  }

  createSlotSelectionForm(isSlotChange:boolean) {
    if(isSlotChange){
      this.slotSelectionForm = this.fb.group({
        reason: this.fb.control('', [Validators.required]),
        slot_date_1: this.fb.control('', [Validators.required]),
        slot_date_2: this.fb.control('', [Validators.required]),
        slot_date_3: this.fb.control('', [Validators.required]),
        slot_time_1: this.fb.control('', [Validators.required]),
        slot_time_2: this.fb.control('', [Validators.required]),
        slot_time_3: this.fb.control('', [Validators.required])
      });
    }else{
      this.slotSelectionForm = this.fb.group({
        reason: this.fb.control('', [Validators.required]),
      });
    }
    
  }
  slotSelected(e:any){
    if(e==4){
      if(this.slotSelectionForm)this.slotSelectionForm.reset();
      this.createSlotSelectionForm(true);
      this.isCustomClicked=true;
      this.isRejectClicked=false;
      this.selectedSlot = Number(0);
    }
    else if(e==5){
      if(this.slotSelectionForm)this.slotSelectionForm.reset();
      this.createSlotSelectionForm(false);
      this.isCustomClicked=false;
      this.isRejectClicked=true;
      this.selectedSlot = Number(0);
    }
    else{
      if(this.slotSelectionForm)this.slotSelectionForm.reset();
      this.isCustomClicked=false;
      this.selectedSlot = Number(e);
    }
  }
  generateOptions(data:any): void {
    this.option=[];
    this.option = [
      { value: 1, name: data.slot_1 },
      { value: 2, name: data.slot_2 },
      { value: 3, name: data.slot_3 },
      { value: 4, name: 'Custom' },
    ];
    if(this.slotType === 'candidate'){
      this.option.push({ value: 5, name: 'Reject Slots'});
    }
  }
  getCandidateDtls() {
    if (this.slotID) {
      const userType = this.slotType === 'manager'?2:1;
      this.showLoaderTrigger(true);
      this.campaignCEService.getCandidateManagerDtlForSlot(this.slotID).subscribe((candidateDtlResp: any) => {
        this.showLoaderTrigger(false);
        this.candidateDtls = candidateDtlResp?.data || {};
        // this.createSlotSelectionForm();
      });
    }
  }
  getManagerDtls() {
    if (this.slotID) {
      const userType = this.slotType === 'manager'?2:1;
      this.showLoaderTrigger(true);
      this.campaignCEService.getManagerDtlForSlot(this.slotID).subscribe((candidateDtlResp: any) => {
        this.showLoaderTrigger(false);
        this.candidateDtls = candidateDtlResp?.data || {};
        // this.createSlotSelectionForm();
      });
    }
  }
  getCandidateSlots() {
    if (this.slotID) {
      this.campaignCEService.getCandidateSlots(this.slotID).subscribe((slotResp: any) => {
        this.showLoaderTrigger(false);
        this.slotDtls = slotResp?.data || {};
        if(this.slotDtls.slot_1 && this.slotDtls.slot_2 && this.slotDtls.slot_3){
          this.generateOptions(this.slotDtls);
        }
      });
    }
  }
  getManagerSlots() {
    if (this.slotID) {
      this.campaignCEService.getManagerSlots(this.slotID).subscribe((slotResp: any) => {
        this.showLoaderTrigger(false);
        this.slotDtls = slotResp?.data || {};
        if(this.slotDtls.slot_1 && this.slotDtls.slot_2 && this.slotDtls.slot_3){
          this.generateOptions(this.slotDtls);
        }
      });
    }
  }

  getRecruiterKeyName() {
    return this.connectDtls.find(eachDtl => eachDtl.value === this.candidateDtls.type)?.name || 'Recruiter';
  }


  submitSlotSelection() {
    this.isCustomClicked?this.submitSlotsByCandidate():this.submitSlotSelectionByManager();
  }
  submitSlotSelectionByManager(){
    if (this.slotID) {
      this.showLoaderTrigger(true);
      let selectedSlot: any = {}
      if(this.isCustomClicked){
        const slotRawData = this.slotSelectionForm.getRawValue();
        selectedSlot = {
          slot_1: `${moment(slotRawData.slot_date_1).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_1).format('HH:mm:ss')}`,
          slot_2: `${moment(slotRawData.slot_date_2).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_2).format('HH:mm:ss')}`,
          slot_3: `${moment(slotRawData.slot_date_3).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_3).format('HH:mm:ss')}`,
          reason:slotRawData.reason,
          status:3
        }
        if(!this.checkSlotsAreUnique(selectedSlot)){
          this.showLoaderTrigger(false);
          this.showToaster('error', '', 'Please ensure that the selected slots are different.');
          return;
        }
      }
      else if(this.isRejectClicked){
        const slotRawData = this.slotSelectionForm.getRawValue();
        selectedSlot = {
          reason:slotRawData.reason,
          status:4
        }
      }
      else{
        selectedSlot = {
          candidate_selected_slot : this.selectedSlot,
          status:2
        }
      }
      console.log('selectedSlot manager',selectedSlot);
      this.campaignCEService.updtSlotchangeByManager(this.slotID, selectedSlot).subscribe((updtSlotResp: any) => {
        this.showLoaderTrigger(false);
        this.showToaster('success', '', updtSlotResp.message);
        this.candidateDtls.is_submitted = true;
        this.slotSelectionForm.reset();
      });
    }
  }
  checkSlotsAreUnique(selectedSlot:any){
    const slots = [
      selectedSlot.slot_1,
      selectedSlot.slot_2,
      selectedSlot.slot_3
    ];
    
    // Check if all elements in the array are unique
    const areSlotsUnique = slots.length === new Set(slots).size;
    
    if (!areSlotsUnique) {
     return false;
    } else {
      return true;
    }
  }
  submitSlotsByCandidate(){
    if (this.slotID) {
      this.showLoaderTrigger(true);
      let updtSlotPayload: any = {}
      if(this.isCustomClicked){
        const slotRawData = this.slotSelectionForm.getRawValue();
        updtSlotPayload = {
          slot_1: `${moment(slotRawData.slot_date_1).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_1).format('HH:mm:ss')}`,
          slot_2: `${moment(slotRawData.slot_date_2).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_2).format('HH:mm:ss')}`,
          slot_3: `${moment(slotRawData.slot_date_3).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_3).format('HH:mm:ss')}`,
          reason:slotRawData.reason,
          status:3
        }
        if(!this.checkSlotsAreUnique(updtSlotPayload)){
          this.showLoaderTrigger(false);
          this.showToaster('error', '', 'Please ensure that the selected slots are different.');
          return;
        }
      }else if(this.isRejectClicked){
        const slotRawData = this.slotSelectionForm.getRawValue();
        updtSlotPayload = {
          reason:slotRawData.reason,
          status:4
        }
      }
      else{
        updtSlotPayload = {
          selected_slot : this.selectedSlot,
          status:2
        }
      }
      console.log('selectedSlot candidate',updtSlotPayload);
      this.campaignCEService.updtSlotchangeByCandidate(this.slotID, updtSlotPayload).subscribe((updtSlotResp: any) => {
        this.showLoaderTrigger(false);
        this.showToaster('success', '', updtSlotResp.message);
        this.candidateDtls.is_submitted = true;
        this.slotSelectionForm.reset();
      });
    }
  }

  showToaster(type: string, heading: string, message: string) {
    this.appStore.dispatch(AppActions.showToaster({
      class: type,
      header: heading,
      message: message,
      showToaster: true
    }));
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
