<plyr
  style="display: block; width: 640px"
  plyrTitle="Video 1"
  [plyrSources]="videoSource"
  (plyrInit)="player = $event"
  (plyrPlay)="played($event)"
></plyr>

<!-- <video #videoPlayer controls>
    <source [src]="videoSources" type="video/mp4">
    Your browser does not support the video tag.
  </video> -->
