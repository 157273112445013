import { NgModule } from '@angular/core';
import { AppComponent } from '@acengage/app/app/app.component';
import { AppRoutingModule } from '@acengage/app/app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@acengage/ui/lib/ui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsAcengageComponent } from '@acengage/app/app/components-acengage/components-acengage.component';
import { ActionReducer, StoreModule, MetaReducer, Action } from '@ngrx/store';
import { AppReducer } from '@acengage/app/app/state/app.reducers';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from '@acengage/app/app/core/interceptors/request.interceptors';
import { ResponseInterceptor } from '@acengage/app/app/core/interceptors/response.interceptors';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@acengage/app/environments';
import { AuthGuardService } from '@acengage/app/app/authgaurd/auth-gaurd.service';
import { localStorageSync } from 'ngrx-store-localstorage';
import { LoginState } from '@acengage/app/app/authentication/state/auth.reducer';
import { SharedModule } from '@acengage/app/app/shared/shared.module';
import { AuthenticationModule } from '@acengage/app/app/authentication/authentication.module';
import { logoutUser } from '@acengage/app/app/authentication/state/auth.actions';
import { SlotSelectionComponent } from './slot-selection/slot-selection.component';
import { SlotSelectionSuccessComponent } from './slot-selection/slot-selection-success/slot-selection-success.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { UnauthGuardService } from './authgaurd/unauth-guard.service';
import { SlotConfirmationComponent } from './slot-confirmation/slot-confirmation.component';
import { SlotConfirmSuccessComponent } from './slot-confirmation/slot-confirm-success/slot-confirm-success.component';
import { SlotNonContactableComponent } from './slot-non-contactable/slot-non-contactable.component';

export function logoutMetareducer(reducer: ActionReducer<LoginState>) {
  return function (state: LoginState, action: Action) {
    if (action.type === logoutUser.type) {
      return reducer(undefined, action);
    }

    return reducer(state, action);
  };
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: ['login'], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
  logoutMetareducer,
];

const INTERCEPTOR = [
  { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
];

@NgModule({
  declarations: [
    AppComponent,
    ComponentsAcengageComponent,
    SlotSelectionComponent,
    SlotSelectionSuccessComponent,
    SlotConfirmationComponent,
    SlotConfirmSuccessComponent,
    SlotNonContactableComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    UiModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    AuthenticationModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot({ app: AppReducer }, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    INTERCEPTOR,
    AuthGuardService,
    UnauthGuardService,
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
