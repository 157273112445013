import { Component, OnInit,Input,EventEmitter,Output } from '@angular/core';

@Component({
  selector: 'acengage-google-icon-button',
  templateUrl: './google-icon-button.component.html',
  styleUrls: ['./google-icon-button.component.scss'],
})
export class GoogleIconButtonComponent implements OnInit {
  constructor() {}
  @Input()
  set buttonText(value:any) {
    this.btnLabel=value;
  }
  @Output() buttonClicked= new EventEmitter
  btnLabel=''
  ngOnInit(): void {}

  buttonClick(event:any){
    this.buttonClicked.emit(event);
  }
  
}
