<form [formGroup]="questionForm" class="add__question--input--container slider__container block-div">
    <div class="add__question--left--content answer__content--input">
        <div class="add__question--slider--container">
            <acengage-select [options]="fromSlider" [isShowClear]="false" class="add__question--slider--each"
                formControlName="from" (ngModelChange)="sliderSelected($event, 'from');" [placeHolder]="'From'">
            </acengage-select>
            <acengage-select [options]="toSlider" [isShowClear]="false" class="add__question--slider--each"
                formControlName="to" (ngModelChange)="sliderSelected($event, 'to');" [placeHolder]="'To'">
            </acengage-select>
        </div>
    </div>
     <!-- follow up questions -->
     <div class="follow--questions">
        <div class="add__question--link add" (click)="addFollowup.emit();sliderNest=true">
            <i class="pi pi-plus"></i>
            <span>Add Follow up Question</span>
        </div>
        <div class="follow--questions__section" *ngIf="sliderNest">
            <div class="follow--questions__head">
                <p class="follow-p">Follow up question</p>
                <div>
                    <div class="add__question--link remove" (click)="removeNest()">
                        <i class="pi pi-trash"></i>
                        <span>Remove</span>
                    </div>
                </div>
            </div>
            <acengage-text-input [inputType]="'text'" [maxLength]="null" [placeholder]="'Enter the question here'" formControlName="followup_question"></acengage-text-input>
            <div class="follow--questions__add">
                <div class="add__question--left--content answer__content--input">
                    <div class="add__question--slider--container">
                        <acengage-select [options]="nestFromSlider" [isShowClear]="false" class="add__question--slider--each"
                            formControlName="follow_up_from" (ngModelChange)="sliderFollowUpSelected($event, 'follow_up_from');" [placeHolder]="'From'">
                        </acengage-select>
                        <acengage-select [options]="nestToSlider" [isShowClear]="false" class="add__question--slider--each"
                            formControlName="follow_up_to" (ngModelChange)="sliderFollowUpSelected($event, 'follow_up_to');" [placeHolder]="'To'">
                        </acengage-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>