import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, tap, Subscription } from 'rxjs';
import { AuthenticationService } from '../../core/services/authentication.service';
import { sixDigitOtp } from '../models/login.model';
import { LoginState } from '../state/auth.reducer';
import { Store } from '@ngrx/store';
import { getLoginStates } from '../state/auth.selectors';
import * as AuthActions from '../state/auth.actions';
import { AppState } from '../../state/app.state';
import * as appActions from '../../state/app.actions';
import { Config } from '../../config';
@Component({
  selector: 'acengage-validate-tfa',
  templateUrl: './validate-tfa.component.html',
  styleUrls: ['./validate-tfa.component.scss'],
})
export class ValidateTfaComponent implements OnInit {
  sixDigitOtp: FormGroup<sixDigitOtp>;
  loginStoreValue$: Observable<any>;
  toasterInfoObj = {
    severity: 'success',
    summary: '',
    detail: '',
  };
  localStoreValues: any = { appStates: {} };
  displayToast = false;
  showLoader: boolean = false;
  numRegex = Config.numberPolicy;
  storeSubscribed$: Subscription;
  constructor(
    private auth: AuthenticationService,
    private fb: FormBuilder,
    private router: Router,
    private store1: Store<LoginState>,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.createSixDigitform();
    this.storeSubscribed$ = this.store1.select(getLoginStates).subscribe((state) => {
      this.localStoreValues = { appStates: state };
      if (this.localStoreValues.appStates.id == '' || !this.localStoreValues.appStates.id || this.localStoreValues.appStates.id == undefined || !this.localStoreValues.appStates.hasOwnProperty('qr_text') || (this.localStoreValues.appStates.hasOwnProperty('qr_text') && this.localStoreValues.appStates.qr_text == undefined)) {
        if(this.localStoreValues.appStates.roles[0].id !== 11 && this.localStoreValues.appStates.is_client_2fa_verified && !this.localStoreValues.appStates.is_client_2fa_verified.is_2fa) {
          this.router.navigateByUrl('/auth/login')
        }
      }
    })

  }
  createSixDigitform(): void {
    this.sixDigitOtp = this.fb.group({
      otp1: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp2: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp3: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp4: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp5: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otp6: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
  }

  enterOtp(event: any, prev: any, curr: any, next: any) {
    if (event.key == 'Backspace') {
      if (prev != '') {
        prev.element.nativeElement.childNodes[0].firstElementChild.focus();
      }
    }
    const length = curr.value.length;
    const maxlength = event.currentTarget.getAttribute('maxlength');

    if (length == maxlength) {
      if (next != '') {
        next.element.nativeElement.childNodes[0].firstElementChild.focus();
      }
    }

  }

  check2FA() {
    this.displayToast = false;
    this.showLoader = true;
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    if (
      this.sixDigitOtp.controls['otp1'].value != null &&
      this.sixDigitOtp.controls['otp2'].value != null &&
      this.sixDigitOtp.controls['otp3'].value != null &&
      this.sixDigitOtp.controls['otp4'].value != null &&
      this.sixDigitOtp.controls['otp5'].value != null &&
      this.sixDigitOtp.controls['otp6'].value != null
    ) {
      const otp = this.sixDigitOtp.controls['otp1'].value +
        this.sixDigitOtp.controls['otp2'].value +
        this.sixDigitOtp.controls['otp3'].value +
        this.sixDigitOtp.controls['otp4'].value +
        this.sixDigitOtp.controls['otp5'].value +
        this.sixDigitOtp.controls['otp6'].value;
      this.auth.verify2FAcode({ twoFa: otp }).subscribe(
        (res: any) => {
          this.store.dispatch(appActions.showLoader({ showLoader: false }));
          this.showLoader = false;
          if (res['code'] == 200) {
            this.toasterInfoObj = {
              severity: 'success',
              summary: 'Success',
              detail: '',
            };
            let toStore = {
              id: this.localStoreValues.appStates.id,
              default_password_changed:
                this.localStoreValues.appStates.default_password_changed,
              first_login: this.localStoreValues.appStates.first_login,
              roles: this.localStoreValues.appStates.roles,
              is_2fa: res.is_2fa,
              token: res.token.access,
              username: res.username,
              qr_text: '',
              is_client_2fa_verified: true
            };
            
            if(this.localStoreValues.appStates.roles[0].id === 11) {
              this.store1.dispatch(AuthActions.updateLoginStateFromVerify(toStore));
              this.store.dispatch(AuthActions.sideMenuBar({ payload: toStore }));
              this.displayToast = true;
              if (this.localStoreValues.appStates.progress_of_completion == 11) {
                this.router.navigateByUrl(`/client-admin/landing`);
              }
            } else {
              this.store1.dispatch(AuthActions.updateLoginStateFromVerify(toStore));
              this.store.dispatch(AuthActions.sideMenuBar({ payload: toStore }));
              this.displayToast = true;
            }
          }
        },
        (err) => {
          this.showLoader = false;
          this.store.dispatch(appActions.showLoader({ showLoader: false }));
          this.sixDigitOtp.setValue({
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            otp5: '',
            otp6: ''
          });
          if (err['error']?.message) {
            this.store.dispatch(appActions.showToaster({
              class: 'error',
              header: err['error']?.message,
              message: '',
              showToaster: true
            }));
          } else {
            this.store.dispatch(appActions.showToaster({
              class: 'error',
              header: 'Server error',
              message: '',
              showToaster: true
            }));
          }

          this.displayToast = true;
        }
      );
    }
  }
  ngOnDestroy() {
    this.storeSubscribed$.unsubscribe()
  }
}
