import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'acengage-border-button',
  templateUrl: './border-button.component.html',
  styleUrls: ['./border-button.component.scss'],
})
export class BorderButtonComponent implements OnInit {
  constructor() {}
  icon='';
  label='';
  @Input()
  set buttonIcon(value:any) {
    this.icon=value;
  }
  @Input()
  set buttonlabel(value:any) {
    this.label=value;
  }
  @Output() buttonClicked = new EventEmitter();
  buttonClick(event:any) {
    this.buttonClicked.emit(event);
  }
  ngOnInit(): void {}
}
