<router-outlet></router-outlet>

<acengage-toaster
  toasterPosition="top-right"
  [toasterInfos]="toasterInfo"
  [displaytoaster]="displayToast"
  (hideToaster)="hideToaster($event)"
>
</acengage-toaster>
<acengage-loader [showLoader]="showLoader"></acengage-loader>


<acengage-dialog
  [openModel]="showConfirmationDialog"
  [headerText]="'Update Profile'"
  [confirmButtonText]="'Continue'"
  [cancelButtonText]="'Cancel'"
  (confirmButton)="updtStatusOfUsers('edit')"
  (cancelButton)="updtStatusOfUsers('skip')"
>
  <p>{{ confimationMessage }}</p>
</acengage-dialog>

<acengage-dialog [classesToAdd]="'reduced_dialog_size'" [openModel]="showInitiateCallDialog"
[headerText]="''" [addCloseIcon]="(iconIndex !== 0 && iconIndex !== 3 && iconIndex !== 5 && iconIndex !== 6 && iconIndex !== 100)?true:false"
(cancelButton)="showInitiateCallDialog = false;" (modelStateUpdate)="closeModal($event)">
<div class="ca__profile__creation--popup" >
  <div class="ca__profile__creation--popup-cntr">
    <div class="caller-content">
      <div class="caller-head">
        <div class="caller-icon" [ngClass]="iconItems[iconIndex]?.className"></div>
        <div class="caller-text">{{isCallerError?isCallerError:callerMessage}}</div>
      </div>
      <div class="caller-body">
        <div class="caller-dotted-icon-outer">
          <div class="caller-dotted-icon"></div>
        </div>
        <div class="caller-wrapper" [ngClass]="{'wrap-reverse' : !isOutgoingCall}">
          <div class="caller-user">
            <div class="caller-user-avatar left">
              <!-- {{loggedInUserDtls?.userDetails?.personal_info?.first_name[0] ?? '-'}} -->
            </div>
            <h4 class="caller-user-name marg_0">Smartflo
              <!-- {{loggedInUserDtls?.userDetails?.personal_info?.first_name ?? '-'}} -->
            </h4>
            <!-- <p class="caller-user-id marg_0">{{loggedInUserDtls?.userDetails?.personal_info?.mobile_number ?? '-'}}</p> -->
            <!-- <img src="assets/images/smartflo.jpg" alt="call icon" /> -->
          </div>
          <div class="caller-user">
            <div class="caller-user-avatar" style="text-transform: uppercase;">{{callerModal.first_name[0]}}</div>
            <h4 class="caller-user-name marg_0">{{callerModal.first_name ?? '-'}}</h4>
            <p class="caller-user-id marg_0">{{callerModal.mobile ?? '-'}}</p>
          </div>
        </div>
      </div>
      <div class="caller-button" *ngIf="isOutgoingCall">
          <acengage-button [buttonText]="'Start Interview'" buttonClassesToAdd="secondary-btn" *ngIf="iconIndex === 3"
          [iconDtls]="{ name: 'pi pi-arrow-right', position: 'right' }" (buttonClicked)="rowSelection(dialedCandidate)"></acengage-button>
      </div>
    </div>
  </div>
</div>
</acengage-dialog>
