import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acengage-accounts-icon',
  templateUrl: './accounts-icon.component.html',
  styleUrls: ['./accounts-icon.component.scss'],
})
export class AccountsIconComponent implements OnInit {
  isClicked: boolean = false;
  @Input() name: string = '';
  @Input() icon: string = '';
  @Output() iconClick: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  toggleIconClick() {
    this.isClicked = !this.isClicked;
    this.iconClick.emit(this.isClicked);
  }
}
