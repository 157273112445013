import { FormControl } from '@angular/forms';

export interface FirstLoginInformation {
  username: FormControl<string | null>;
  password: FormControl<string | null>;
}

export interface LoginInformation {
  username: FormControl<string | null>;
  password: FormControl<string | null>;
  otp: FormControl<string | null>;
}
export interface ResetPasswordInformation {
  user_id: FormControl<number | null>;
  password: FormControl<string | null>;
}
export interface LoginParams {
  username: string;
  password: string;
  otp: string;
}
export interface ResetPasswordParams {
  user_id: number;
  password: string;
}
export interface sixDigitOtp {
  otp1: FormControl<string | null>;
  otp2: FormControl<string | null>;
  otp3: FormControl<string | null>;
  otp4: FormControl<string | null>;
  otp5: FormControl<string | null>;
  otp6: FormControl<string | null>;
}

export interface fourDigitOtp {
  otp1: FormControl<string | null>;
  otp2: FormControl<string | null>;
  otp3: FormControl<string | null>;
  otp4: FormControl<string | null>;
}

export interface QrCodePayloadSuccess {
  qrCode: string;
}

export interface twoFAParams {
  twoFa: number;
}

export interface firstLoginPayloadSucess {
  token: string;
  refreshToken: string;
  id: number;
  default_password_changed: boolean;
  first_login: boolean;
  is_2fa: boolean;
  qr_text: string;
  username?: string;
  picture?: string;
  roles: Role[];
  usage_id?: number;
  client_details: any;
  full_name: any;
  is_client_2fa_verified: boolean;
  progress_of_completion: number;
}

export interface LoggedInUserDetails {
  default_password_changed: boolean;
  first_login: boolean;
  id: number;
  is_2fa: boolean;
  token: string;
  username: string;
  roles: Role[];
  client_details: any;
  is_client_2fa_verified: boolean;
  progress_of_completion: number;
}

// forgot
export interface ForgotPayload {
  username: string | null;
}

export interface ForgotInformation {
  username: FormControl<string | null>;
}
export interface ForgotOTPPayload {
  username: string | null;
  otp: string | number | null;
}

export interface ForgotResetPasswordParams {
  username: number;
  password: string;
}
export interface ForgotDtls {
  message: string;
  code: string;
}
export interface Role {
  id: number;
  name: string;
}
