<div class="edit__table__columns--container" (clickOutside)="this.closePopup('clear');">
    <acengage-button class="edit__column__btn" buttonText="Edit Columns" [isNgContentAdded]="true"
        [isBtnDisable]="disableBtn" buttonClassesToAdd="p-button-outlined p-button-secondary"
        (buttonClicked)="closeInBtnClick();">
        <img src="../../shared-assets/images/general_icons/edit_icon_primary.svg" alt="icon">
    </acengage-button>

    <div class="edit__table__columns--content" *ngIf="isShowEditPopup">
        <div class="edit__table__columns--header">
            <p-checkbox name="all" [binary]="true" inputId="all" [(ngModel)]="checkAllColumns"
                (onChange)="checkColumn($event, 'all');"></p-checkbox>
            <acengage-text-input [placeholder]="'Filter'" [isShowFloatLabel]="false" [(ngModel)]="filteredValue"
                (ngModelChange)="filterColumnName();">
            </acengage-text-input>
        </div>
        <div class="edit__table__columns--body">
            <ng-container *ngFor="let eachColumnDtl of columnsList; let columnIndex = index">
                <div [ngStyle]="{display: !eachColumnDtl.isEditShow ? 'none' : 'flex'}" (onDragEnd)="dragEnd($event)"
                    (onDragStart)="dragStart($event, eachColumnDtl, columnIndex)" (onDrop)="drop($event, columnIndex);"
                    pDraggable="columns" pDroppable="columns" class="edit__table__columns--each">
                    <p-checkbox name="edit_columns" [binary]="true" [(ngModel)]="eachColumnDtl.isShow"
                        [inputId]="eachColumnDtl.objectKeyName + '__' + columnIndex"
                        (onChange)="checkColumn($event, columnIndex);">
                    </p-checkbox>
                    <div>{{eachColumnDtl.headerName}}</div>
                </div>
            </ng-container>
        </div>
        <div class="edit__table__columns--footer">
            <acengage-button buttonText="Cancel" [buttonClassesToAdd]="'secondary-btn'"
                (buttonClicked)="closePopup('cancel');"></acengage-button>
            <acengage-button buttonText="Save" [isBtnDisable]="checkDisable()"
                (buttonClicked)="closePopup('save');"></acengage-button>
        </div>
    </div>
</div>