import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'acengage-read-more-text',
  templateUrl: './read-more-text.component.html',
  styleUrls: ['./read-more-text.component.scss'],
})
export class ReadMoreTextComponent implements OnInit {
  @Input() textToShow = '';
  @Input() charLimit = 500;
  showAllText = false;
  constructor() {}

  ngOnInit(): void {}
}
