<div class="popup-overlay"></div>
<div class="message-popup">
    <div class="message-content">
        <div class="message-icon" [ngClass]="{ 'success': success, 'error': error }">
            <i class="pi" [ngClass]="{'pi-user': success, 'pi-times': error}"></i>
        </div>
        <div class="message-text">{{ message }}</div>
        <div class="message-button">
            <acengage-button [buttonText]="buttonDtls.buttonText" [iconDtls]="buttonDtls.iconDetails"
                [buttonClassesToAdd]="buttonDtls.buttonClassesToAdd"
                (buttonClicked)="closeClicked.emit();"></acengage-button>
        </div>
    </div>
</div>