<div class="forgot-cntr">
  <div class="forgot-head">Forgot Password</div>
  <form [formGroup]="forgotForm" (keydown)="checkForEnter($event)">
    <div class="forgot-input">
      <div class="forgot-input-content">
        <div class="marginBottom10px">
          <label>Username</label>
        </div>
        <div class="marginBottom10px">
          <acengage-text-input
            formControlName="username"
            [maxLength]="'50'" (keydown.space)="$event.preventDefault()"
            [isShowFloatLabel]="false"
            [placeholder]="'Enter your registered username'"
          ></acengage-text-input>
          <!-- <acengage-form-error [control]="forgotForm.controls.username"  validatorName="required">
            Username is required.
        </acengage-form-error>
        <acengage-form-error  [control]="forgotForm.controls.username" validatorName="MinMax">
         Maximum limit is 50.
        </acengage-form-error> -->
        </div>
      </div>
    </div>
    <div class="forgot-btn">
      <acengage-button
        [isBtnDisable]="!forgotForm.valid"
        (buttonClicked)="submitUsername()"
        buttonText="Submit"
      ></acengage-button>
    </div>
    <div class="marginBottom10px forgot-link">
      <a class="forgot-link" routerLink="/auth/login">Go to Login</a>
    </div>
  </form>
</div>
