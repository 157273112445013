<div class="add__client__admin--container">
    <div class="add__client__admin--content">
        <div class="add__client__admin--header" [ngClass]="{'add__border': !isLoggedIn}">
            <span>{{isLoggedIn ? 'Hello' : 'Fill in the details'}}</span>
            <span *ngIf="isLoggedIn" class="add__client__admin--header--extra">Dwight Schrute</span>
            <span class="add__client__admin--header--sub">{{isLoggedIn ?
                'Here are all your prefilled data' :
                'After confirmation from admin, rest of onboarding will be continued'}}</span>
        </div>
        <form [formGroup]="addClientAdminForm" class="add__client__admin--input--container"
            [ngClass]="{'remove__border': !isLoggedIn}">
            <div class="add__client__admin--input--each">
                <acengage-text-input formControlName="organisation_name" [inputType]="'text'" [maxLength]="50"
                    [placeholder]="'Name of the organisation *'">
                </acengage-text-input>
                <acengage-form-error [control]="addClientAdminForm.controls.organisation_name" validatorName="required">
                    Name of the organisation is required
                </acengage-form-error>
                <acengage-form-error [control]="addClientAdminForm.controls.organisation_name"
                    validatorName="minlength">
                    Name of the organisation must be greater than or equal to 2 Characters
                </acengage-form-error>
            </div>
            <div class="add__client__admin--input--each">
                <acengage-select formControlName="spoc_name" [options]="[]" [placeHolder]="'Primary SPOC Name *'">
                </acengage-select>
                <acengage-form-error [control]="addClientAdminForm.controls.spoc_name" validatorName="required">
                    Primary SPOC name is required
                </acengage-form-error>
            </div>
            <div class="add__client__admin--input--each">
                <ngx-intl-tel-input [cssClass]="'custom'" [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name, SearchCountryField.DialCode]"
                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [minlength]="7"
                    [maxLength]="12" [phoneValidation]="true" [separateDialCode]="false"
                    [numberFormat]="PhoneNumberFormat.National"
                    [formControl]="addClientAdminForm.controls['spoc_mobile_number']" [enablePlaceholder]="true"
                    [customPlaceholder]="'Primary SPOC Mobile Number *'">
                </ngx-intl-tel-input>
                <acengage-form-error [controlName]="'mobile_number'"
                    [control]="addClientAdminForm.controls.spoc_mobile_number" validatorName="required">
                    Primary SPOC mobile number is required
                </acengage-form-error>
                <acengage-form-error [controlName]="'mobile_number'"
                    [control]="addClientAdminForm.controls.spoc_mobile_number" validatorName="validatePhoneNumber">
                    Invalid mobile number
                </acengage-form-error>
            </div>
            <div class="add__client__admin--input--each">
                <acengage-text-input [inputType]="'email'" formControlName="spoc_email"
                    [placeholder]="'Primary SPOC Email Id *'">
                </acengage-text-input>
                <acengage-form-error [control]="addClientAdminForm.controls.spoc_email" validatorName="required">
                    Primary SPOC email is required
                </acengage-form-error>
                <acengage-form-error [control]="addClientAdminForm.controls.spoc_email" validatorName="pattern">
                    Primary SPOC email is invalid
                </acengage-form-error>
            </div>
            <div class="add__client__admin--input--each">
                <acengage-text-input formControlName="service_type" [inputType]="'text'" [allowedKeys]="'alphanum'"
                    [maxLength]="50" [placeholder]="'Type of Service *'">
                </acengage-text-input>
                <acengage-form-error [control]="addClientAdminForm.controls.service_type" validatorName="required">
                    Type of service is required
                </acengage-form-error>
            </div>
        </form>
        <div *ngIf="isLoggedIn" class="add__client__admin--continue--container textCenter">
            <i></i>
            <div class="add__client__admin--header marginBottom2rem">
                Continue your signup by
                <br>
                filling up the form
            </div>
            <acengage-button [buttonText]="'Start profile creation'"
                (buttonClicked)="navigateToProfileCreation();"></acengage-button>
        </div>
    </div>
    <acengage-bottom-bar *ngIf="!isLoggedIn" [nextBtnDisable]="addClientAdminForm.invalid"
        [nextBtnLabel]="'Confirm and send'" [activeStep]="1" [stepItems]="2"
        (navigateBtnClicked)="showSuccessMsgPopup = true;"></acengage-bottom-bar>
</div>

<acengage-message-popup *ngIf="showSuccessMsgPopup" [success]="true" [buttonDtls]="msgPopupButtonDtls"
    [message]="'Thank you for entering the data. You will receive a mail shortly from the admin confirming your registration.'"
    (closeClicked)="showSuccessMsgPopup = false;"></acengage-message-popup>