<p-toast></p-toast>
<div *ngIf="!clientData">
  <ng-container *ngIf="isMultiUpload; else singleUpload">
    <p-fileUpload
      #multipleFileUpload
      [name]="name"
      (onSelect)="onMultiUpload($event)"
      (onClear)="clearFileMultiple($event)"
      (onRemove)="clearFileMultiple($event)"
      [multiple]="isMultiUpload"
      [accept]="acceptType"
      [showUploadButton]="false"
      [chooseLabel]="btnLabel"
      [chooseIcon]="btnIcon"
      [previewWidth]="125"
      [customUpload]="true"
      [files]="existingFiles"
      [showCancelButton]="showCancelButton"
    >
      <ng-template pTemplate="content" let-files>
        <!-- <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">
            {{ file.name }} - {{ file.size }} bytes manage
          </li>
        </ul> -->
        <div class="upload__info__message" *ngIf="!uploadedFiles.length">
          Upload Size limit of {{ formatBytes(maxFileSize) }}, <span *ngIf="!removeAcceptText">Support formats
            {{ acceptType }}</span><span *ngIf="removeAcceptText">Supports All formats</span>
        </div>
      </ng-template>
    </p-fileUpload>
  </ng-container>
  <ng-template #singleUpload>
    <div class="single-upload">
      <p-fileUpload
        #fubauto
        [name]="name"
        [accept]="acceptType"
        [previewWidth]="125"
        [showUploadButton]="false"
        [chooseLabel]="btnLabel"
        [chooseIcon]="btnIcon"
        [customUpload]="true"
        (onSelect)="onSingleUpload($event)"
        (onClear)="clearFile($event)"
        (onRemove)="clearFile($event)"
        (onError)="clearFile($event)"
        [files]="existingFiles"
        [disabled]="disableUpload"
        [showCancelButton]="showCancelButton"
      >
        <ng-template pTemplate="content" let-files>
          <div class="upload__info__message" *ngIf="!uploadedFiles.length">
            Upload Size limit of {{ formatBytes(maxFileSize) }}, Support formats
            {{ acceptType }}
          </div>
        </ng-template>
      </p-fileUpload>
    </div>
  </ng-template>
</div>

<!-- <div *ngIf="clientData">
  <p-fileUpload mode="basic" chooseLabel="Upload" [name]="name" [accept]="acceptType" [maxFileSize]="1000000" (onUpload)="onUpload($event)"></p-fileUpload>
</div> -->
<div *ngIf="clientData">
  <p-toast></p-toast>
  <p-fileUpload
    mode="basic"
    chooseLabel="Upload"
    [name]="name"
    [accept]="acceptType"
    [maxFileSize]="1000000"
    (onUpload)="onUpload($event)"
    [auto]="true"
  ></p-fileUpload>
</div>
