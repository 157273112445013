import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acengage-forgot-password-step-four',
  templateUrl: './forgot-password-step-four.component.html',
  styleUrls: ['./forgot-password-step-four.component.scss'],
})
export class ForgotPasswordStepFourComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {}
  navigateToLogin(){
    this.route.navigate(['/auth/login'])
  }
}
