<div class="tree-dropdown-wrap" (clickOutside)="clickoutSide();">
    <div class="text-wrap displayFlex align-items-center" [ngClass]="{'treeValid':treeValue || dropDown, 'disabled':disabled}"
        (click)="selectboxClick();"><span *ngIf="treeValue">{{treeValue}}</span>
        <label>{{placeholder}}</label>
        <i *ngIf="treeValue" (click)="removeData()"
            class="p-dropdown-clear-icon pi pi-times ng-tns-c72-4 ng-star-inserted"></i>
        <i class="p-dropdown-trigger-icon ng-tns-c72-18 pi pi-chevron-down"></i>
    </div>
    <div class="tree-dropdown" *ngIf="dropDown">
        <ul class="scroll">
            <li class="menuitem sep-row" *ngFor="let list of data;let i=index;" [ngClass]="{'menuActive':list.active}"
                (click)="$event.stopPropagation(); list.active=!list.active;firstDropdown(i,list,list?.children?.length)"
                (mouseover)="list.hovered = true;" (mouseleave)="list.hovered = false;">
                <div class="left-content">{{list?.label}}</div>
                <div *ngIf="showSubmit || list?.children?.length" class="right-content">
                    <div *ngIf="showSubmit && list.hovered && firstNodeType !== 'trees' && (!isSelectAnyLevel && !list?.children?.length)"
                        class="submit-container"
                        (click)="$event.stopPropagation(); firstDropdown(i,list,list?.children?.length, true);">
                        <i class="pi pi-check"></i>
                    </div>
                    <div *ngIf="(firstNodeType === 'trees' && list.is_drilldown) || (firstNodeType === 'nodes' && list?.children?.length)"
                        class="arrow-container">
                        <img src="/assets/images/treeArrow.svg" alt="arrow">
                    </div>
                </div>
                <ul class="submenu-list firstList" [ngClass]="{'position-left':subMenuShowLeft}"
                    *ngIf="((firstNodeType === 'trees' && list.is_drilldown) || (firstNodeType === 'nodes' && list?.children?.length)) && list?.active">
                    <div class="scroll">
                        <li class="sep-row" *ngFor="let first of list.children;let j=index;"
                            [ngClass]="{'secondmenuActive':first.active}"
                            (click)="$event.stopPropagation(); first.active=!first.active; secondDropdown(i,j,first,first?.children?.length)"
                            (mouseover)="first.hovered = true;" (mouseleave)="first.hovered = false;">

                            <div class="left-content">{{first.label}}</div>
                            <div *ngIf="showSubmit || first?.children?.length" class="right-content">
                                <div *ngIf="showSubmit && first.hovered && (!isSelectAnyLevel && !first?.children?.length)"
                                    class="submit-container"
                                    (click)="$event.stopPropagation(); secondDropdown(i,j,first,first?.children?.length, true);">
                                    <i class="pi pi-check"></i>
                                </div>
                                <div *ngIf="showSubmit && !first.hovered" class="empty__space"></div>
                                <div *ngIf="first?.children?.length" class="arrow-container">
                                    <img src="/assets/images/treeArrow.svg" alt="arrow">
                                </div>
                            </div>

                            <ul class="submenu-list childNodes" [ngClass]="{'position-left':subMenuShowLeft}"
                                *ngIf="first?.children?.length && first?.active">
                                <div class="scroll">
                                    <li class="sep-row" *ngFor="let second of first.children;let k=index;"
                                        [ngClass]="{'thirdmenuActive':second.active}"
                                        (click)="$event.stopPropagation(); second.active=!second.active;thirdDropdown(i,j,k,second,second?.children?.length)"
                                        (mouseover)="second.hovered = true;" (mouseleave)="second.hovered = false;">

                                        <div class="left-content">{{second.label}}</div>
                                        <div *ngIf="showSubmit || second?.children?.length" class="right-content">
                                            <div *ngIf="showSubmit && second.hovered && (!isSelectAnyLevel && !second?.children?.length)"
                                                class="submit-container"
                                                (click)="$event.stopPropagation(); thirdDropdown(i,j,k,second,second?.children?.length, true);">
                                                <i class="pi pi-check"></i>
                                            </div>
                                            <div *ngIf="showSubmit && !second.hovered" class="empty__space"></div>
                                            <div *ngIf="second?.children?.length" class="arrow-container">
                                                <img src="/assets/images/treeArrow.svg" alt="arrow">
                                            </div>
                                        </div>

                                        <ul class="submenu-list childNodes"
                                            [ngClass]="{'position-left':subMenuShowLeft}"
                                            *ngIf="second?.children?.length && second?.active">
                                            <div class="scroll">
                                                <li class="sep-row" *ngFor="let third of second.children;let l=index;"
                                                    [ngClass]="{'forthmenuActive':third.active}"
                                                    (click)="$event.stopPropagation(); third.active=!third.active;forthDropdown(i,j,k,l,third,third?.children?.length)"
                                                    (mouseover)="third.hovered = true;"
                                                    (mouseleave)="third.hovered = false;">

                                                    <div class="left-content">{{third.label}}</div>
                                                    <div *ngIf="showSubmit || third?.children?.length"
                                                        class="right-content">
                                                        <div *ngIf="showSubmit && third.hovered && (!isSelectAnyLevel && !third?.children?.length)"
                                                            class="submit-container"
                                                            (click)="$event.stopPropagation(); forthDropdown(i,j,k,l,third,third?.children?.length, true);">
                                                            <i class="pi pi-check"></i>
                                                        </div>
                                                        <div *ngIf="showSubmit && !third.hovered" class="empty__space">
                                                        </div>
                                                        <div *ngIf="third?.children?.length" class="arrow-container">
                                                            <img src="/assets/images/treeArrow.svg" alt="arrow">
                                                        </div>
                                                    </div>

                                                    <ul class="submenu-list childNodes"
                                                        [ngClass]="{'position-left':subMenuShowLeft}"
                                                        *ngIf="third?.children?.length && third?.active">
                                                        <div class="scroll">
                                                            <li class="sep-row"
                                                                *ngFor="let forth of third.children;let m=index;"
                                                                [ngClass]="{'fifthmenuActive':forth.active}"
                                                                (click)="$event.stopPropagation(); forth.active=!forth.active;fifthDropdown(i,j,k,l,m,forth, forth?.children?.length)"
                                                                (mouseover)="forth.hovered = true;"
                                                                (mouseleave)="forth.hovered = false;">

                                                                <div class="left-content">{{forth.label}}</div>
                                                                <div *ngIf="showSubmit || forth?.children?.length"
                                                                    class="right-content">
                                                                    <div *ngIf="showSubmit && forth.hovered && (!isSelectAnyLevel && !forth?.children?.length)"
                                                                        class="submit-container"
                                                                        (click)="$event.stopPropagation(); fifthDropdown(i,j,k,l,m,forth,forth?.children?.length,true);">
                                                                        <i class="pi pi-check"></i>
                                                                    </div>
                                                                    <div *ngIf="showSubmit && !forth.hovered"
                                                                        class="empty__space"></div>
                                                                    <div *ngIf="forth?.children?.length"
                                                                        class="arrow-container">
                                                                        <img src="/assets/images/treeArrow.svg"
                                                                            alt="arrow">
                                                                    </div>
                                                                </div>

                                                                <ul class="submenu-list childNodes"
                                                                    [ngClass]="{'position-left':subMenuShowLeft}"
                                                                    *ngIf="forth?.children?.length && forth?.active">
                                                                    <div class="scroll">
                                                                        <li class="sep-row"
                                                                            *ngFor="let fifth of forth.children;let n=index;"
                                                                            [ngClass]="{'sixthmenuActive':fifth.active}"
                                                                            (click)="$event.stopPropagation(); fifth.active=!fifth.active;sixthDropdown(i,j,k,l,m,n,fifth,fifth?.children?.length)"
                                                                            (mouseover)="fifth.hovered = true;"
                                                                            (mouseleave)="fifth.hovered = false;">

                                                                            <div class="left-content">{{fifth.label}}
                                                                            </div>
                                                                            <div *ngIf="showSubmit || fifth?.children?.length"
                                                                                class="right-content">
                                                                                <div *ngIf="showSubmit && fifth.hovered && (!isSelectAnyLevel && !fifth?.children?.length)"
                                                                                    class="submit-container"
                                                                                    (click)="$event.stopPropagation(); sixthDropdown(i,j,k,l,m,n,fifth,fifth?.children?.length, true);">
                                                                                    <i class="pi pi-check"></i>
                                                                                </div>
                                                                                <div *ngIf="showSubmit && !fifth.hovered"
                                                                                    class="empty__space"></div>
                                                                                <div *ngIf="fifth?.children?.length"
                                                                                    class="arrow-container">
                                                                                    <img src="/assets/images/treeArrow.svg"
                                                                                        alt="arrow">
                                                                                </div>
                                                                            </div>

                                                                            <ul class="submenu-list childNodes"
                                                                                [ngClass]="{'position-left':subMenuShowLeft}"
                                                                                *ngIf="fifth?.children?.length && fifth?.active">
                                                                                <div class="scroll">
                                                                                    <li class="sep-row"
                                                                                        *ngFor="let sixth of fifth.children;let o=index;"
                                                                                        [ngClass]="{'seventhmenuActive':sixth.active}"
                                                                                        (click)="$event.stopPropagation(); sixth.active=!sixth.active;seventhDropdown(i,j,k,l,m,n,o,sixth,sixth?.children?.length)"
                                                                                        (mouseover)="sixth.hovered = true;"
                                                                                        (mouseleave)="sixth.hovered = false;">

                                                                                        <div class="left-content">
                                                                                            {{sixth.label}}
                                                                                        </div>
                                                                                        <div *ngIf="showSubmit || sixth?.children?.length"
                                                                                            class="right-content">
                                                                                            <div *ngIf="showSubmit && sixth.hovered && (!isSelectAnyLevel && !sixth?.children?.length)"
                                                                                                class="submit-container"
                                                                                                (click)="$event.stopPropagation(); seventhDropdown(i,j,k,l,m,n,o,sixth,sixth?.children?.length, true);">
                                                                                                <i
                                                                                                    class="pi pi-check"></i>
                                                                                            </div>
                                                                                            <div *ngIf="showSubmit && !sixth.hovered"
                                                                                                class="empty__space">
                                                                                            </div>
                                                                                            <div *ngIf="sixth?.children?.length"
                                                                                                class="arrow-container">
                                                                                                <img src="/assets/images/treeArrow.svg"
                                                                                                    alt="arrow">
                                                                                            </div>
                                                                                        </div>

                                                                                        <ul class="submenu-list childNodes"
                                                                                            [ngClass]="{'position-left':subMenuShowLeft}"
                                                                                            *ngIf="sixth?.children?.length && sixth?.active">
                                                                                            <div class="scroll">
                                                                                                <li class="sep-row"
                                                                                                    *ngFor="let seventh of sixth.children;let p=index;"
                                                                                                    [ngClass]="{'eightthmenuActive':sixth.active}"
                                                                                                    (click)="$event.stopPropagation(); seventh.active=!seventh.active;eightDropdown(i,j,k,l,m,n,o,p,seventh,seventh?.children.length)"
                                                                                                    (mouseover)="seventh.hovered = true;"
                                                                                                    (mouseleave)="seventh.hovered = false;">

                                                                                                    <div
                                                                                                        class="left-content">
                                                                                                        {{seventh.label}}
                                                                                                    </div>
                                                                                                    <div *ngIf="showSubmit || seventh?.children?.length"
                                                                                                        class="right-content">
                                                                                                        <div *ngIf="showSubmit && seventh.hovered && (!isSelectAnyLevel && !seventh?.children?.length)"
                                                                                                            class="submit-container"
                                                                                                            (click)="$event.stopPropagation(); eightDropdown(i,j,k,l,m,n,o,p,seventh,seventh?.children?.length ,true);">
                                                                                                            <i
                                                                                                                class="pi pi-check"></i>
                                                                                                        </div>
                                                                                                        <div *ngIf="showSubmit && !seventh.hovered"
                                                                                                            class="empty__space">
                                                                                                        </div>
                                                                                                        <div *ngIf="seventh?.children?.length"
                                                                                                            class="arrow-container">
                                                                                                            <img src="/assets/images/treeArrow.svg"
                                                                                                                alt="arrow">
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <ul class="submenu-list childNodes"
                                                                                                        [ngClass]="{'position-left':subMenuShowLeft}"
                                                                                                        *ngIf="seventh?.children?.length && seventh?.active">
                                                                                                        <div
                                                                                                            class="scroll">
                                                                                                            <li class="sep-row"
                                                                                                                *ngFor="let eight of seventh.children;let q=index;"
                                                                                                                [ngClass]="{'fifthmenuActive':eight.active}"
                                                                                                                (click)="$event.stopPropagation(); eight.active=!eight.active;ninethDropdown(i,j,k,l,m,n,o,p,q,eight)"
                                                                                                                (mouseover)="eight.hovered = true;"
                                                                                                                (mouseleave)="eight.hovered = false;">

                                                                                                                <div
                                                                                                                    class="left-content">
                                                                                                                    {{eight.label}}
                                                                                                                </div>
                                                                                                                <div *ngIf="showSubmit || eight?.children?.length"
                                                                                                                    class="right-content">
                                                                                                                    <div *ngIf="showSubmit && eight.hovered && (!isSelectAnyLevel && !eight?.children?.length)"
                                                                                                                        class="submit-container"
                                                                                                                        (click)="$event.stopPropagation(); ninethDropdown(i,j,k,l,m,n,o,p,q,eight, true);">
                                                                                                                        <i
                                                                                                                            class="pi pi-check"></i>
                                                                                                                    </div>
                                                                                                                    <div *ngIf="showSubmit && !eight.hovered"
                                                                                                                        class="empty__space">
                                                                                                                    </div>
                                                                                                                    <div *ngIf="eight?.children?.length"
                                                                                                                        class="arrow-container">
                                                                                                                        <img src="/assets/images/treeArrow.svg"
                                                                                                                            alt="arrow">
                                                                                                                    </div>
                                                                                                                </div>





                                                                                                            </li>
                                                                                                        </div>
                                                                                                    </ul>


                                                                                                </li>
                                                                                            </div>
                                                                                        </ul>



                                                                                    </li>
                                                                                </div>
                                                                            </ul>



                                                                        </li>
                                                                    </div>
                                                                </ul>

                                                            </li>
                                                        </div>
                                                    </ul>
                                                </li>
                                            </div>
                                        </ul>
                                    </li>
                                </div>
                            </ul>
                        </li>
                    </div>
                </ul>
            </li>
        </ul>
    </div>
</div>