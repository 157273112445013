import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { UiModule } from '@acengage/ui/lib/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../core/services/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from '../core/interceptors/request.interceptors';
import { ResponseInterceptor } from '../core/interceptors/response.interceptors';
import { SharedModule } from '../shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  StoreModule,
} from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from './state/auth.reducer';
import { loginEffects } from './state/auth.effects';
import { AuthGuardService } from '../authgaurd/auth-gaurd.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ScanQrCodeComponent } from './scan-qr-code/scan-qr-code.component';
import { ValidateTfaComponent } from './validate-tfa/validate-tfa.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { localStorageSync } from 'ngrx-store-localstorage';
import { metaReducers } from './state/metareducers';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgotPasswordStepOneComponent } from './forget-password/forgot-password-steps/forgot-password-step-one/forgot-password-step-one.component';
import { ForgotPasswordStepTwoComponent } from './forget-password/forgot-password-steps/forgot-password-step-two/forgot-password-step-two.component';
import { ForgotPasswordStepThreeComponent } from './forget-password/forgot-password-steps/forgot-password-step-three/forgot-password-step-three.component';
import { ForgotPasswordStepFourComponent } from './forget-password/forgot-password-steps/forgot-password-step-four/forgot-password-step-four.component';
import { UnauthGuardService } from '../authgaurd/unauth-guard.service';
import { ClientLoginComponent } from './client-login/client-login.component';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ScanQrCodeComponent,
    ValidateTfaComponent,
    AuthLayoutComponent,
    ForgetPasswordComponent,
    ForgotPasswordStepOneComponent,
    ForgotPasswordStepTwoComponent,
    ForgotPasswordStepThreeComponent,
    ForgotPasswordStepFourComponent,
    ClientLoginComponent,
  ],
  imports: [
    AuthenticationRoutingModule,
    UiModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    QRCodeModule,
    StoreModule.forFeature(fromAuth.LOGIN_FEATURE_KEY, fromAuth.LoginReducer),

    EffectsModule.forFeature([loginEffects]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    AuthenticationService,
    AuthGuardService,
    UnauthGuardService,
  ],
})
export class AuthenticationModule {}
