<div (clickOutside)="setCampaignName(true);showDropdown = false;">
    <div *ngIf="selectedCampaignName !== floatLabel && isShowFloatLabel" class="float-container">
        <label class="float-label">{{floatLabel}}</label>
    </div>
    <div class="form-control-select  p-multiselect p-component custom__dropdown" [ngClass]="{'disabled': disabled}"
        (click)="setCampaignName();showDropdown = !showDropdown;">
        <span
            [ngClass]="[selectedCampaignName ===  floatLabel ? 'dropdown-empty' :'dropdown-label']">{{selectedCampaignName}}</span>
        <i *ngIf="selectedCampaignName !== floatLabel && selectedCampaignName !== ''"
            class="p-multiselect-clear-icon pi pi-times ng-star-inserted" (click)="clearFilters()"></i>
        <i class="pi pi-chevron-down arrow-changes"></i>
        <div class="dropdown__container multi" *ngIf="showDropdown" (click)="$event.stopPropagation();">
            <div class="search__select--container p-multiselect-panel p-component">
                <div class="search__select--content ">
                    <div class="p-multiselect-header">
                        <div class="p-checkbox p-component">
                            <span class="all-option">
                                {{'All'}}
                            </span>
                            <p-checkbox name="group" [value]="toggle" [binary]="true" [(ngModel)]="toggle"
                                (onChange)="toggleAll($event);"></p-checkbox>
                        </div>
                        <div class="p-multiselect-filter-container">
                            <input type="text" class="p-multiselect-filter p-inputtext p-component" name="name"
                                [(ngModel)]="textValue" (input)="onLazyFilter($event, true)">
                        </div>
                        <div>
                            <button type="button" pripple=""
                                class="p-ripple p-element p-multiselect-close p-link ng-star-inserted"
                                (click)="showDropdown = !showDropdown"><span
                                    class="p-multiselect-close-icon pi pi-times"></span><span class="p-ink"
                                    style="height: 30px; width: 30px; top: -0.725006px; left: -0.112488px;"></span></button>
                        </div>
                    </div>
                    <div class="maxHeight p-multiselect-items-wrapper" #scrollMe (scroll)="onScroll($event)">
                        <div class="flex search__select--data" [ngClass]="{'selected': eachOption?.selected }"
                            *ngFor="let eachOption of OptionList;let i=index" (click)="sendSelectData(eachOption);">
                            <div class="p-checkbox p-component">
                                <div class="p-checkbox-box">
                                    <p-checkbox #insideControl name="{{i}}" [value]="eachOption[optionValue]"
                                        [inputId]="eachOption[optionValue]" [(ngModel)]="eachOption.checked"
                                        (onChange)="updatechange($event,eachOption)"></p-checkbox>
                                </div>
                                <span>
                                    {{formValueToShow(eachOption)}}
                                </span>
                            </div>
                        </div>
                        <div class="emptymessage" *ngIf="!OptionList?.length">
                            <span>No results found</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>