import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { loaderService } from '../../services/loader.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private store: Store<AppState>,
    private _loader: loaderService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers: any = {
      Accept: 'application/json',
    };

    if (req.headers.get('Content-Type')) {
      headers['Content-Type'] = req.headers.get('Content-Type');
    }

    if (this.auth.isSessionActive() && req.responseType !== 'blob') {
      headers['Authorization'] =
        'Bearer ' +
        JSON.parse(localStorage.getItem('login') as any)['token'];
    }

    const request = req.clone({
      setHeaders: headers,
    });

    return next.handle(request);
  }
}
