import { AcengageTreeDefaultPayload, AcengageTreeForm } from '@acengage/app/app/acengage-tree/models/add-acengage-tree.interface';
import { AppState } from '@acengage/app/app/state/app.state';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectOption } from '@acengage/app/app/models/select.model';
import { AcengageTreeService } from '@acengage/app/app/services/acengage-tree.service';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { AcengageTreeServiceList } from '@acengage/app/app/acengage-tree/models/acengage-tree-listing.interface';

@Component({
  selector: 'acengage-acengage-tree-service-selection',
  templateUrl: './acengage-tree-service-selection.component.html',
  styleUrls: ['./acengage-tree-service-selection.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AcengageTreeServiceSelectionComponent implements OnInit {
  constructor(private treeService: AcengageTreeService,
    private appStore: Store<AppState>) { }
  @Input() treeForm: AcengageTreeForm;
  @Input() processType: string | null = '';
  @Output() addClicked = new EventEmitter<null>();
  @Output() resetClicked = new EventEmitter<null>();
  @Output() sendMappingFields = new EventEmitter<SelectOption[]>();
  @ViewChild('subservice') subserviceModel: NgModel;
  originalServiceList: AcengageTreeServiceList[] = [];
  servicesList: SelectOption[] = [];
  subServicesList: SelectOption[] = [];

  ngOnInit(): void {
    this.getServiceList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['treeForm'] && (changes['treeForm'].currentValue?.service !== changes['treeForm'].previousValue?.service)) {
      if (this.treeForm.service) {
        this.getSubServiceList(this.treeForm.service, false);
        this.fetchMappingFields();
      }
    }
  }

  getServiceList() {
    this.showLoaderTrigger(true);
    this.treeService.getServiceList().subscribe((serviceResp: any) => {
      this.showLoaderTrigger(false);
      this.originalServiceList = [...serviceResp.data];
      this.servicesList = serviceResp?.data?.map((eachValue: any) => {
        const formSelectOption: SelectOption = { name: eachValue.name, value: eachValue.id };
        return formSelectOption;
      }) || [];
      if (this.processType && this.treeForm.service) {
        this.getSubServiceList(this.treeForm.service, false);
      }
    });
  }

  getSubServiceList(selectedServiceID: number | number[], clearValue = true) {
    if (clearValue) {
      this.subServicesList = [];
      this.treeForm.subservice = null;
      this.subserviceModel.control.markAsPristine();
    }
    if (this.getServiceUID() === "CE") {
      this.showLoaderTrigger(true);
      const subservicePayload: { service_id: number | number[] } = {
        service_id: selectedServiceID
      };
      this.treeService.getSubServicesList(subservicePayload).subscribe((subServiceResp: any) => {
        this.showLoaderTrigger(false);
        if (subServiceResp?.data?.length) {
          this.subServicesList = subServiceResp?.data?.map((eachSubservice: any) => {
            const formSelectOption: SelectOption = {
              name: eachSubservice.name,
              value: eachSubservice.id
            };
            return formSelectOption;
          }) || [];
        }
      });
    }
  }

  fetchMappingFields() {
    let mappingFields: SelectOption[] = [];
    if (this.treeForm.service && (this.getServiceUID() !== "CE" || (this.getServiceUID() === "CE" && this.treeForm.subservice))) {
      this.showLoaderTrigger(true);
      const mappingFieldPayload: AcengageTreeDefaultPayload = { service_id: this.treeForm.service, sub_service_id: this.treeForm.subservice };
      this.treeService.getMappingFields(mappingFieldPayload).subscribe((mappingFieldResp: any) => {
        this.showLoaderTrigger(false);
        if (mappingFieldResp?.data?.length) {
          mappingFields = mappingFieldResp?.data?.map((eachField: any) => {
            const formSelectOption: SelectOption = { name: eachField.name, value: eachField.id };
            return formSelectOption;
          }) || [];
          this.sendMappingFields.emit(mappingFields);
        }
      });
    } else {
      this.sendMappingFields.emit(mappingFields);
    }
  }

  getServiceUID() {
    return this.originalServiceList?.find(eachService => eachService.id === this.treeForm.service)?.uid || "";
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
