import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '../services';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import * as AppActions from '../../state/app.actions';
import { CommonService } from '../../services/common.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private appStore: Store<AppState>,
    private commonService: CommonService
  ) {}
  newToken: any = '';
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((resp) => {
        this.authCheck(resp);
        return resp;
      }),
      catchError((err) => {
        this.authCheck(err);
        this.showLoaderTrigger(false);
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // unauthorized request
          this.auth.logOut();
          if (err?.error?.code === 'user_inactive') {
            err.error.detail =
              'Please contact admin as your user is deactivated';
            err.error.message =
              'Please contact admin as your user is deactivated';
            this.showToaster('error', '', err?.error?.message);
            return throwError(() => err) as any;
          } else {
            this.showToaster('error', '', err?.error?.message);
            return throwError(() => err) as any;
          }
        } else if (
          err instanceof HttpErrorResponse &&
          (err.status === 403 || err.status === 409)
        ) {
          // Restricted
          this.showToaster('error', '', err?.error?.message);
          if (err.status === 409) {
            this.commonService.redirectToDetailPage$.next(
              err?.error || { code: 409 }
            );
          }
          return throwError(err) as any;
        } else {
          if (
            err?.error?.message &&
            err?.error?.message !== 'No question available'
          ) {
            this.showToaster('error', '', err?.error?.message);
            return throwError(err) as any;
          }
        }
      })
    ) as any;
  }

  /**
   * @param resp API RESPONSE
   * @description To refresh the token with new one if we have that on response header.
   */
  authCheck(resp: any) {
    const token = this.auth.token;
    const responseTokenHeaderName = 'X-NEW-TOKEN'; // Change it based on your API response.
    this.newToken = token;

    if (!(resp instanceof HttpErrorResponse || resp instanceof HttpResponse)) {
      return;
    }

    if (resp && resp.headers && resp.headers.get(responseTokenHeaderName)) {
      this.newToken = resp.headers.get(responseTokenHeaderName);
    }

    if (token !== this.newToken) {
      this.auth.token = this.newToken;
    }
  }

  showToaster(type: string, heading: string, message: string) {
    this.appStore.dispatch(
      AppActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: true,
      })
    );
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
