<div class="forgot-cntr">
  <div class="forgot-head">
    Your password has been successfully changed
  </div>
  <div class="forgot-btn">
    <acengage-button
      buttonText="Go to Login" (buttonClicked)="navigateToLogin()"
    ></acengage-button>
  </div>
</div>
