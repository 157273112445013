import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ScanQrCodeComponent } from './scan-qr-code/scan-qr-code.component';
import { ValidateTfaComponent } from './validate-tfa/validate-tfa.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgotPasswordStepOneComponent } from './forget-password/forgot-password-steps/forgot-password-step-one/forgot-password-step-one.component';
import { ForgotPasswordStepTwoComponent } from './forget-password/forgot-password-steps/forgot-password-step-two/forgot-password-step-two.component';
import { ForgotPasswordStepThreeComponent } from './forget-password/forgot-password-steps/forgot-password-step-three/forgot-password-step-three.component';
import { ForgotPasswordStepFourComponent } from './forget-password/forgot-password-steps/forgot-password-step-four/forgot-password-step-four.component';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EditableTableComponent } from '@acengage/ui/lib/editable-table/editable-table.component';
import { UnauthGuardService } from '../authgaurd/unauth-guard.service';
import { ClientLoginComponent } from './client-login/client-login.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [UnauthGuardService],
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'edit-table', component: EditableTableComponent },
      { path: 'login', component: LoginComponent },
      { path: 'client-login', component: ClientLoginComponent },
      { path: 'two-factor-auth', component: ValidateTfaComponent },
      { path: 'scan-qr', component: ScanQrCodeComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      {
        path: 'forget-password',
        component: ForgetPasswordComponent,
        children: [
          { path: '', redirectTo: 'step-one', pathMatch: 'full' },
          { path: 'step-one', component: ForgotPasswordStepOneComponent },
          { path: 'step-two', component: ForgotPasswordStepTwoComponent },
          { path: 'step-three', component: ForgotPasswordStepThreeComponent },
          { path: 'step-four', component: ForgotPasswordStepFourComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
