<svg display="none">
    <symbol id="arrow">
      <polyline points="7 10,12 15,17 10" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polyline>
    </symbol>
  </svg>
  <div id="timeline" class="timeline">
    <div class="btn-group">
      <div class="heading">
        <h1>Ngage Continuity </h1>
      </div>
      <div class="btn-group">
        <acengage-button buttonText="Expand All" [classToadd]="'secondary-btn'"
                  (buttonClicked)="expandAll()">
              </acengage-button>
        <acengage-button buttonText="Collapse All" [classToadd]="'secondary-btn'"
                  (buttonClicked)="collapseAll()">
              </acengage-button>
        <acengage-button buttonText="Export" [classToadd]="'secondary-btn'"
                  (buttonClicked)="downloadExport()">
              </acengage-button>
      </div>
    </div>
    <div class="timeline__item" *ngFor="let item of timelineItems; let i = index">
      <div class="timeline__item-header">
        <button class="timeline__arrow" type="button" [id]="'item' + (i+1)" [attr.aria-labelledby]="'item' + (i+1) + '-name'"
          [attr.aria-expanded]="item.expanded ? 'true' : 'false'" [attr.aria-controls]="'item' + (i+1) + '-ctrld'" aria-haspopup="true"
          [attr.data-item]="i + 1" (click)="toggleItem(i)">
          <svg class="timeline__arrow-icon" viewBox="0 0 24 24" width="24px" height="24px">
            <use href="#arrow" />
          </svg>
        </button>
        <span class="timeline__dot"></span>
        <span [id]="'item' + (i+1) + '-name'" class="timeline__meta">
          <time class="timeline__date" [attr.datetime]="item.date">{{ item.date }}</time><br>
          <strong class="timeline__title">{{ item.title }}</strong>
        </span>
      </div>
      <div class="timeline__item-body" [id]="'item' + (i+1) + '-ctrld'" role="region" [attr.aria-labelledby]="'item' + (i+1)"
        [attr.aria-hidden]="item.expanded ? 'false' : 'true'" [ngClass]="{'timeline__item-body--expanded': item.expanded}">
        <div class="timeline__item-body-content">
          <!-- <p class="timeline__item-p">{{ item.content?.details }}</p> -->
          <!-- <acengage-table [currentModule]="'campaigns'" [total]="item?.content.length" [detailPage]="true" [isShowFilter]="false" [isShowSearch]="false"
                [tableRecords]="item?.content" [headerlabels]="headers" sort="false" [checkboxes]="false" (rowSelection)="rowClicked($event,item)">
          </acengage-table> -->
          <div class="intervention__details--basic--row divider box-outline" *ngFor="let data of item?.content; let ind = index">
              <div class="intervention__details--each--data" *ngFor="let keyValuePair of getObjectEntries(data)" [hidden]="keyValuePair[0] === 'isMoreData' || keyValuePair[0] === 'record_id' || keyValuePair[0] === 'campaign_id' || keyValuePair[0] === 'basicInfo' || !item.isShowMore[ind]">
                  <div class="intervention__details--each--data__header"> {{ keyValuePair[0] }}</div>
                  <div class="intervention__details--each--data__value">
                    {{ keyValuePair[1] }}
                  </div>
              </div>
              <div class="intervention__details--each--data" *ngFor="let keyValuePair of getObjectEntries(data.basicInfo)" [hidden]="keyValuePair[0] === 'isMoreData' || keyValuePair[0] === 'record_id' || keyValuePair[0] === 'campaign_id' || item.isShowMore[ind]">
                  <div class="intervention__details--each--data__header"> {{ keyValuePair[0] }}</div>
                  <div class="intervention__details--each--data__value">
                    {{ keyValuePair[1] }}
                  </div>
              </div>
              <h3 class="intervention__details--each--data__header show_more" *ngIf="data.isMoreData" style="cursor:pointer" (click)="showMore(item,ind)"> {{ item.isShowMore[ind]?'Show Less':'Show More' }}</h3>
              <div class="btn-outer">
                <acengage-button buttonText=""
                [buttonClassesToAdd]="'p-button-outlined p-button-secondary'"
                [iconDtls]="{ name: 'pi pi-arrow-right', position: 'right' }"
                (buttonClicked)="rowClicked(data,item)"></acengage-button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>