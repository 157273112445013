import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CECampaignService } from '../services-screens/services/ce-campaign.service';
import { AppState } from '../state/app.state';
import * as AppActions from '../state/app.actions';
import { ActivatedRoute } from '@angular/router';
import { CESlotSelectionCandidateDetails, CESlotSelectionForm, CESlotSelectionPayload } from '../services-screens/candidate-engagement/models/CE-Intervention-Slot-Selection.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { SelectOption } from '../models/select.model';
import { Config } from '../config';

@Component({
  selector: 'acengage-slot-selection',
  templateUrl: './slot-selection.component.html',
  styleUrls: ['./slot-selection.component.scss'],
})
export class SlotSelectionComponent implements OnInit {
  constructor(private campaignCEService: CECampaignService,
    private appStore: Store<AppState>,
    private route: ActivatedRoute,
    private fb: FormBuilder,) { }

  slotID: number | null;
  todayDate: Date = new Date();
  slotSelectionForm: FormGroup<CESlotSelectionForm>;
  candidateDtls = {} as CESlotSelectionCandidateDetails;
  connectDtls: SelectOption[] = [{
    name: 'manager',
    value: 1
  }, {
    name: 'leader',
    value: 2
  }, {
    name: 'recruiter',
    value: 3
  }, {
    name: 'buddy',
    value: 4
  }, {
    name: 'peer',
    value: 5
  }];

  slotSuccess = false;

  ngOnInit(): void {
    this.slotID = this.route.snapshot.paramMap.get('id') ? Number(this.route.snapshot.paramMap.get('id')) : null;
    this.getCandidateDtls();
  }

  createSlotSelectionForm() {
    const meetingValidation: any[] = (this.candidateDtls?.type === 1) ? [Validators.pattern(Config.urlRegex)] : [Validators.required, Validators.pattern(Config.urlRegex)];
    this.slotSelectionForm = this.fb.group({
      slot_date_1: this.fb.control('', [Validators.required]),
      slot_date_2: this.fb.control('', [Validators.required]),
      slot_date_3: this.fb.control('', [Validators.required]),
      slot_time_1: this.fb.control('', [Validators.required]),
      slot_time_2: this.fb.control('', [Validators.required]),
      slot_time_3: this.fb.control('', [Validators.required]),
      link: this.fb.control('', meetingValidation),
      meeting_id: this.fb.control('', []),
      passcode: this.fb.control('', [])
    });
  }

  getCandidateDtls() {
    if (this.slotID) {
      this.showLoaderTrigger(true);
      this.campaignCEService.getCandidateDtlForSlot(this.slotID).subscribe((candidateDtlResp: any) => {
        this.showLoaderTrigger(false);
        this.candidateDtls = candidateDtlResp?.data || {};
        this.createSlotSelectionForm();
      });
    }
  }
  notifyCandidate() {
    if (this.slotID) {
      this.showLoaderTrigger(true);
      this.campaignCEService.notifyCandidate(this.slotID).subscribe((candidateDtlResp: any) => {
        this.showLoaderTrigger(false);
      });
    }
  }

  getRecruiterKeyName() {
    return this.connectDtls.find(eachDtl => eachDtl.value === this.candidateDtls.type)?.name || 'Recruiter';
  }


  submitSlotSelection() {
    if (this.slotID) {
      this.showLoaderTrigger(true);
      const slotRawData = this.slotSelectionForm.getRawValue();
      const updtSlotPayload: CESlotSelectionPayload = {
        slot_1: `${moment(slotRawData.slot_date_1).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_1).format('HH:mm:ss')}`,
        slot_2: `${moment(slotRawData.slot_date_2).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_2).format('HH:mm:ss')}`,
        slot_3: `${moment(slotRawData.slot_date_3).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time_3).format('HH:mm:ss')}`,
        link: `${slotRawData.link}`,
        meeting_id: `${slotRawData.meeting_id}`,
        passcode: `${slotRawData.passcode}`,
      }
      this.campaignCEService.updtSlotSelection(this.slotID, updtSlotPayload).subscribe((updtSlotResp: any) => {
        this.showLoaderTrigger(false);
        this.showToaster('success', '', updtSlotResp.message);
        this.notifyCandidate();
        this.candidateDtls.is_submitted = true;
        this.slotSelectionForm.reset();
      });
    }
  }

  showToaster(type: string, heading: string, message: string) {
    this.appStore.dispatch(AppActions.showToaster({
      class: type,
      header: heading,
      message: message,
      showToaster: true
    }));
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
