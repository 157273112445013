<div
  class="top-bar"
  [ngClass]="{ 'sidebar-collapse': sharedService.collapseIcon }"
>
  <!-- <div class="search-bar">
    <acengage-search-input></acengage-search-input>
  </div> -->
  <!-- <div class="setting-icon-section">
    <acengage-settings-icon></acengage-settings-icon>
  </div> -->
  <div class="notification-icon-section" *ngIf="noDetail || clientDetails['progress_of_completion'] >= 12">
    <acengage-notification-icon
      (click)="toggleNotification()"
      pTooltip="Notification"
      [newCount]="newCount"
    ></acengage-notification-icon>
  </div>
  <div class="accounts-section">
    <acengage-accounts-icon
      [name]="userName"
      [icon]="imagePic"
      (iconClick)="toggleProfileIcon($event)"
      (clickOutside)="showContextMenu = false"
    ></acengage-accounts-icon>
  </div>
</div>
<div class="top-bar-context" *ngIf="showContextMenu">
  <ul>
    <li (click)="navigateToUrl('Profile')">
      <img src="shared-assets/images/user.svg" />
      <span>Profile</span>
      <img src="shared-assets/images/angle-right.svg" />
    </li>
  </ul>
  <ul>
    <li (click)="navigateToUrl('Password')">
      <img src="shared-assets/images/lock.svg" />
      <span>Password</span>
      <img src="shared-assets/images/angle-right.svg" />
    </li>
  </ul>
  <ul *ngIf="this.userDetails.roles[0].id === 11 && userDetails?.client_details?.progress_of_completion >= 12">
    <li (click)="navigateToUrl('subscription')">
      <img class="sub-icon" src="shared-assets/images/subscribe.png" />
      <span>Subscriptions</span>
      <img src="shared-assets/images/angle-right.svg" />
    </li>
  </ul>
  <ul>
    <li (click)="goToHelp()">
      <img style="opacity: 0.5;" src="shared-assets/images/help-circle 1.svg" />
      <span>Get Help</span>
      <img src="shared-assets/images/angle-right.svg" />
    </li>
  </ul>
  <ul>
    <li (click)="logoutProcess()">
      <img src="shared-assets/images/power-off.svg" />
      <span>Logout</span>
      <img src="shared-assets/images/angle-right.svg" />
    </li>
  </ul>
</div>

<acengage-dialog
  [classesToAdd]="'reduced_dialog_size'"
  [openModel]="isShowForceLogoutPopup"
  [headerText]="'Confirmation'"
  [confirmButtonText]="'Yes'"
  [cancelButtonText]="'No'"
  (confirmButton)="authService.logOut(true)"
  (cancelButton)="isShowForceLogoutPopup = false"
>
  <p>
    You have records assigned to you, logging out will have these records
    re-assigned to another user. Do you still want to logout?
  </p>
</acengage-dialog>
