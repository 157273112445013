import { Component, Input, ContentChildren, TemplateRef, Output, EventEmitter } from '@angular/core';
import { TemplateMarkerDirective } from '../directives/templateMarker/template-marker.directive';

@Component({
  selector: 'acengage-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {

  @ContentChildren(TemplateMarkerDirective, { read: TemplateRef }) templates: TemplateRef<any>[];
  @Input() classesToAdd = '';
  @Input() multipleOpen = true;
  headersarray: any = []
  @Input() filterDetails: any = [];
  @Input() filteredData: any = {};
  @Input() set headers(value: any) {
    this.headersarray = value
  }
  @Output() accordionOpen = new EventEmitter<any>();

}
