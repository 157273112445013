import { FormControl } from "@angular/forms";

export interface Queries {
  id: number;
  title: string;
  description: string;
  recommendation: string;
  employee: number;
  created_by: number;
  created_user: string;
  modified_by?: any;
  status: string;
  notes: string;
  opened_on: Date;
  closed_on?: any;
  notes_updated_on?: any;
  response_received_on?: any;
  modified_on: Date;
  resolution?: any;
  reply: string;
}
export interface CountResponse {
  total: number;
  open: number;
  closed: number;
  responded: number;
  note_updated: number;
  open_today: number;
  closed_today: number;
  responded_today: number;
  note_updated_today: number;
}

export interface SendEmailPayload {
  query_id: number[];
}
export interface StatusPayload {
  status: string;
  query_id: number[];
}

export interface SendEmailResponse {
  message: string;
}

export interface QueryOtherFilesDetailsResponse {
  id: number;
  url: string | null;
  filename: string;
  fileformat: string | null;
}
export interface QueryEmailRecurit {
  recruiter_email_id: string | null;
}
export interface QueryFormField {
  recruiter_email_id: FormControl<string | null>,
}
