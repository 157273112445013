import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CECampaignService } from '../services-screens/services/ce-campaign.service';
import { AppState } from '../state/app.state';
import * as AppActions from '../state/app.actions';
import { ActivatedRoute } from '@angular/router';
import { CESlotSelectionCandidateDetails, SlotChangeForm, ChangeSlotPayload } from '../services-screens/candidate-engagement/models/CE-Intervention-Slot-Selection.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { SelectOption } from '../models/select.model';
import { Config } from '../config';

@Component({
  selector: 'acengage-slot-non-contactable',
  templateUrl: './slot-non-contactable.component.html',
  styleUrls: ['./slot-non-contactable.component.scss'],
})
export class SlotNonContactableComponent implements OnInit {
  constructor(private campaignCEService: CECampaignService,
    private appStore: Store<AppState>,
    private route: ActivatedRoute,
    private fb: FormBuilder,) { }

  slotID: number | null;
  slotType: string | null;
  todayDate: Date = new Date();
  slotSelectionForm: FormGroup<any>;
  candidateDtls = {} as CESlotSelectionCandidateDetails;
  slotDtls:any = {};
  slotDtlsManager:any = {};
  selectedSlot: number=0;
  isCustomClicked: boolean = false;
  isRejectClicked: boolean = false;
  option:any[] = [
    { value: 2, name: 'I will provide a time slot to contact me.'},
    { value: 3, name: 'Please terminate the process.' },
  ];
  connectDtls: SelectOption[] = [{
    name: 'manager',
    value: 1
  }, {
    name: 'leader',
    value: 2
  }, {
    name: 'recruiter',
    value: 3
  }, {
    name: 'buddy',
    value: 4
  }, {
    name: 'peer',
    value: 5
  }];

  slotSuccess = false;

  ngOnInit(): void {
    this.slotID = this.route.snapshot.paramMap.get('id') ? Number(this.route.snapshot.paramMap.get('id')) : null;
    this.route.queryParams.subscribe((params: any) => {
      this.slotType = params?.type?params?.type:null;
    });
    this.getCandidateDtls();
  }

  createSlotSelectionForm(isSlotChange:boolean) {
    if(isSlotChange){
      this.slotSelectionForm = this.fb.group({
        slot_date: this.fb.control('', [Validators.required]),
        slot_time: this.fb.control('', [Validators.required]),
      });
    }else{
      this.slotSelectionForm = this.fb.group({
        note: this.fb.control('', [Validators.required]),
      });
    }
    
  }
  slotSelected(e:any){
    if(e==2){
      if(this.slotSelectionForm)this.slotSelectionForm.reset();
      this.createSlotSelectionForm(true);
      this.isCustomClicked=true;
      this.isRejectClicked=false;
      this.selectedSlot = Number(0);
    }
    else if(e==3){
      if(this.slotSelectionForm)this.slotSelectionForm.reset();
      this.createSlotSelectionForm(false);
      this.isCustomClicked=false;
      this.isRejectClicked=true;
      this.selectedSlot = Number(0);
    }
    else{
      if(this.slotSelectionForm)this.slotSelectionForm.reset();
      this.isCustomClicked=false;
      this.selectedSlot = Number(e);
    }
  }
  getCandidateDtls() {
    if (this.slotID) {
      this.showLoaderTrigger(true);
      this.campaignCEService.getNonContactableCandidateDtls(this.slotID).subscribe((candidateDtlResp: any) => {
        this.showLoaderTrigger(false);
        this.candidateDtls = candidateDtlResp?.data || {};
        // this.createSlotSelectionForm();
      });
    }
  }
  getRecruiterKeyName() {
    return this.connectDtls.find(eachDtl => eachDtl.value === this.candidateDtls.type)?.name || 'Recruiter';
  }

  submitSlotSelection(){
    if (this.slotID) {
      this.showLoaderTrigger(true);
      let selectedSlot: any = {}
      if(this.isCustomClicked){
        const slotRawData = this.slotSelectionForm.getRawValue();
        selectedSlot = {
          slot: `${moment(slotRawData.slot_date).format('YYYY-MM-DD')} ${moment(slotRawData.slot_time).format('HH:mm:ss')}`,
          status:2
        }
      }
      else if(this.isRejectClicked){
        const slotRawData = this.slotSelectionForm.getRawValue();
        selectedSlot = {
          note:slotRawData.note,
          status:3
        }
      }
      console.log('selectedSlot manager',selectedSlot);
      this.campaignCEService.updtNonContactableSlot(this.slotID, selectedSlot).subscribe((updtSlotResp: any) => {
        this.showLoaderTrigger(false);
        this.showToaster('success', '', updtSlotResp.message);
        this.candidateDtls.is_submitted = true;
        this.slotSelectionForm.reset();
      });
    }
  }

  showToaster(type: string, heading: string, message: string) {
    this.appStore.dispatch(AppActions.showToaster({
      class: type,
      header: heading,
      message: message,
      showToaster: true
    }));
  }

  showLoaderTrigger(isShow: boolean) {
    this.appStore.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
