import { AnswersNodeResponse } from '@acengage/app/app/question-bank/models/question-details.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup,FormControl } from '@angular/forms';
import { QuestionCheckboxTypeForm, QuestionsForm } from 'apps/acengage/src/app/question-bank/models/add-question-bank.model';

@Component({
  selector: 'acengage-choice-question-type',
  templateUrl: './choice-question-type.component.html',
  styleUrls: ['./choice-question-type.component.scss', '../../add-edit-questions.component.scss'],
})
export class ChoiceQuestionTypeComponent implements OnInit {
  constructor(
    private fb: FormBuilder
  ) { }
  boxNest=false;
  @Input() isMultiChoice = false; 
  @Input() questionForm: FormGroup<QuestionsForm>;
  @Output() addClicked = new EventEmitter<null>();
  @Output() removeClicked = new EventEmitter<number>();
  @Output() addFollowUp = new EventEmitter<number>();
  @Output() addQuestion = new EventEmitter<number>();
  @Output() updateNestedQuestions = new EventEmitter<any>();
  @Output() updateFollowupQuestions = new EventEmitter<any>();
  @Output() removeFollowup = new EventEmitter<number>();
  @Output() removeQuestion = new EventEmitter<number>();
  @Output() updateMainForm = new EventEmitter<any>();
  @Input() nestedQuestions: any = [];
  ngOnInit(): void { 
    console.log(this.questionForm)
  }

  checkboxAnswerFA() {
    return this.questionForm.get('options') as FormArray<FormGroup<QuestionCheckboxTypeForm>>;
  }
  followupQuestionControl() {
    return this.questionForm.get('followup_question');
}
  get questionFormControls() {
    return this.questionForm.controls;
  }

  updateNestedQuestion(index: any, $event: any) {
    (this.checkboxAnswerFA().controls[index]?.controls['nested_question'] as FormGroup).patchValue($event.value);
    ((this.questionForm.get('options') as FormArray).controls[index] as FormGroup).patchValue({'nested_question': $event.value});
    (((this.questionForm.get('options') as FormArray).controls[index] as FormGroup).controls['nested_question'].get('options') as FormArray).clear();
    $event.value.options.forEach((choice: any) => {
      (((this.questionForm.get('options') as FormArray).controls[index] as FormGroup).controls['nested_question'].get('options') as FormArray).push(this.newCheckboxAnswer(choice.choice));
    });
    this.checkboxAnswerFA().controls[index].controls['nested_question']?.updateValueAndValidity();
    this.questionForm.updateValueAndValidity();
  }
  updateFollowupQuestion(index: any, $event: any) {
    console.log('$event.value',$event.value);
    (this.checkboxAnswerFA().controls[index]?.controls['followup_question'])?.patchValue($event.value?.question);
    ((this.questionForm.get('options') as FormArray).controls[index] as FormGroup).patchValue({'followup_question': $event.value?.question});
    this.checkboxAnswerFA().controls[index].controls['followup_question']?.updateValueAndValidity();
    this.questionForm.updateValueAndValidity();
    console.log(this.questionForm.get('options'))
    console.log(this.questionForm)
  }

  newCheckboxAnswer(value?: any) {
    return this.fb.group<QuestionCheckboxTypeForm>({
      choice: this.fb.control(value? value : ''),
    });
  }
}
