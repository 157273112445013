<svg display="none">
    <symbol id="arrow">
      <polyline points="7 10,12 15,17 10" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polyline>
    </symbol>
  </svg>
  <div id="timeline" class="timeline">
    <div class="btn-group">
      <div class="heading">
        <h1>Buddy Induction </h1>
      </div>
      
      <div class="download--link">
        <a
          href="assets/files/Buddy_Induction.xlsx"
          download
          ><acengage-button buttonText="Download Template" [classToadd]="'outline-secondary-btn p-button-outlined'"
          (buttonClicked)="downloadTemplate();"></acengage-button></a
        >
      </div>
    </div>

    <div class="uploader-rec">
      <acengage-table 
        [currentModule]="'campaign'" 
        [tableRecords]="buddyInductionList"
        [headerlabels]="headers" 
        [isShowFilter]="false"
        [isShowSearch]="false"
        [actionItems]="actionItems" 
        [isShowActions]="false"
        (pageChange)="getNextpageRecords($event)" 
        [ischeckBox]="false"
        [showActions]="false"
        (sortTriggered)="sortColumn($event);" 
        sort="true" 
        [permissionRole]="permissionDetails"
        [total]="totalCount" 
        [tableLoader]="loading"
        [removeSelection]="resetRecords" 
        [isShowEditColumns]="true" 
        [isCheckBoxSelection]="false" 
        [showContractId]="false"
        [permissionRole]="permissionRole" 
        (customBtnTriggered)="resetRecords=false;"
        [setPermissionForActionButton]="true" 
        [isIndividualCellEditable]="true"
        (individualCellValueChanges)="showPopup($event)"
        [buttonDetails]="tableCustomBtnDtls"
        (customBtnTriggered)="customBtnClicked($event)"
        (deleteRecordEvent)="confirmDeleteRecord($event)"
        [searchPlaceholder]="'Search from the buddy induction list'"
        [isShowSearch]="true"
        (filterTriggered)="filterUsers($event)"
        (headersDtl)="setEditColsToStore($event)"
        >
      </acengage-table>
    </div>

    <acengage-dialog [classesToAdd]="'reduced_dialog_size'" [openModel]="showConfirmationDialog"
      [headerText]="'Confirmation'" [confirmButtonText]="'Yes'" [cancelButtonText]="'No'" (confirmButton)="updateData()"
      (cancelButton)="discardFunction()">
      <p>{{ confimationMessage }}</p>
    </acengage-dialog>

    <acengage-dialog [classesToAdd]="'reduced_dialog_size'" [openModel]="showDeleteConfirmationDialog"
      [headerText]="'Confirmation'" [confirmButtonText]="'Yes'" [cancelButtonText]="'No'" (confirmButton)="deleteRecord()"
      (cancelButton)="discardFunction()">
      <p>Are you sure you want to delete this value?</p>
    </acengage-dialog>

    <acengage-dialog 
      [openModel]="uploadDialog"
      [headerText]="'Upload'" 
      [confirmButtonText]="this.file ? 'Yes' : ''" 
      [cancelButtonText]="'No'" 
      (confirmButton)="submitClicked()"
      (cancelButton)="discardFunction()"
    >
      <acengage-fileupload
        [isMultiUpload]="false"
        [acceptType]="'.xlsx'"
        [existingFiles]="[]"
        [maxFileSize]="10000000"
        [name]="'upload'"
        (uploadedData)="fileUploads($event)"
        [btnLabel]="'Upload'"
        (validationToaster)="showToaster('error', '', $event)"
      ></acengage-fileupload>
    </acengage-dialog>
    
  </div>