import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'acengage-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class StepsComponent implements OnInit {
  items: MenuItem[];

  activeIndex = 1;
  @Input() readonly = true;
  @Input() isReadonly = false;
  @Input() set activeStep(value: number) {
    this.activeIndex = value - 1;
  }
  @Input() set stepItems(value: any) {
    this.items = value;
  }
  @Output() activeIndexChanged = new EventEmitter<number>();

  constructor(private messageService: MessageService) { }


  ngOnInit() {
  }
}
