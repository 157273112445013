import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleSwitchComponent),
    multi: true,
  }]
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  constructor() { }
  @Input() disabled = false;
  @Input() classesToAdd = '';
  @Input() name = '';
  value: any;
  private valueChanges = new Subject<string>();
  private touches = new Subject();

  registerOnChange(fn: any) {
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value;
  }
  updateValue(value: any) {
    this.value = value;
    this.valueChanges.next(value);
  }
  touch() {
    this.touches.next(null);
  }
}
