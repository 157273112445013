import { CommonService } from '@acengage/app/app/services/common.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'acengage-nested-followup-question',
  templateUrl: './nested-followup-question.component.html',
  styleUrls: ['./nested-followup-question.component.scss'],
})
export class NestedFollowupQuestionComponent implements OnInit {
  @Input() questionForm: FormGroup;
  @Output() addCheckboxOptionClicked = new EventEmitter<null>();
  @Output() removeCheckboxOptionClicked = new EventEmitter<number>();
  @Output() addFollowUpClicked = new EventEmitter<number>();
  @Output() addClicked = new EventEmitter<null>();
  @Output() removeClicked = new EventEmitter<number>();
  @Output() addFollowUp = new EventEmitter<number>();
  @Input() isMultiChoice = false;
  @Output() updateNestedQuestions = new EventEmitter<any>();
  @Input() nestedQuestions: FormGroup;
  @Input() nestIndex: number = 0;
  constructor(
    private fb: FormBuilder,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.createNestedForm();
    this.questionForm.valueChanges.subscribe((res)=>{
      this.updateNestedQuestions.emit(this.questionForm);
    })
  }

  createNestedForm() {
    const existVal = this.nestedQuestions.value?.options[this.nestIndex]?.followup_question;
    console.log('existVal',existVal);
    this.questionForm = this.fb.group({
      question: this.fb.control(existVal ? existVal : '', [Validators.required]),
    })
  }

}
