import { Injectable, HostListener } from '@angular/core';
import { ValidatorFn, AbstractControl, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class validationService {
  constructor() {}

  public regexMapping: any = {
    Alpha: /[a-zA-Z]/,
    Number: /[0-9]/,
    SpecialChar: /[\W]/,
    anythingExceptSpace: /[\S]/,
    anythingExceptNumber: /[\D]/,
    anythingExceptSpecialChar: /[\w]/,
    onlySpace: /[\s]/,
    AlphaWithSpace: /[a-zA-Z '\s]/,
  };
  public regexConstructor: any = {
    Alpha: 'a-z',
    Number: '\\d',
    SpecialChar: '\\W',
    anythingExceptSpace: '\\S',
    anythingExceptNumber: '\\D',
    AlphaNumeric: '\\w',
    Space: '\\s',
    AlphaWithSpace: 'a-zA-Z \\s',
  };
  /**
   * allows only validation
   * @param  {any} Regex
   * @param  {any} e
   */
  public allowOnly(Regex: any, e: any) {
    this.preventCharacters(this.regexMapping[Regex], e);
  }
  /**
   * not allow validation
   * @param  {any} Regex
   * @param  {any} e
   */
  public notAllow(Regex: any, e: any) {
    this.notAllowCharacters(this.regexMapping[Regex], e);
  }
  /**
   * prevent characters validation
   * @param  {any} pattern
   * @param  {any} event
   */
  public preventCharacters(pattern: any, event: any) {
    const input =
      event.type === 'paste'
        ? event.clipboardData.getData('Text')
        : String.fromCharCode(event.charCode);
    if (!pattern.test(input)) {
      event.preventDefault();
    }
  }
  /**
   * user defined pattern validation
   * @param  {any} event
   * @param  {any} CharacterClass1
   * @param  {any} CharacterClass2
   * @param  {boolean} IgnoreCaseSensitive?
   * @param  {any} array?
   */
  public userDefinedPattern(
    event: any,
    CharacterClass1: any,
    CharacterClass2: any,
    IgnoreCaseSensitive?: boolean,
    array?: any
  ) {
    //CharacterClasses
    let Char = this.regexConstructor[CharacterClass1];
    let Char2 = this.regexConstructor[CharacterClass2];
    if (Char2) {
      Char = Char + Char2;
    }
    let joinarr = array.join('');
    const pattern = '[' + Char + joinarr + ']';
    const RegexPattern = new RegExp(pattern, 'i');
    this.preventCharacters(RegexPattern, event);
  }
  /**
   * not allow characters validation
   * @param  {any} pattern
   * @param  {any} event
   */
  public notAllowCharacters(pattern: any, event: any) {
    const input =
      event.type === 'paste'
        ? event.clipboardData.getData('Text')
        : String.fromCharCode(event.charCode);
    if (pattern.test(input)) {
      event.preventDefault();
    }
  }
  /**min max validation
   * @param  {any} minVal
   * @param  {any} maxVal
   * @param  {any} validationMessage?
   * @returns ValidatorFn
   */
  public MinMax(
    minVal: any,
    maxVal: any,
    validationMessage?: any
  ): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control && control.value !== undefined && control.value !== null) {
        if (
          control.value.length < parseInt(minVal) ||
          control.value.length > parseInt(maxVal)
        ) {
          if (parseInt(minVal) === parseInt(maxVal)) {
            return { MinMax: { valid: 'Length should be ' + minVal } };
          } else {
            return {
              MinMax: {
                valid: 'Length should between ' + minVal + ' and ' + maxVal,
              },
            };
          }
        }
      }
    };
  }
  /**
   * empty space validation
   * @returns ValidatorFn
   */
  validateSpace(): ValidatorFn {
    return (control: AbstractControl) => {
      let spaceRegex = /^\s+$/i;
      return spaceRegex.test(control.value)
        ? { validateSpace: { valid: false } }
        : null;
    };
  }
}
