export interface AcengageTreeForm {
    service: number | number[] | null;
    heading: string | null;
    subservice: number | number[] | null;
    options: AcengageTreeNode[];
    tree_clone_id?: number | null;
}

export interface AcengageTreeNode {
    id?: number;
    choice: string | null;
    followup_question:string | null;
    parent: number | null;
    is_controllable: boolean | null;
    node_identifier: number | null;
    is_red_flagged: boolean | null;
    is_hidden: boolean | null;
    mapped_to: number | null;
    mapped_to_name?: string | null;
    leaf_nodes: AcengageTreeNode[];
    drill_down: number | null;
    scale_end: number | null;
    scale_start:number | null
}

export interface AcengageTreeDefaultPayload {
    service_id: number | number[] | null;
    sub_service_id: number | number[] | null;
}