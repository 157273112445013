<p-password class="width100" [placeholder]="placeholder" [maxlength]="maximumLength"
 [ngModel]="value" (ngModelChange)="updateValue($event)" (onBlur)="onTouch();" type="string"
    [feedback]="feedback" [toggleMask]="true">
    <ng-template *ngIf="showSuggessions" pTemplate="header">
      <h4>Pick a password</h4>
    </ng-template>
    <ng-template *ngIf="showSuggessions" pTemplate="footer">
      <!-- <p-divider></p-divider> -->
      <p class="p-mt-2">The password should contain:</p>
      <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>At least one special character</li>
        <li>Minimum 8 characters</li>
      </ul>
    </ng-template>
   </p-password>
