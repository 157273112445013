import { FormControl } from "@angular/forms";

export interface AddressForm {
    address_line1: FormControl<string | null>,
    address_line2: FormControl<string | null>,
    country: FormControl<string | number | null>,
    state: FormControl<string | number | null>,
    city: FormControl<string | number | null>,
    zip: FormControl<number | null>,
    // isSameAddress?: FormControl<boolean | null>
}

export interface AddressFormAccount {
    address_line1: FormControl<string | null>,
    address_line2: FormControl<string | null>,
    country: FormControl<string | number | null>,
    state: FormControl<string | number | null>,
    city: FormControl<string | number | null>,
    zip_code: FormControl<string | null>,
    // isSameAddress?: FormControl<boolean | null>
}

export interface CountryStateCitySelect {
    id: number
    name: string
    value: number
}

export interface AddressPayload {
    address_line1: string | null;
    address_line2: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    zip: number | null;
}

export interface AddressResponse {
    address_type: number | null;
    address_line1: string | null;
    address_line2: string | null;
    city: string | number | null;
    state: string | number | null;
    country: string | number | null;
    zip: number | null;
}
export interface AddressAccResponse {
    address_line1: string | null;
    address_line2: string | null;
    city: string | number | null;
    state: string | number | null;
    country: string | number | null;
    zip_code: string | null;
}

export interface MobileNoForm {
    number: string,
    internationalNumber: string,
    nationalNumber: string,
    e164Number: string,
    countryCode: string,
    dialCode: string
}
