import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardService as AuthGuard } from './authgaurd/auth-gaurd.service';
import { SlotSelectionComponent } from './slot-selection/slot-selection.component';
import { SlotConfirmationComponent } from './slot-confirmation/slot-confirmation.component';
import { AddClientAdminComponent } from './client-admin/add-client-admin/add-client-admin.component';
import { SlotNonContactableComponent } from './slot-non-contactable/slot-non-contactable.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'authentication',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'slot-selection/:id',
    component: SlotSelectionComponent,
  },
  {
    path: 'slot-confirmation/:id',
    component: SlotConfirmationComponent,
  },
  {
    path: 'slot-non-contactable/:id',
    component: SlotNonContactableComponent,
  },
  {
    path: 'online-client-onboarding',
    component: AddClientAdminComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
